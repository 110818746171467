import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// ================ Action types ================ //

export const FETCH_SERVICE_FEES_REQUEST = 'app/InboxPage/FETCH_SERVICE_FEES_REQUEST';
export const FETCH_SERVICE_FEES_SUCCESS = 'app/InboxPage/FETCH_SERVICE_FEES_SUCCESS';
export const FETCH_SERVICE_FEES_ERROR = 'app/InboxPage/FETCH_SERVICE_FEES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchServiceFeesError: null,
  fetchServiceFeesSuccess: false,
  pagination: null,
  serviceFees: [],
};

export default function serviceFeeInboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SERVICE_FEES_REQUEST:
      return {
        fetchInProgress: true,
        fetchServiceFeesError: null,
        fetchServiceFeesSuccess: false,
        serviceFees: [],
        pagination: null,
      };
    case FETCH_SERVICE_FEES_SUCCESS: {
      return {
        fetchInProgress: false,
        serviceFees: entityRefs(payload.data.data),
        pagination: payload.data.meta,
        fetchServiceFeesSuccess: true,
        fetchServiceFeesError: null,
      };
    }
    case FETCH_SERVICE_FEES_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        fetchInProgress: false,
        fetchServiceFeesError: payload,
        fetchServiceFeesSuccess: true,
        serviceFees: [],
        pagination: null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchServiceFeesRequest = () => ({ type: FETCH_SERVICE_FEES_REQUEST });

const fetchServiceFeesSuccess = response => ({
  type: FETCH_SERVICE_FEES_SUCCESS,
  payload: response,
});

const fetchServiceFeesError = e => ({
  type: FETCH_SERVICE_FEES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, providerId } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };

  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for ServiceFeeInboxPage: ${tab}`));
  }

  dispatch(fetchServiceFeesRequest());

  const providerParam = providerId === 'me' ? { employeeId: 'me' } : { providerId };
  const { page = 1, phrase, state } = parse(search);
  const owner = onlyFilter === 'order' ? { userId: 'me' } : { ...providerParam };

  const apiQueryParams = {
    serviceFee: true,
    include: ['provider', 'provider.image', 'user', 'user.image', 'listings', 'deliveryToProvider'],
    ...owner,
    page,
    per_page: PAGE_SIZE,
    phrase,
    state
  };

  if (!providerId && onlyFilter === 'sale') return Promise.resolve([]);

  return sdk.newSdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchServiceFeesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchServiceFeesError(storableError(e)));
      throw e;
    });
};

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { bool, object, string } from 'prop-types';

import css from './OrderItemInfo.css';

const InvoiceDetails = ({ invoice, customerType, isInvoiceDetails }) => {
  if (!invoice || !isInvoiceDetails) return null;

  const isCompany = customerType === 'company';
  const { address, city, country, name, zip, companyName, taxId } = invoice;

  return (
    <>
      <p className={classNames(css.subtitle, css.subtitleContainer)}>
        <FormattedMessage id="OrderInfo.billing" />
      </p>
      <p className={css.text}>{isCompany ? companyName : name}</p>
      {isCompany && (
        <p className={css.text}>
          <FormattedMessage id="OrderInfo.taxId" />: {taxId}
        </p>
      )}
      <p className={css.text}>{address}</p>
      <p className={css.text}>
        {zip} {city}
      </p>
      <p className={css.text}>{country}</p>
    </>
  );
};

InvoiceDetails.propTypes = {
  isInvoiceDetails: bool,
  customerType: string,
  invoice: object,
}

export default InvoiceDetails;

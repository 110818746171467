import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { formatMoney } from '../../../util/currency';

import css from './OrderSummary.css';

const format = (intl, date) =>
  intl.formatDate(date, {
    weekday: 'short',
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  });

const OrderSummary = ({ start, end, payinTotal, lineItems, cartListings, intl }) => {
  const PRODUCT_CODE = 'day';
  const LONG_TERM_DISCOUNT = 'long-term-discount';
  const PROMO_DISCOUNT = 'promo-discount';

  const products = lineItems.map(item => {
    if (!item) return;

    const { code, listingId, quantity, lineTotal } = item;
    if (code === PRODUCT_CODE) {
      const listingItem = cartListings.find(
        ({
          listing: {
            id: { uuid },
          },
        }) => listingId === uuid
      );
      const title = listingItem.listing.attributes.title;
      return (
        <div className={css.productContainer} key={listingId}>
          <p className={css.productText}>
            {`${title} x `}
            <FormattedMessage id="Summary.day" values={{count: quantity}}/>
          </p>
          <p className={css.productText}>{formatMoney(intl, lineTotal)}</p>
        </div>
      );
    }

    if (code === LONG_TERM_DISCOUNT) {
      return (
        <div className={css.productContainer} key={LONG_TERM_DISCOUNT}>
          <p className={css.productText}>
            <FormattedMessage id="Summary.discountText" />
          </p>
          <p className={css.productText}>{formatMoney(intl, lineTotal)}</p>
        </div>
      );
    }

    if (code === PROMO_DISCOUNT) {
      return (
        <div className={css.productContainer} key={PROMO_DISCOUNT}>
          <p className={classNames(css.productText, css.promoDiscount)}>
            <FormattedMessage id="Summary.promoDiscountText" />
          </p>
          <p className={classNames(css.productText, css.promoDiscount)}>{formatMoney(intl, lineTotal)}</p>
        </div>
      );
    }
  });

  return (
    <div className={css.summary}>
      <p className={css.title}>
        <FormattedMessage id="Summary.summaryTitle" />
      </p>
      <div className={css.productSection}>
        <div className={css.productContainer}>
          <p className={css.productText}>
            <FormattedMessage id="Summary.bookingStartTitle" />
          </p>
          <p className={css.productText}>{format(intl, moment(start))}</p>
        </div>
        <div className={css.productContainer}>
          <p className={css.productText}>
            <FormattedMessage id="Summary.bookingEndTitle" />
          </p>
          <p className={css.productText}>{format(intl, moment(end).subtract(1, 'days'))}</p>
        </div>
      </div>
      <div className={css.productSection}>{products}</div>
      <div className={css.togetherSection}>
        <p className={css.togetherText}>
          <FormattedMessage id="Summary.together" />
        </p>
        <p className={css.togetherText}>{formatMoney(intl, payinTotal)}</p>
      </div>
    </div>
  );
};

export default OrderSummary;

import React from 'react';
import classNames from 'classnames';
import {  shape, string } from 'prop-types';

import { NamedLink } from '../index';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import ServiceFeeTransaction from './ServiceFeeTransaction';
import ListingsTransaction from './ListingsTransaction';

import css from './RelatedTransactionsSection.css';

const transactionStatus = [
  { status: 'payment-expired', textStatus: 'DetailOrder.paymentExpired', class: css.expired },
  { status: 'pending-payment', textStatus: 'DetailOrder.pendingPayment', class: css.pending },
  { status: 'accepted', textStatus: 'DetailOrder.inProgress', class: css.inProgress },
  { status: 'picked-up', textStatus: 'DetailOrder.inProgress', class: css.inProgress },
  { status: 'cancelled', textStatus: 'DetailOrder.cancelled', class: css.cancelled },
  { status: 'delivered', textStatus: 'DetailOrder.delivered', class: css.delivered },
  { status: 'declined', textStatus: 'DetailOrder.cancelled', class: css.cancelled },
];

//A fee with the 'decline' status is an unpaid and canceled fee,
// while a fee with the 'cancelled' status is paid and canceled.
const serviceFeeStatus = [
  { status: 'pending', textStatus: 'DetailOrder.pendingPayment', class: css.pending },
  { status: 'declined', textStatus: 'DetailOrder.cancelled', class: css.cancelled },
  { status: 'accepted', textStatus: 'DetailOrder.paid', class: css.delivered },
  { status: 'cancelled', textStatus: 'DetailOrder.cancelled', class: css.cancelled },
];

const RelatedTransactionsSection = ({
    transaction,
    intl,
    history,
    containerClassName
}) => {
  const containerClasses = classNames(css.container, containerClassName)
  const isOrderPage = history && history.location.pathname.includes("order");
  const transactionRedirection = isOrderPage ? "OrderDetailsPage" : "SaleDetailsPage";
  const serviceFeeRedirection = isOrderPage ? "ServiceFeeDetailsPage" : "SaleServiceFeeDetailsPage";

  return (
    <div className={containerClasses}>
      <h3 className={css.title}>
        {intl.formatMessage({ id: "RelatedTransactionsSection.title" })}
      </h3>
      <div>
        {
          transaction.relatedTransactions.map(t => {
            const transactionStatusAttributes = transactionStatus.find(
              ({ status }) => status === t.attributes.processState
            );
            const transactionServiceFeeStatusAttributes = serviceFeeStatus.find(
              ({ status }) => status === t.attributes.state
            );
            const isServiceFee = t.attributes.serviceFee;
            const redirection = isServiceFee ? serviceFeeRedirection : transactionRedirection;

            return (
              <div className={css.text}>
                <NamedLink
                  name={redirection}
                  params={{ id: t.id.uuid }}
                  className={css.link}
                >
                  {t.attributes.code}
                </NamedLink>
                {isServiceFee
                  ? <ServiceFeeTransaction
                      intl={intl}
                      statusClass={transactionServiceFeeStatusAttributes.class}
                      statusText={transactionServiceFeeStatusAttributes.textStatus}
                      createdAt={t.attributes.createdAt}
                    />
                  : <ListingsTransaction
                      intl={intl}
                      statusClass={transactionStatusAttributes.class}
                      statusText={transactionStatusAttributes.textStatus}
                      createdAt={t.attributes.createdAt}
                      start={t.attributes.start}
                      end={t.attributes.end}
                    />
                }
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

RelatedTransactionsSection.defaultProps = {
  containerClassName: null,
};

RelatedTransactionsSection.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  containerClassName: string,
  history: shape({
    location: shape({
      pathname: string,
    })
  }).isRequired,
}

export default RelatedTransactionsSection;

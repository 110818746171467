import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {  node, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { createSlug } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { formatThisYearDateToShorter } from '../../util/dates';
import { NamedLink, ResponsiveImage } from '../../components';

import css from './OrderDetails.css';

const OrderDetails = ({
  providerName,
  listings,
  intl,
  actualOrderedProducts,
  transaction,
  children,
  codeMessage,
  titleMessage,
  transactionStatusAttributes
}) => {
  if (!transaction) return null;
  const {  end, start, code } = transaction.attributes;

  return (
    <div className={css.container}>
      <div className={classNames(css.titleContainer, css.title)}>
        <p className={css.title}>
          <FormattedMessage id={titleMessage} values={{ providerName }} />
          {code &&
            <span className={css.code}>
              <FormattedMessage id={codeMessage} />
              <span className={css.codeValue}>{` ${code}`}</span>
            </span>
          }
        </p>
        <div className={classNames(css.statusText, transactionStatusAttributes.class)}>
          <FormattedMessage id={ transactionStatusAttributes.textStatus } />
        </div>
      </div>
      {children}
      <div className={css.mainContent}>
        {actualOrderedProducts.map(({ listingId, lineTotal, seats }) => {
          const listing = listings.find(({ id: { uuid } }) => uuid === listingId);
          const { uuid } = listing.id;
          const image = listing?.images[0];
          const { title, deleted } = listing.attributes;
          const params = { id: listingId, slug: createSlug(title) };
          return (
            <div
              className={classNames(css.productContainer, {[css.overlay]: !seats})}
              key={uuid}
            >
              {image && (
                <ResponsiveImage
                  rootClassName={css.rootForImage}
                  image={image}
                  alt="product"
                  variants={["square-small", "square-small2x"]}
                />
              )}
              <div className={css.productContent}>
                <p className={css.productName}>{title}</p>
                <div className={css.productDetails}>
                  <p className={css.text}>
                    <FormattedMessage id="DetailOrder.quantity" /> <b> {seats}</b>
                  </p>
                  <p className={css.text}>
                    <FormattedMessage id="DetailOrder.when" />{` `}
                    <b>
                      {`${formatThisYearDateToShorter(intl, moment(start))} - ${formatThisYearDateToShorter(intl, moment(end).subtract(1, 'days'))}`}
                    </b>
                  </p>
                  <p className={classNames(css.text, css.price)}>{formatMoney(intl, lineTotal)}</p>
                </div>
                {!deleted && <NamedLink className={css.text} name="ListingPage" params={params}>
                  <FormattedMessage id="DetailOrder.product" />
                </NamedLink>}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

OrderDetails.defaultProps = {
  children: null,
}

OrderDetails.propTypes = {
  intl: intlShape,
  providerName: string.isRequired,
  transaction: propTypes.transaction.isRequired,
  children: node,
  codeMessage: string,
  titleMessage: string,
};

export default OrderDetails;

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { IconTick } from '../index';

import css from './TransactionSuccess.css';

const TransactionSuccess = () => {
  return (
    <>
      <div className={css.successContainer}>
        <div className={css.successContent}>
          <IconTick />
          <p className={css.successText}>
            <FormattedMessage id="TransactionPage.success" />{' '}
          </p>
        </div>
      </div>
    </>
  )
}

export default TransactionSuccess;

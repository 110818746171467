import React from 'react';
import classNames from 'classnames';

import { IconDelete, ResponsiveImage } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';

import css from './Order.css';

const Order = ({
  intl,
  orders,
  totalNumberOfOrders = 0,
  orderNumber = 0,
  children = <></>,
  provider,
  rootClassName,
  className,
  deleteFromCart = () => { },
  warnings = [],
  productCanBeRemoved = false,
}) => {
  const classes = classNames(rootClassName || css.root, className);

  const handleDelete = (event, orderId) => {
    event.preventDefault();
    deleteFromCart(orderId);
  };

  return (
    <div className={classes}>
      <div className={css.orderContent}>
        <p className={css.title}>
          {!!orderNumber && <span>
            {`${orderNumber}/${totalNumberOfOrders} `}
          </span>}
          <span>
            <FormattedMessage
              id="Order.orderFrom"
              values={{ providerName: provider.attributes.name }}
            />
          </span>
        </p>
        <div className={css.mainContent}>
          {orders.map(order => {
            const image = order.listing?.images[0];
            const warning = warnings.find(item => item?.details?.listingId === order.listing.id.uuid);
            const { uuid } = order?.id;
            const { price, title } = order.listing.attributes;
            return (
              <div className={css.productContainer} key={uuid}>
                {image && (
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    image={image}
                    alt="product"
                    variants={["square-small", "square-small2x"]}
                  />
                )}
                <div className={css.productContent}>
                  <div className={css.orderDetailFirstParts}>
                    <p className={css.productName}>{title.toLowerCase()}</p>
                    <div className={css.productDetails}>
                      <p className={css.text}>
                        <FormattedMessage id="Order.quantity" /> <b> {order.attributes.amount}</b>
                      </p>
                    </div>
                  </div>

                  <div className={css.orderDetailSecondParts}>
                    <p className={classNames(css.text, css.priceWrapper)}>
                      <span className={css.price}>{formatMoney(intl, price)}/</span>{' '}
                      <FormattedMessage id="Order.day" />{' '}
                    </p>
                  </div>
                </div>
                {!!warning && (
                  <p className={css.error}>
                    <FormattedMessage id="Order.unavailableProduct" />
                  </p>
                )}
                {productCanBeRemoved && (
                  <button
                    className={css.deleteButton}
                    onClick={event => handleDelete(event, uuid)}
                  >
                    <IconDelete />
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Order;

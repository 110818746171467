import { updatedEntities, denormalisedEntities } from '../../util/data';

// ================ Action types ================ //

// export const FETCH_LISTINGS_REQUEST = 'app/PayoutPage/FETCH_LISTINGS_REQUEST';
// export const FETCH_LISTINGS_SUCCESS = 'app/PayoutPage/FETCH_LISTINGS_SUCCESS';
// export const FETCH_LISTINGS_ERROR = 'app/PayoutPage/FETCH_LISTINGS_ERROR';

export const FETCH_STRIPE_ACCOUNTS_REQUEST = 'app/PayoutPage/FETCH_STRIPE_ACCOUNTS_REQUEST';
export const FETCH_STRIPE_ACCOUNTS_SUCCESS = 'app/PayoutPage/FETCH_STRIPE_ACCOUNTS_SUCCESS';
export const FETCH_STRIPE_ACCOUNTS_ERROR = 'app/PayoutPage/FETCH_STRIPE_ACCOUNTS_ERROR';

// export const OPEN_LISTING_REQUEST = 'app/PayoutPage/OPEN_LISTING_REQUEST';
// export const OPEN_LISTING_SUCCESS = 'app/PayoutPage/OPEN_LISTING_SUCCESS';
// export const OPEN_LISTING_ERROR = 'app/PayoutPage/OPEN_LISTING_ERROR';

// export const CLOSE_LISTING_REQUEST = 'app/PayoutPage/CLOSE_LISTING_REQUEST';
// export const CLOSE_LISTING_SUCCESS = 'app/PayoutPage/CLOSE_LISTING_SUCCESS';
// export const CLOSE_LISTING_ERROR = 'app/PayoutPage/CLOSE_LISTING_ERROR';

// export const REMOVE_LISTING_REQUEST = 'app/PayoutPage/REMOVE_LISTING_REQUEST';
// export const REMOVE_LISTING_SUCCESS = 'app/PayoutPage/REMOVE_LISTING_SUCCESS';
// export const REMOVE_LISTING_ERROR = 'app/PayoutPage/REMOVE_LISTING_ERROR';

export const ADD_OWN_ENTITIES = 'app/PayoutPage/ADD_OWN_ENTITIES';

export const QUERY_OWN_STRIPE_ACCOUNTS_REQUEST = 'app/PayoutPage/QUERY_OWN_STRIPE_ACCOUNTS_REQUEST';
export const QUERY_OWN_STRIPE_ACCOUNTS_SUCCESS = 'app/PayoutPage/QUERY_OWN_STRIPE_ACCOUNTS_SUCCESS';
export const QUERY_OWN_STRIPE_ACCOUNTS_ERROR = 'app/PayoutPage/QUERY_OWN_STRIPE_ACCOUNTS_ERROR';

export const SHOW_OWN_STRIPE_ACCOUNT_REQUEST = 'app/PayoutPage/SHOW_OWN_STRIPE_ACCOUNTS_REQUEST';
export const SHOW_OWN_STRIPE_ACCOUNT_SUCCESS = 'app/PayoutPage/SHOW_OWN_STRIPE_ACCOUNTS_SUCCESS';
export const SHOW_OWN_STRIPE_ACCOUNT_ERROR = 'app/PayoutPage/SHOW_OWN_STRIPE_ACCOUNTS_ERROR';

export const QUERY_PAYOUTS_REQUEST = 'app/PayoutPage/QUERY_PAYOUTS_REQUEST';
export const QUERY_PAYOUTS_SUCCESS = 'app/PayoutPage/QUERY_PAYOUTS_SUCCESS';
export const QUERY_PAYOUTS_ERROR = 'app/PayoutPage/QUERY_PAYOUTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  stripeAccounts: [],
  queryStripeAccountsInProgress: false,
  queryStripeAccountsError: null,
  stripeAccountData: {},
  showStripeAccountInProgress: false,
  showStripeAccountError: null,
  showStripeAccountSuccess: false,
  payouts: [],
  queryPayoutsInProgress: false,
  queryPayoutsError: null,
  queryPayoutsSuccess: false,
};

const resultIds = data => data.data.map(l => l.id);

const merge = (state, sdkResponse) => {
  const apiResponse = sdkResponse.data;
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case QUERY_OWN_STRIPE_ACCOUNTS_REQUEST:
      return {
        ...state,
        queryStripeAccountsInProgress: true,
        queryStripeAccountsError: null,
      };

    case QUERY_OWN_STRIPE_ACCOUNTS_SUCCESS:
      return {
        ...state,
        queryStripeAccountsInProgress: false,
        stripeAccounts: payload.data,
      };

    case QUERY_OWN_STRIPE_ACCOUNTS_ERROR:
      return {
        ...state,
        queryStripeAccountsInProgress: true,
        queryStripeAccountsError: payload,
      };

    case SHOW_OWN_STRIPE_ACCOUNT_REQUEST:
      return {
        ...state,
        showStripeAccountInProgress: true,
        showStripeAccountError: null,
      };

    case SHOW_OWN_STRIPE_ACCOUNT_SUCCESS:
      return {
        ...state,
        showStripeAccountSuccess: true,
        showStripeAccountInProgress: false,
        stripeAccountData: payload.data,
      };

    case SHOW_OWN_STRIPE_ACCOUNT_ERROR:
      return {
        ...state,
        showStripeAccountInProgress: true,
        showStripeAccountError: payload,
      };

    case QUERY_PAYOUTS_REQUEST:
      return {
        ...state,
        queryPayoutsInProgress: true,
        queryPayoutsError: null,
        queryPayoutsSuccess: false,
      };

    case QUERY_PAYOUTS_SUCCESS:
      return {
        ...state,
        queryPayoutsSuccess: true,
        queryPayoutsInProgress: false,
        queryPayoutsError: false,
        payouts: payload.data,
        pagination: payload.meta,
      };

    case QUERY_PAYOUTS_ERROR:
      return {
        ...state,
        queryPayoutsInProgress: true,
        queryPayoutsError: payload,
        queryPayoutsSuccess: false,
      };

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

export const queryOwnStripeAccountsRequest = () => ({
  type: QUERY_OWN_STRIPE_ACCOUNTS_REQUEST,
});

export const queryOwnStripeAccountsSuccess = response => ({
  type: QUERY_OWN_STRIPE_ACCOUNTS_SUCCESS,
  payload: response.data,
});

export const showOwnStripeAccountRequest = () => ({
  type: SHOW_OWN_STRIPE_ACCOUNT_REQUEST,
});

export const showOwnStripeAccountSuccess = response => ({
  type: SHOW_OWN_STRIPE_ACCOUNT_SUCCESS,
  payload: response.data,
});

export const showOwnStripeAccountError = () => ({
  type: SHOW_OWN_STRIPE_ACCOUNT_ERROR,
});

export const queryPayoutsRequest = () => ({
  type: QUERY_PAYOUTS_REQUEST,
});

export const queryPayoutsSuccess = response => ({
  type: QUERY_PAYOUTS_SUCCESS,
  payload: response.data,
});

export const queryPayoutsError = () => ({
  type: QUERY_PAYOUTS_ERROR,
});


// Throwing error for new (loadData may need that info)
export const queryOwnStripeAccounts = queryParams => (dispatch, getState, sdk) => {
  return sdk.newSdk.providerStripeAccounts
    .query({
      include: ['providers'],
    })
    .then(response => {
      dispatch(queryOwnStripeAccountsSuccess(response));
      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const showOwnStripeAccount = accountId => (dispatch, getState, sdk) => {
  dispatch(showOwnStripeAccountRequest());

  return sdk.newSdk.providerStripeAccounts
    .show({
      id: accountId,
      'fields.provider-stripe-account': ['name', 'futurePayoutStats'],
    })
    .then(response => {
      dispatch(showOwnStripeAccountSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(showOwnStripeAccountError(error));
      throw error;
    });
};

const PAYOUTS_SIZE = 10;

export const queryStripeAccountPayouts = (accountId, search) => (dispatch, getState, sdk) => {
  const { page = 1 } = search;
  dispatch(queryPayoutsRequest());

  const paginationParams = {
    page,
    per_page: PAYOUTS_SIZE,
  };

  return sdk.newSdk.payouts
    .query({
      providerStripeAccountId: accountId,
      ...paginationParams,
    })
    .then(response => {
      dispatch(queryPayoutsSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(queryPayoutsError(error));
      throw error;
    });
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage  } from 'react-intl';

import { Modal, Button, InlineTextButton, Checkbox, FieldCheckbox } from '../../../components';
import CatalogProductSelectField from '../CatalogProductSelectField';
import CatalogItemSelectField from '../CatalogItemSelectField';

import css from './AssignCatalogItemModal.css';

const AssignCatalogItemModal = props => {
  const {
    className,
    rootClassName,
    id,
    isOpen,
    onCloseModal,
    onManageDisableScrolling=false,
    inProgress,
    form,
    intl,
    productCatalogList,
    catalogProduct,
    handleSubmit,
    isWarehouseFromPL,
    customPriceInput,
    isCustomPrice
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const handelClose = (event) => {
    event.preventDefault();
    form.change('catalogProduct', '');
    form.change('catalogItem', '');
    form.change('isCustomPrice', false);
    onCloseModal();
  }

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      closeButtonClassName={css.closeButton}
      isOpen={isOpen}
      onClose={handelClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      <div className={css.modalContent}>
        <div className={css.container}>
          <p className={css.modalTitle}>
            <FormattedMessage id="EditListingDescriptionForm.assignCatalogProduct" />
          </p>
          {isOpen ? <CatalogProductSelectField
            id="catalogProduct"
            name="catalogProduct"
            catalogProducts={productCatalogList}
            markRequired={true}
            intl={intl}
            form={form}
          /> : null}
          {isOpen ? <CatalogItemSelectField
            id="catalogItem"
            name="catalogItem"
            intl={intl}
            markRequired={true}
            catalogProducts={productCatalogList}
            catalogProduct={{value: catalogProduct}}
            form={form}
          /> : null}
          {isOpen && isWarehouseFromPL ?
            <FieldCheckbox
              id="isCustomPrice"
              name="isCustomPrice"
              label={intl.formatMessage({ id: "AssignCatalogItemModal.customPriceLabel"})}
              checked={isCustomPrice}
              valueFromForm={isCustomPrice}
              textClassName={css.consentLabel}
              size={'large'}
              checkmarkClassName={css.checkmark}
            /> : null}
          {isOpen && isWarehouseFromPL && isCustomPrice ? customPriceInput : null}
        </div>
        <div className={css.buttonSection}>
          <InlineTextButton className={css.cancelButton} onClick={handelClose} type='button'>
            <FormattedMessage id="PartialCancelModal.cancel" />
          </InlineTextButton>
          <Button onClick={handleSubmit} className={css.confirmButton} inProgress={inProgress}>
            <FormattedMessage id="ConfirmModal.confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const { bool, string } = PropTypes;

AssignCatalogItemModal.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
};

AssignCatalogItemModal.propTypes = {
  className: string,
  rootClassName: string,
};

export default AssignCatalogItemModal;

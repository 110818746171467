import React, { Component } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { Form, Avatar, Button, ImageFromFile, IconSpinner, FieldTextInput, LocationAutocompleteInputField, FieldSelect, CheckoutCustomFields } from '../../components';

import css from './InvoiceSettingsForm.css';

const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset

const INVOICE_FIELDS = [
  {
    "label": "StripePaymentForm.country",
    "type": "text",
    "placeholder": "StripePaymentForm.country",
    "name": "invoice.country",
    "required": true
  },
  {
    "label": "StripePaymentForm.city",
    "type": "text",
    "placeholder": "StripePaymentForm.city",
    "name": "invoice.city",
    "required": true
  },
  {
    "label": "StripePaymentForm.zip",
    "type": "text",
    "placeholder": "StripePaymentForm.zip",
    "name": "invoice.zip",
    "required": true
  },
  {
    "label": "StripePaymentForm.address",
    "type": "text",
    "placeholder": "StripePaymentForm.address",
    "name": "invoice.address",
    "required": true
  },
];

const COMPANY_FIELDS = [
  {
    "label": "StripePaymentForm.companyName",
    "type": "text",
    "placeholder": "StripePaymentForm.companyName",
    "name": "invoice.companyName",
    "required": true
  },
  {
    "label": "StripePaymentForm.taxId",
    "type": "text",
    "placeholder": "StripePaymentForm.taxId",
    "name": "invoice.taxId",
    "required": true
  },
  ...INVOICE_FIELDS
];

const INDIVIDUAL_FIELDS = [
  {
    "label": "StripePaymentForm.customerName",
    "type": "text",
    "placeholder": "StripePaymentForm.customerName",
    "name": "invoice.name",
    "required": true
  },
  ...INVOICE_FIELDS
];

class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = { uploadDelay: false };
    this.submittedValues = {};
  }

  render() {
    // const isDecathlonUser = !!(this.props.currentUser.attributes.profile.metadata && this.props.currentUser.attributes.profile.metadata.decathlon_user);
    // const isExternalProvider = !isDecathlonUser && !!(this.props.currentUser.attributes.profile.metadata && this.props.currentUser.attributes.profile.metadata.is_provider);
    // const displayCompanySection = isDecathlonUser || isExternalProvider;
    const displayCompanySection = false;
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const pristineSinceLastSubmit = submittedOnce && isEqual(values, this.submittedValues);
          const submitDisabled =
            invalid || submitInProgress;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedValues = values;
                handleSubmit(e);
              }}
            >
              <div className={css.sectionContainer}>
                <div className={css.paymentSection}>
                  <FieldSelect
                    id="type"
                    name="customerType"
                    label={intl.formatMessage({ id: 'StripePaymentForm.customerType' })}
                    validate={validators.required('Type required')}
                  >
                    <option value="individual">
                      {intl.formatMessage({ id: 'StripePaymentForm.indivdual' })}
                    </option>
                    <option value="company">
                      {intl.formatMessage({ id: 'StripePaymentForm.company' })}
                    </option>
                  </FieldSelect>
                  <CheckoutCustomFields
                    additionalFields={{
                      fields: values.customerType === 'company' ? COMPANY_FIELDS.map(f => ({ ...f, required: values.customerType === 'company' })) : INDIVIDUAL_FIELDS.map(f => ({ ...f, required: values.customerType === 'individual' }))
                    }}
                    intl={intl}
                    needsTranslation
                  />
                </div>
              </div>
              {submitError}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={pristineSinceLastSubmit}
              >
                <FormattedMessage id="ProfileSettingsForm.saveChanges" />
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;

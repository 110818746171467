import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import ActivityFeed from './ActivityFeed/ActivityFeed';

import css from './FeedSection.css';

// Functional component as a helper to build ActivityFeed section
const FeedSection = props => {
  const {
    className,
    rootClassName,
    currentTransaction,
    currentUser,
    fetchMessagesError,
    fetchMessagesInProgress,
    initialMessageFailed,
    messages,
    review,
    oldestMessagePageFetched,
    onShowMoreMessages,
    onOpenReviewModal,
    totalMessagePages,
    isProvider,
    onUpdateTransaction
  } = props;

  const txTransitions = currentTransaction.attributes.transitions
    ? currentTransaction.attributes.transitions
    : [];
  const hasOlderMessages = totalMessagePages > oldestMessagePageFetched;

  const showFeed =
    messages.length > 0 || txTransitions.length > 0 || initialMessageFailed || fetchMessagesError;

  const classes = classNames(rootClassName || css.feedContainer, className);
  const lastMessage = messages.length ? messages[messages.length - 1] : null;

  return showFeed ? (
    <div className={classes}>
      <h3 className={css.feedHeading}>
        <FormattedMessage id="TransactionPanelNew.activityHeading" />
      </h3>
      {initialMessageFailed ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.initialMessageFailed" />
        </p>
      ) : null}
      {fetchMessagesError ? (
        <p className={css.messageError}>
          <FormattedMessage id="TransactionPanel.messageLoadingFailed" />
        </p>
      ) : null}
      <ActivityFeed
        className={css.feed}
        messages={messages}
        review={review}
        transaction={currentTransaction}
        currentUser={currentUser}
        hasOlderMessages={hasOlderMessages && !fetchMessagesInProgress}
        onOpenReviewModal={onOpenReviewModal}
        onShowOlderMessages={onShowMoreMessages}
        fetchMessagesInProgress={fetchMessagesInProgress}
      />
      <div className={css.notRespond}>
        {
          lastMessage?.attributes.sender !== 'provider' &&
          currentTransaction.attributes.notRespondedMessage &&
          isProvider &&
          <a onClick={() => onUpdateTransaction(currentTransaction.id)}><FormattedMessage id="TransactionPanelNew.dontWantToRespond" /></a>
        }
      </div>
    </div>
  ) : null;
};

FeedSection.defaultProps = {
  messages: [],
  txTransitions: [],
}
export default FeedSection;

import React from 'react';
import moment from 'moment';
import { arrayOf, shape, string } from 'prop-types';
import classNames from 'classnames';

import { NamedLink, LogoMedicoverSport } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';

import css from './TransactionItem.css';

const TransactionItem = ({
  state,
  createdAt,
  lastTransitionedAt,
  firstName,
  lastName,
  providerName,
  providerCountryId,
  listings,
  id,
  startDate,
  endDate,
  intl,
  processState,
  lineItems,
  partnership
}) => {
  const resolveState = () => {
    const now = moment();
    if (processState === 'accepted') {
      return now.diff(moment(startDate), 'days') > 0 ? intl.formatMessage({ id: "ConsoleTransactionPage.verifyDelayedRelease" }) : null;
    }
    if (processState === 'picked-up') {
      return now.diff(moment(endDate), 'days') > 0 ? intl.formatMessage({ id: "ConsoleTransactionPage.verifyDelayedReturn" }) : null;
    }
    return null;
  }

  const resolvedWarning = resolveState();

  const productsWithActualSeats = lineItems.reduce((accumulator, item) => {
    if (item?.code === 'day') {
      const { listingId, reversal, seats } = item;
      accumulator[listingId] = accumulator[listingId] ? accumulator[listingId] : 0;
      accumulator[listingId] += (reversal ? -1 : 1) * seats;
    }
    return accumulator;
  }, {});

  const listingsWithSeats = listings.map(listing => ({
    ...listing,
    seats: productsWithActualSeats[listing.id.uuid],
  }));

  const sortedListingsWithSeats = listingsWithSeats.sort((a, b) => b.seats - a.seats);
  const stateClassName = classNames({ [css.accepted]: processState === "accepted" });

  return (
    <li className={css.listItem} key={id}>
      <div className={css.transactionDetails}>
        <div className={css.firstTransactionDetails}>
          <div className={css.txHeader}>
            {firstName} {lastName}
            {
              partnership &&
                <LogoMedicoverSport className={classNames(css.medicoverLogo)}/>
            }
          </div>
          {sortedListingsWithSeats.map(({ attributes: { title }, seats }) => (
            <p className={!seats && css.lineThrough}>{seats}{' x '}{title}</p>
          ))}
          <p className={css.providerName}>
            {providerName} <em className={css.providerCountry}>({providerCountryId})</em>
          </p>
          <p>
            {intl.formatDate(startDate, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })} - {intl.formatDate(endDate, { year: 'numeric', month: 'short', day: 'numeric' })}
          </p>
        </div>
        <div className={css.secondTransactionDetails}>
          <p className={stateClassName}>{state}</p>
          <p className={css.date}>
            <FormattedMessage id="Console.createdAt" />
            <span> {createdAt}</span>
          </p>
          <p className={css.date}>
            <FormattedMessage id="Console.lastTransitionedAt" />
            <span> {lastTransitionedAt}</span>
          </p>
          <div>
            {resolvedWarning &&
              <p className={css.warning}>
                {resolvedWarning}
              </p>
            }
          </div>
        </div>
      </div>
      <NamedLink className={css.link} name="SaleDetailsPage" params={{ id }}>
        <FormattedMessage id="Console.transactionDetails" />
      </NamedLink>
    </li>
  );
};

TransactionItem.defaultProps = {
  id: null,
  createdAt: null,
  lastTransitionedAt: null,
  firstName: null,
  lastName: null,
  providerName: null,
  providerCountryId: null,
  listings: [],
};

TransactionItem.propTypes = {
  id: string.isRequired,
  state: string.isRequired,
  createdAt: string.isRequired,
  lastTransitionedAt: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  providerName: string.isRequired,
  providerCountryId: string.isRequired,
  listings: arrayOf(
    shape({
      id: shape({ uuid: string.isRequired }),
      attributes: shape({
        title: string.isRequired,
      }),
    })
  ),
};

export default TransactionItem;

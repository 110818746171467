import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import { intlShape } from '../../../util/reactIntl';
import OriginalOrderItems from './OriginalOrderItems';
import PartiallyCanceledItems from './PartiallyCanceledItems';
import CancelledItems from './CancelledItems';
import {
  TRANSITION_CANCEL_BY_OPERATOR,
  TRANSITION_CANCEL_BY_OPERATOR_AFTER_PICKUP,
  TRANSITION_CANCEL_BY_PROVIDER,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR,
} from '../../../util/transaction';
import { propTypes } from '../../../util/types';

const PARTIAL_CANCEL_TRANSITION = 'partial-cancel';
const CANCEL_TRANSITION = 'cancel';
const CONFIRM_PAYMENT = 'confirm-payment';

const confirmPaymentStatus = [
  TRANSITION_CONFIRM_PAYMENT,
  CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_BOOKING_BY_OPERATOR,
];

const cancelStatus = [
  CANCEL_TRANSITION,
  TRANSITION_CANCEL_BY_OPERATOR,
  TRANSITION_CANCEL_BY_OPERATOR_AFTER_PICKUP,
  TRANSITION_CANCEL_BY_PROVIDER,
];

const ItemSummary = ({ transitions, intl, listings, lineItems }) => {
    return transitions.map(({ transition, relLineItemIndex, relLineItemsAmount }, index) => {

      if (confirmPaymentStatus.includes(transition)) {
        const confirmList = lineItems.slice(
          relLineItemIndex,
          relLineItemIndex + relLineItemsAmount
        );

        return (
          <OriginalOrderItems
            confirmLineItems={confirmList}
            intl={intl}
            listings={listings}
          />
        )
      }

      if (transition === PARTIAL_CANCEL_TRANSITION) {
        const partialCancelLineItems = lineItems.slice(
          relLineItemIndex,
          relLineItemIndex + relLineItemsAmount
        );

        return (
          <PartiallyCanceledItems
            partialCancelLineItems={partialCancelLineItems}
            relLineItemIndex={relLineItemIndex}
            intl={intl}
            listings={listings}
          />
        )
      }

      if (cancelStatus.includes(transition)) {
        const cancelList = lineItems.slice(relLineItemIndex, relLineItemIndex + relLineItemsAmount);

        return (
          <CancelledItems
            cancelList={cancelList}
            intl={intl}
            listings={listings}
          />
        )
      }

      return null;
    });
}

ItemSummary.propTypes = {
  intl: intlShape.isRequired,
  listings: arrayOf(shape({
    id: shape({ uuid: string.isRequired}),
    attributes: shape({
      title: string.isRequired,
    })
  })),
  lineItems: arrayOf(
    shape({
      code: oneOf(['day', 'service-fee', 'long-term-discount', 'promo-discount', 'provider-commission']),
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
    })
  ),
  transitions: arrayOf(propTypes.transition),
};

export default ItemSummary;

import React from 'react';
import { bool, func, object, shape, string, oneOf } from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { types as sdkTypes } from '../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import businessCategoryConfig from '../../business-category-config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';

import { NamedRedirect, Page } from '../../components';
import { EditProductForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import {
  requestCreateProduct,
  requestUpdateProduct,
  requestImageUpload,
  updateImageOrder,
  removeProductImage,
  loadData,
} from './EditProductPage.duck';

import css from './EditProductPage.css';

const { UUID } = sdkTypes;

// N.B. All the presentational content needs to be extracted to their own components
export const EditProductPageComponent = props => {
  const {
    createStripeAccountError,
    fetchInProgress,
    getOwnProduct,
    history,
    intl,
    onImageUpload,
    onRemoveProductImage,
    onManageDisableScrolling,
    onUpdateImageOrder,
    page,
    params,
    scrollingDisabled,
    onCreateProduct,
    onUpdateProduct,
    currentUser
  } = props;

  const { id, type } = params;
  const isNewURI = type === 'new';
  const isNewListingFlow = isNewURI;

  const productId = page.submittedProductId || (id ? new UUID(id) : null);
  const currentListing = getOwnProduct(productId);

  const showForm = isNewURI || currentListing?.id;

  if (showForm) {
    const {
      createListingDraftError = null,
      publishListingError = null,
      updateListingError = null,
      showListingsError = null,
      uploadImageError = null,
    } = page;
    const errors = {
      createListingDraftError,
      publishListingError,
      updateListingError,
      showListingsError,
      uploadImageError,
      createStripeAccountError,
    };

    // Images are passed to EditListingForm so that it can generate thumbnails out of them
    const currentListingImages =
      currentListing && currentListing.images ? currentListing.images : [];

    // Images not yet connected to the listing
    const imageOrder = page.imageOrder || [];
    const unattachedImages = imageOrder.map(i => page.images[i]);

    const allImages = currentListingImages.concat(unattachedImages);
    const removedImageIds = page.removedImageIds || [];
    const images = allImages.filter(img => {
      return !removedImageIds.includes(img.id);
    });

    const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);

    const pageTitle = isNewListingFlow
      ? intl.formatMessage({ id: 'EditProductPage.titleCreateProduct' })
      : intl.formatMessage({ id: 'EditProductPage.titleEditProduct' });

    const { countryId, title, description, modelCode, parts, minBookingDays, businessCategory, price, externalLink } = currentListing?.attributes || {};
    const initialCategory = businessCategoryConfig.find(item => item.key === businessCategory)?.category;
    const { updateProductInProgress, createProductInProgress } = page;

    const saveActionMsg = isNewListingFlow ? intl.formatMessage({ id: 'EditProductPage.add' }) : intl.formatMessage({ id: 'EditProductPage.update' });

    // When user has update draft listing, he should be redirected 'edit''
    const redirectAfterDraftUpdate = (productId, params, history) => {
      const currentPathParams = {
        ...params,
        type: 'edit',
        id: productId,
      };
      const routes = routeConfiguration();

      // Replace current "new" path to "draft" path.
      // Browser's back button should lead to editing current draft instead of creating a new one.
      if (params.type === 'new') {
        const editURI = createResourceLocatorString('EditProductPage', routes, currentPathParams, {});
        history.replace(editURI);
      }
    };

    return (
      <Page title={pageTitle} scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
        <div className={css.root}>
          <div className={css.headerWrapper}>
            <h1 className={css.title}>
              {pageTitle}
            </h1>
          </div>
          <EditProductForm
            className={css.form}
            initialValues={{ countryId, title, category: initialCategory, description, modelCode, externalLink, parts, minBookingDays, businessCategory, price, images }}
            images={images}
            saveActionMsg={saveActionMsg}
            onSubmit={values => {
              const { countryId, title, description, modelCode, parts, externalLink, minBookingDays, businessCategory, images, price } = values;
              const updateValues = {
                countryId,
                title: title.trim(),
                description,
                businessCategory,
                modelCode,
                externalLink,
                parts,
                minBookingDays: parseInt(minBookingDays),
                imageIds: images.map(i => (typeof i.id === 'string' ? i.imageId.uuid : i.id.uuid)),
                price: price.amount
              };
              if (currentListing?.id?.uuid) {
                onUpdateProduct({ id: currentListing.id.uuid, ...updateValues });
              } else {
                onCreateProduct(updateValues)
                  .then(res => redirectAfterDraftUpdate(res.data.data.id.uuid, params, history));
              }
            }}
            disabled={false}
            ready={false}
            updated={false}
            submitInProgress={updateProductInProgress || createProductInProgress}
            fetchErrors={null}
            categories={categoryOptions}
            onImageUpload={onImageUpload}
            onRemoveImage={onRemoveProductImage}
            currentUser={currentUser}
            keepDirtyOnReinitialize
          />
        </div>
      </Page>
    );
  } else {
    // If user has come to this page through a direct linkto edit existing listing,
    // we need to load it first.
    const loadingPageMsg = {
      id: 'EditProductPage.loadingProductData',
    };
    return (
      <Page title={intl.formatMessage(loadingPageMsg)} scrollingDisabled={scrollingDisabled} />
    );
  }
};

EditProductPageComponent.defaultProps = {
  createStripeAccountError: null,
  fetchStripeAccountError: null,
  getAccountLinkError: null,
  getAccountLinkInProgress: null,
  stripeAccountFetched: null,
  currentUser: null,
  stripeAccount: null,
  currentUserHasOrders: null,
  listing: null,
  listingDraft: null,
  notificationCount: 0,
  sendVerificationEmailError: null,
};

const mapStateToProps = state => {
  const page = state.EditProductPage;
  const { currentUser } = state.user;

  const getOwnProduct = id => {
    const products = getMarketplaceEntities(state, [{ id, type: 'catalog-product' }]);
    return products.length === 1 ? products[0] : null;
  };

  return {
    currentUser,
    fetchInProgress: false,
    getOwnProduct,
    page,
    scrollingDisabled: isScrollingDisabled(state)
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProduct: (values) => dispatch(requestUpdateProduct(values)),
  onCreateProduct: values => dispatch(requestCreateProduct(values)),
  onImageUpload: data => dispatch(requestImageUpload(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateImageOrder: imageOrder => dispatch(updateImageOrder(imageOrder)),
  onRemoveProductImage: imageId => dispatch(removeProductImage(imageId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const EditProductPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(injectIntl(EditProductPageComponent));

EditProductPage.loadData = loadData;

export default EditProductPage;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayOf, oneOf, shape, string } from 'prop-types';

const InvoiceLink = ({ documents, invoiceClassName }) => {
  if (documents.filter(d => d.type === 'invoice' || d.type === 'invoice-correction').length <= 0) return null;

  return (
      <div key="invoice">
        {documents.filter(d => d.type === 'invoice').map((document, index) => (
          <a
            className={invoiceClassName}
            key={index}
            href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${document.url}`}
            target="_blank"
          >
            <FormattedMessage id="OrderInfo.doc-invoice" />
          </a>
        ))}
        {documents.filter(d => d.type === 'invoice-correction').map((document, index) => (
          <a
            className={invoiceClassName}
            key={index}
            href={`${process.env.REACT_APP_CANONICAL_ROOT_URL}${document.url}`}
            target="_blank"
          >
            <FormattedMessage id="OrderInfo.doc-invoice-correction" />
          </a>
        ))}
      </div>
    );
}

InvoiceLink.propTypes = {
  documents: arrayOf(shape({
    type: oneOf(['protocol', 'invoice', 'invoice-correction']),
    url: string,
  })),
  invoiceClassName: string,
}

export default InvoiceLink;

import React, { Component } from 'react';
import { bool, func, instanceOf, object, oneOfType, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { LINE_ITEM_SERVICE_FEE, propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
} from '../../util/data';
import { minutesBetween } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';

import {
  TRANSITION_ENQUIRE,
  txIsPaymentPending,
  txIsPaymentExpired,
  typesOfServiceFee,
} from '../../util/transaction';
import {
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  Order,
  ServiceFeeSummary,
  ServiceFeeItem
} from '../../components';
import { StripePaymentFormV2 } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  handleCardPayment,
  handleP24Payment,
  retrievePaymentIntent,
} from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';

import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  stripeCustomer,
  confirmPayment,
  confirmP24Payment,
  sendMessage,
  updateProfile,
  fetchTransaction
} from './PaymentPage.duck';
import { storeData, clearData } from './CheckoutPageSessionHelpers';
import css from './CheckoutPage.css';
import parseCheckoutFields from './parseCheckoutFields';
import OrderSummary from './OrderSummary/OrderSummary';
import { finishedCheckoutEvent } from '../../analytics/gaEvents';

const { Money } = sdkTypes;

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
      ? PAY_AND_SAVE_FOR_LATER_USE
      : ONETIME_PAYMENT;
};

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
      ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
      : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      validatingPromoCode: false,
      promoCode: '',
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  loadInitialData() {
    const {
      fetchStripeCustomer,
      onFetchTransaction,
      params
    } = this.props;

    fetchStripeCustomer();
    onFetchTransaction(params.transactionId).then(() => this.setState({ dataLoaded: true }));
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onHandleCardPayment,
      onHandleP24Payment,
      onConfirmPayment,
      onSendMessage,
      onSavePaymentMethod,
      onUpdateProfile,
      currentTransaction
    } = this.props;
    const {
      bookingAdditionalData,
      pageData,
      message,
      paymentIntent,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(currentTransaction);

    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    let createdPaymentIntent = null;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents = storedTx.attributes && storedTx.attributes.stripePaymentIntent;
      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = fnParams => {
      // fnParams should be returned transaction entity
      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents = order.attributes && order.attributes.stripePaymentIntent;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { clientSecret: stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.stripePaymentIntent
        : null;

      const {
        stripe,
        card,
        elements,
        billingDetails,
        paymentIntent,
      } = handlePaymentParams;

      const stripeElementMaybe = selectedPaymentFlow !== USE_SAVED_CARD ? { card } : {};

      // Note: payment_method could be set here for USE_SAVED_CARD flow.
      // { payment_method: stripePaymentMethodId }
      // However, we have set it already on API side, when PaymentIntent was created.
      const paymentParams =
        selectedPaymentFlow !== USE_SAVED_CARD
          ? {
            payment_method_data: {
              billing_details: billingDetails,
            },
          }
          : {};

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        ...stripeElementMaybe,
        elements,
        paymentParams,
        transactionProtectedData: order.attributes.protectedData,
      };

      const handlePaymentFn =
        selectedPaymentMethod === 'p24' ? onHandleP24Payment : onHandleCardPayment;

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({
          transactionId: order.id,
          paymentIntent,
          transactionProtectedData: params.transactionProtectedData,
        })
        : handlePaymentFn(params);
    };

    // Step 3a: complete order by confirming payx ment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = fnParams => {
      // createdPaymentIntent = fnParams.paymentIntent;
      return onConfirmPayment(fnParams);
    };

    // Step 4: send initial message
    const fnSendMessage = fnParams => {
      return onSendMessage({ ...fnParams, message });
    };

    // Step 5: optionally save card as defaultPaymentMethod
    const fnSavePaymentMethod = fnParams => {
      const pi = createdPaymentIntent || paymentIntent;

      if (selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE) {
        return onSavePaymentMethod(ensuredStripeCustomer, pi.payment_method)
          .then(response => {
            if (response.errors) {
              return { ...fnParams, paymentMethodSaved: false };
            }
            return { ...fnParams, paymentMethodSaved: true };
          })
          .catch(e => {
            // Real error cases are catched already in paymentMethods page.
            return { ...fnParams, paymentMethodSaved: false };
          });
      } else {
        return Promise.resolve({ ...fnParams, paymentMethodSaved: true });
      }
    };

    const fnSaveInvoiceDetails = fnParams => {
      const { bookingAdditionalData } = handlePaymentParams;
      if (bookingAdditionalData.saveInvoiceDetails) {
        const updatedUserParams = {
          defaultTransactionMeta: {
            ...bookingAdditionalData.invoice,
            customerType: bookingAdditionalData.customerType,
          },
        };
        return onUpdateProfile(updatedUserParams).then(res => {
          return { ...fnParams };
        });
      } else {
        return Promise.resolve({ ...fnParams });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage,
      fnSavePaymentMethod,
      fnSaveInvoiceDetails
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
          ? { setupPaymentMethodForSaving: true }
          : {};

    const orderParams = {
      listings: tx.listings,
      bookingStart: tx.attributes.start,
      bookingEnd: tx.attributes.end,
      voucherCode: bookingAdditionalData.voucherCode,
      ...optionalPaymentParams,
      protectedData: bookingAdditionalData,
      selectedPaymentMethod,
      providerId: tx.provider.id.uuid,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, paymentIntent, dispatch } = this.props;

    const { card, message, paymentMethod, formValues, elements, paymentMethodType } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      saveAfterOnetimePayment,
      customerFirstName,
      customerLastName,
      customerName,
      customerEmail,
      customerPhoneNumber,
      receiver,
      deliveryLocation,
      checkoutFields,
      customerType,
      delivery,
      saveInvoiceDetails,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const invoiceMaybe =
      checkoutFields && checkoutFields.invoice ? { invoice: checkoutFields.invoice } : {};
    const { invoice, ...rest } = checkoutFields || {};


    const requestPaymentParams = {
      bookingAdditionalData: {
        customerFirstName,
        customerLastName,
        customerName,
        customerEmail,
        customerPhoneNumber,
        receiver,
        deliveryLocation,
        customerType,
        saveInvoiceDetails,
        ...invoiceMaybe,
      },
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      elements: elements,
      billingDetails,
      message,
      paymentIntent,
      selectedPaymentMethod: paymentMethod,
      saveAfterOnetimePayment: !!saveAfterOnetimePayment,
    };


    const tx = this.props.currentTransaction;
    const discountValue = tx.attributes.lineItems.filter(li => li && (li.code === 'promo-discount' || li.code === 'long-term-discount')).reduce((acc, li) => acc + li.lineTotal.amount, 0);

    const discountFields = {
      discount: !!discountValue,
      discountValue: discountValue / 100
    };

    finishedCheckoutEvent({
      currency: tx.attributes.payinTotal.currency,
      paymentType: paymentMethodType,
      value: tx.attributes.payinTotal.amount / 100,
      ...discountFields,
      items: tx.listings.map(l => ({
        id: l.id.uuid,
        name: l.attributes.title,
        category: l.attributes.businessCategory,
        provider: tx.provider.attributes.name,
        price: l.attributes.price.amount / 100,
        quantity: l.attributes.amount,
      })),
    })

    this.handlePaymentIntent(requestPaymentParams)
  .then(res => {
    const { orderId, messageSuccess, paymentMethodSaved } = res;
    this.setState({ submitting: false });

    const routes = routeConfiguration();
    const initialMessageFailedToTransaction = messageSuccess ? null : orderId;
    const orderPath = tx.attributes.serviceFee ? "ServiceFeeDetailsSuccessPage" : "OrderDetailsSuccessPage";
    const orderDetailsPath = pathByRouteName(orderPath, routes, {
      id: orderId.uuid,
    });
    const initialValues = {
      initialMessageFailedToTransaction,
      savePaymentMethodFailed: !paymentMethodSaved,
    };

    initializeOrderPage(initialValues, routes, dispatch);
    clearData(STORAGE_KEY);

    history.push(orderDetailsPath);
  })
  .catch(err => {
    console.error(err);
    this.setState({ submitting: false });
  });
  }

onStripeInitialized(stripe) {
  this.stripe = stripe;

  const {
    history,
    paymentIntent,
    onRetrievePaymentIntent,
    onConfirmPayment,
    onConfirmP24Payment,
  } = this.props;
  const tx = this.state.pageData ? this.state.pageData.transaction : null;

  // We need to get up to date PI, if booking is created but payment is not expired.
  const shouldFetchPaymentIntent =
    this.stripe &&
    !paymentIntent &&
    tx &&
    tx.id &&
    tx.booking &&
    tx.booking.id &&
    txIsPaymentPending(tx) &&
    !checkIsPaymentExpired(tx);

  if (shouldFetchPaymentIntent) {
    const { stripePaymentIntentClientSecret } =
      tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
        ? tx.attributes.protectedData.stripePaymentIntents.default
        : {};

    // Fetch up to date PaymentIntent from Stripe
    onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret }).then(res => {
      if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
        const confirmFn =
          tx.attributes.lastTransition === 'transition/request-push-payment'
            ? onConfirmP24Payment
            : onConfirmPayment;
        confirmFn({
          paymentIntent: res.paymentIntent,
          transactionId: tx.id,
          transactionProtectedData: tx.attributes.protectedData,
        }).then(res => {
          const routes = routeConfiguration();
          const orderDetailsPath = pathByRouteName('OrderDetailsSuccessPage', routes, {
            id: tx.id.uuid,
          });
          history.push(orderDetailsPath);
        });
      }
    });
  }
}

render() {
  const {
    scrollingDisabled,
    speculateTransactionError,
    speculatedTransaction: speculatedTransactionMaybe,
    initiateOrderError,
    confirmPaymentError,
    intl,
    params,
    currentUser,
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    stripeCustomerFetched,
    currentTransaction
  } = this.props;

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (!currentTransaction?.id?.uuid) {
    return <Page>{topbar}</Page>;
  }

  if (currentTransaction.attributes.processState !== 'pending-payment') {
    return <NamedRedirect name="OrderDetailsPage" params={{ id: currentTransaction.id.uuid }} />;
  }

  // Since the listing data is already given from the ListingPage
  // and stored to handle refreshes, it might not have the possible
  // deleted or closed information in it. If the transaction
  // initiate or the speculative initiate fail due to the listing
  // being deleted or closec, we should dig the information from the
  // errors and not the listing data.
  const listingNotFound =
    isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
    isTransactionInitiateListingNotFoundError(initiateOrderError);

  const {
    listings,
    provider,
  } = currentTransaction;

  // const redirectToCart = !bookingDates && !listings && !provider;
  // if (redirectToCart) return <NamedRedirect name="CartPage" />;
  // if (redirectToCart) return <div>Loading</div>

  const existingTransaction = ensureTransaction(currentTransaction);
  const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe);
  const currentListing = ensureListing(listings[0]);
  const currentAuthor = provider;

  const title = intl.formatMessage({ id: 'CheckoutPage.title' });

  const currentAuthorDisplayName = currentAuthor.attributes.name;

  const pageProps = { title, scrollingDisabled, className: css.page };

  const tx =
    existingTransaction && existingTransaction.id ? existingTransaction : speculatedTransaction;
  const { start, end, payinTotal, lineItems, serviceFee } = tx.attributes;

  const isPaymentExpired = !serviceFee && checkIsPaymentExpired(existingTransaction);

  const hasDefaultPaymentMethod = !!(
    stripeCustomerFetched &&
    ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
    ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
  );

  // Allow showing page when currentUser is still being downloaded,
  // but show payment form only when user info is loaded.
  const showPaymentForm = this.state.dataLoaded;

  const listingLink = (
    <NamedLink name="CartPage">
      <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
    </NamedLink>
  );

  const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
  const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);
  const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
    initiateOrderError
  );
  const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

  let initiateOrderErrorMessage = null;
  let listingNotFoundErrorMessage = null;

  if (listingNotFound) {
    listingNotFoundErrorMessage = (
      <p className={css.notFoundError}>
        <FormattedMessage id="CheckoutPage.listingNotFoundError" />
      </p>
    );
  } else if (isAmountTooLowError) {
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      </p>
    );
  } else if (isBookingTimeNotAvailableError) {
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      </p>
    );
  } else if (isChargeDisabledError) {
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
      </p>
    );
  } else if (stripeErrors && stripeErrors.length > 0) {
    // NOTE: Error messages from Stripes are not part of translations.
    // By default they are in English.
    const stripeErrorsAsString = stripeErrors.join(', ');
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage
          id="CheckoutPage.initiateOrderStripeError"
          values={{ stripeErrors: stripeErrorsAsString }}
        />
      </p>
    );
  } else if (initiateOrderError) {
    // Generic initiate order error
    initiateOrderErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.initiateOrderError" values={{ listingLink }} />
      </p>
    );
  }

  let speculateErrorMessage = null;

  if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
    speculateErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
      </p>
    );
  } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
    speculateErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
      </p>
    );
  } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
    speculateErrorMessage = (
      <p className={css.orderError}>
        <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
      </p>
    );
  }

  // temporarly block sending initial messages
  const showInitialMessageInput =
    false &&
    !(
      existingTransaction && existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

  // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
  const userName =
    currentUser && currentUser.attributes
      ? `${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`
      : null;

  // If paymentIntent status is not waiting user action,
  // handleCardPayment has been called previously.
  const hasPaymentIntentUserActionsDone =
    paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

  // If your marketplace works mostly in one country you can use initial values to select country automatically
  // e.g. {country: 'FI'}
  const { email, phoneNumber, defaultTransactionMeta } = ensureUser(currentUser).attributes;

  const defaultTransactionMetaMaybe = defaultTransactionMeta && (currentAuthor.attributes.countryId === 'hu' ||
    (currentAuthor.attributes.countryId === 'pl' && currentAuthor.attributes.isDecathlon))
    ? {
      checkoutFields: {
        invoice: {
          ...defaultTransactionMeta,
        },
      },
    }
    : {};

  const initialCustomerType =
    defaultTransactionMeta && defaultTransactionMeta.customerType
      ? defaultTransactionMeta.customerType
      : 'individual';

  const initalValuesForStripePayment = {
    name: userName,
    receiver: userName,
    customerName: userName,
    customerFirstName: currentUser?.attributes.firstName || null,
    customerLastName: currentUser?.attributes.lastName || null,
    customerEmail: email,
    customerPhoneNumber: phoneNumber,
    // for hungarian invoices purposes
    customerType: initialCustomerType,
    ...defaultTransactionMetaMaybe,
  };

  const homeDelivery = currentListing.attributes.deliveryToHome;
  const deliveryToProviders = currentListing.attributes.deliveryToProviders;
  // const p24PaymentEnabled = !!currentListing.attributes.metadata.p24_payment_enabled || !!currentAuthor.attributes.profile.metadata.p24_payment_enabled;
  const p24PaymentEnabled = currentAuthor.attributes.countryId === 'pl';

  const showDeliveryToHome = listings.some(listing => listing.attributes.deliveryToHome);
  const showDeliveryToProviders = listings.some(
    listing => listing.attributes.deliveryToProviders
  );
  const isStoreSelect = showDeliveryToProviders && !showDeliveryToHome;

  const showSummary = displayClass => (
    <div className={classNames(displayClass, css.summaryContainer)}>
      {serviceFee
         ? <ServiceFeeSummary
            intl={intl}
            transaction={currentTransaction}
          />
        : <OrderSummary
            start={start}
            end={end}
            payinTotal={payinTotal}
            lineItems={lineItems}
            cartListings={listings}
            intl={intl}
          />
      }
    </div>
  );

  const listingsToOrder = lineItems.filter(l => l && l.code === 'day').map(li => {
    return {
      listing: listings.find(l => l.id.uuid === li.listingId),
      attributes: { amount: li.seats },
      id: { uuid: '' },
    }
  });

  const messageOfSubmitBtn = serviceFee
    ? "StripePaymentForm.payServiceFee"
    : "StripePaymentForm.payButton";

  const orderSummaryForPayment = (serviceFee
      ? <ServiceFeeItem
        orders={listings}
        intl={intl}
        provider={provider}
        className={css.serviceFeeContainer}
        serviceFee={serviceFee}
        payinTotal={payinTotal}
        serviceFeeFor={[]}
      />
      : <Order
        orders={listingsToOrder}
        provider={provider}
        intl={intl}
        className={css.orderContainer}
        warnings={[]}
      />
  );

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.container}>

        <>
          <div className={css.wrapper}>
            <div className={css.paymentDetailsContainer}>
              {orderSummaryForPayment}
              <section className={css.paymentContainer}>
                {initiateOrderErrorMessage}
                {listingNotFoundErrorMessage}
                {speculateErrorMessage}
                {retrievePaymentIntentError ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.retrievingStripePaymentIntentFailed"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
                {showSummary(css.mobileContainer)}

                {showPaymentForm ? (
                  <StripePaymentFormV2
                    currentTransaction={currentTransaction}
                    checkoutFields={currentListing.attributes.checkoutFields}
                    className={css.paymentForm}
                    onSubmit={this.handleSubmit}
                    inProgress={this.state.submitting}
                    formId="CheckoutPagePaymentForm"
                    paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                    authorDisplayName={currentAuthorDisplayName}
                    currentProvider={currentAuthor}
                    homeDelivery={homeDelivery}
                    deliveryToProviders={deliveryToProviders}
                    showInitialMessageInput={showInitialMessageInput}
                    initialValues={initalValuesForStripePayment}
                    initiateOrderError={initiateOrderError}
                    handleCardPaymentError={handleCardPaymentError}
                    confirmPaymentError={confirmPaymentError}
                    hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                    loadingData={!stripeCustomerFetched}
                    defaultPaymentMethod={
                      hasDefaultPaymentMethod
                        ? currentUser.stripeCustomer.defaultPaymentMethod
                        : null
                    }
                    p24PaymentEnabled={p24PaymentEnabled}
                    paymentIntent={paymentIntent}
                    onStripeInitialized={this.onStripeInitialized}
                    providerRules={currentAuthor.attributes.rentTerms}
                    listings={listings}
                    warnings={[]}
                    isStoreSelect={isStoreSelect}
                    isServiceFee={!!serviceFee}
                    messageOfSubmitBtn={messageOfSubmitBtn}
                  />
                ) : null}
                {isPaymentExpired ? (
                  <p className={css.orderError}>
                    <FormattedMessage
                      id="CheckoutPage.paymentExpiredMessage"
                      values={{ listingLink }}
                    />
                  </p>
                ) : null}
              </section>
            </div>
            {showSummary(css.desktopContainer)}
          </div>
        </>
      </div>
    </Page>
  );
}
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchStripeCustomer: func.isRequired,
  stripeCustomerFetched: bool.isRequired,
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onConfirmPayment: func.isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  onSavePaymentMethod: func.isRequired,
  onSendMessage: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const {
    listing,
    listings,
    provider,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    validatePromoInProgress,
    validatePromoInfo,
    validatePromoError,
    totalNumberOfOrders,
    currentTransactionRef,
  } = state.PaymentPage;
  const { currentUser } = state.user;
  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;
  const transactions = getMarketplaceEntities(state, currentTransactionRef ? [currentTransactionRef] : []);
  const currentTransaction = transactions.length > 0 ? transactions[0] : null;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    listings,
    provider,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    validatePromoInProgress,
    validatePromoInfo,
    validatePromoError,
    totalNumberOfOrders,
    currentTransaction,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, validatingPromoCode) =>
    dispatch(speculateTransaction(params, validatingPromoCode)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId) => dispatch(initiateOrder(params, transactionId)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onHandleP24Payment: params => dispatch(handleP24Payment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onConfirmP24Payment: params => dispatch(confirmP24Payment(params)),
  onSendMessage: params => dispatch(sendMessage(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onUpdateProfile: params => dispatch(updateProfile(params)),
  onFetchTransaction: params => dispatch(fetchTransaction(params))
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;

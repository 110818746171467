import React from 'react';
import classNames from 'classnames';
import { bool, func, node, oneOf, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import {
  txRoleIsCustomer,
  getUserTxRole,
} from '../../util/transaction';
import {
  IconArrowHead,
  NamedLink,
  SecondaryButton,
  OrderItemInfo,
  FeedSection,
  RelatedTransactionsSection,
  OrderDetails
} from '..';
import ServiceFeeDetails from './ServiceFeeDetails/ServiceFeeDetails';

import css from './ServiceFeePanel.css';

//A fee with the 'decline' status is an unpaid and canceled fee,
// while a fee with the 'cancelled' status is paid and canceled.
const stateOptions = [
  { status: "pending", textStatus: "DetailOrder.pendingPayment", class: css.pending },
  { status: "declined", textStatus: "DetailOrder.cancelled", class: css.cancelled },
  { status: "accepted", textStatus: "DetailOrder.paid", class: css.delivered },
  { status: "cancelled", textStatus: "DetailOrder.cancelled", class: css.cancelled },
];

const ServiceFeePanelComponent = ({
  transaction,
  intl,
  transactionRole,
  cancelInProgress,
  onCancel,
  currentUser,
  currentUserProviderId,
  history,
  transactionSuccessComponent
}) => {
  const providerName = transaction.provider.attributes.name;
  const listings = transaction.listings;
  const isCustomerLoaded = !!transaction.user.id;
  const isCustomerBanned = isCustomerLoaded && transaction.user.attributes.banned;
  const isProvider = transactionRole === 'provider';
  const ownRole = getUserTxRole(currentUser.id, transaction);
  const { processState: status, lineItems, transitions, state } = transaction.attributes;
  const showServiceFeeCancelBtn = ['pending', 'accepted'].includes(transaction.attributes.state)
    && isProvider && !isCustomerBanned;
  const isRelatedTransactions = transaction && transaction.relatedTransactions.length;
  const transactionStatusAttributes = stateOptions.find((item) => item.status === state);
  const isAdmin = currentUser.attributes.isAdmin;

  return (
    <div className={css.container}>
      {transactionSuccessComponent}
      <div className={css.navigation}>
        <NamedLink
          className={classNames(css.text, css.link)}
          name="ServiceFeeInboxPage"
          params={{
            tab: isProvider ? 'sales' : 'orders',
            providerId: currentUserProviderId || isProvider ? 'me' : 'orders',
          }}
        >
          <FormattedMessage id="ServiceFeeInboxPage.fees" />
        </NamedLink>
        <IconArrowHead direction="right" className={css.arrow} />
        <span className={css.text}>
          <FormattedMessage id="OrderDetails.serviceFeeFor" values={{ providerName }} />
        </span>
      </div>
      <div className={css.wrapper}>
        <OrderDetails
          providerName={providerName}
          listings={listings}
          intl={intl}
          actualOrderedProducts={[]}
          transaction={transaction}
          codeMessage="OrderDetails.codeMessage"
          titleMessage="OrderDetails.serviceFeeFor"
          transactionStatusAttributes={transactionStatusAttributes}
        >
          <ServiceFeeDetails
            isProvider={isProvider}
            isOrder={txRoleIsCustomer(ownRole)}
            transaction={transaction}
            intl={intl}
          />
        </OrderDetails>
        <div className={css.actionButtons}>
          {showServiceFeeCancelBtn ?
             <div className={css.actionButtonWrapper}>
                <SecondaryButton
                  className={classNames(css.actionButton, css.cancelServiceFee)}
                  inProgress={cancelInProgress}
                  onClick={() => onCancel(transaction.id)}
                  confirmationNeeded
                >
                  <FormattedMessage id="TransactionPanel.cancelServiceFee" />
                </SecondaryButton>
             </div>
          : null}
        </div>
      </div>
      <div className={css.serviceFeeContainer}>
        <div className={css.serviceFeeWrapper}>
          <OrderItemInfo
            transaction={transaction}
            deliveryToProvider={transaction.deliveryToProvider}
            provider={transaction.provider}
            listings={transaction.listings}
            intl={intl}
            isAdminOrProvider={currentUser.attributes.isAdmin || isProvider}
            isProvider={isProvider}
            isAdmin={isAdmin}
          />
          {isRelatedTransactions ?
             <RelatedTransactionsSection
              transaction={transaction}
              intl={intl}
              history={history}
              containerClassName={css.relatedTransactions}
            />
          : null}
        </div>
        <div className={css.messagesSection}>
          <FeedSection
            rootClassName={css.feedContainer}
            currentTransaction={transaction}
            currentUser={currentUser}
            onOpenReviewModal={() =>{}}
            totalMessagePages={0}
            isProvider={isProvider}
          />
      </div>
      </div>
    </div>
  );
};

ServiceFeePanelComponent.propTypes = {
  currentUserProviderId: string,
  transactionRole: oneOf(['provider', 'customer']).isRequired,
  history: shape({
    location: shape({
      pathname: string,
    })
  }).isRequired,
  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  cancelInProgress: bool.isRequired,
  onCancel: func.isRequired,
  transactionSuccessComponent: node,
};

const ServiceFeePanel = injectIntl(ServiceFeePanelComponent);

export default ServiceFeePanel;

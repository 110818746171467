import React, { useState } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { Input, Select } from "../../../components";
import { parse, stringify } from "../../../util/urlHelpers";
import { propTypes } from "../../../util/types";
import { intlShape } from "../../../util/reactIntl";

import css from "./ServiceFeeFilters.css";

const ServiceFeeFilters = (props) => {
  const { isOrders, location, history, currentUserProviders, providerId, intl, stateOptions } = props;
  const { search, pathname } = location;
  const { phrase, state } = parse(search);
  const [searchPhrase, setSearchPhrase] = useState(phrase);

  const handleStateChange = event => {
    const stateParam = event.target.value === "all" ? undefined : event.target.value;

    history.push(
      `${pathname}?${stringify({ state: stateParam, page: 1, })}`
    );
  };
  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), phrase: searchPhrase })}` });
  };

  const handleProviderChange = (e) => {
    history.push({
      pathname: `/fee/sales/${e.target.value}`,
      search: `${stringify({ ...parse(search) })}`,
    });
  }

  return (
    <div className={css.container}>
      {!isOrders ?
        <>
          <Select
            value={providerId}
            onChange={handleProviderChange}
            label={intl.formatMessage({ id: "ServiceFeeInboxPage.provider" })}
            isValidation={false}
          >
            <option value="me">{intl.formatMessage({ id: "ServiceFeeInboxPage.all" })}</option>
            {currentUserProviders.map(provider => {
              return (
                <option value={provider.id.uuid}>{provider.attributes.name}</option>
              );
            })
            }
          </Select>
          <form onSubmit={handleSubmit}>
            <Input
              label={intl.formatMessage({ id: "ServiceFeeInboxPage.search" })}
              value={searchPhrase}
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: "ServiceFeeInboxPage.searchTransaction" })}
              isValidation={false}
            />
          </form>
        </>
      : null}
      <Select
        value={state || "all"}
        onChange={handleStateChange}
        label={intl.formatMessage({ id: "ServiceFeeInboxPage.serviceFeeStatus" })}
        isValidation={false}
      >
        {stateOptions.map(({ value, label }) => (
          <option value={value}>{intl.formatMessage({ id: label })}</option>
        ))}
      </Select>
    </div>
  )
}

ServiceFeeFilters.propTypes = {
  intl: intlShape.isRequired,
  stateOptions: arrayOf(shape({
    value: string,
    label: string,
    className: string
  })),
  providerId: string.isRequired,
  isOrders: bool.isRequired,
  location: shape({
    search: string.isRequired,
    pathname: string.isRequired,
  }).isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  currentUserProviders: arrayOf(propTypes.providerDetails)
};

export default ServiceFeeFilters;

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { intlShape } from '../../../../util/reactIntl';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import { formatMoney } from '../../../../util/currency';
import { propTypes } from '../../../../util/types';

import css from '../../OrderItemInfo.css';

const PromoDiscount = ({ discount, intl }) => {
  if (!discount || !discount.lineTotal.amount) return null;

  const { lineTotal, code, percentage, reversal } = discount;

  return (
    <p
      className={classNames(css.text, css.priceContainer)}
      key={reversal ? `${code}_1` : `${code}_0`}
    >
        <span className={css.productName}>
          <FormattedMessage id="OrderInfo.discount" />
          {percentage && <span>{` ${percentage}%`}</span>}
        </span>
      <span className={css.text}>{formatMoney(intl, lineTotal)}</span>
    </p>
  );
}

PromoDiscount.propTypes = {
  intl: intlShape.isRequired,
  discount: shape({
      code: propTypes.value('promo-discount').isRequired,
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
  })
}

export default PromoDiscount;

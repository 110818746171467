import React from 'react';
import PropTypes, { func, shape } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import AutocompleteSelect from '../AutocompleteSelect/AutocompleteSelect';

import css from './FieldAutocompleteSelect.css';

const FieldAutocompleteSelectComponent = props => {
  const {
    rootClassName,
    className,
    id,
    label,
    placeholder,
    inputValue,
    onChange,
    meta,
    markRequired,
    options,
    disabled,
    textRequired,
    validate,
    input,
    requiredProvider,
    ...rest
  } = props;

  const { value, ...inputProps } = input;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { invalid, touched, error } = meta;
  const hasError = touched && invalid && error;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <AutocompleteSelect
        inputValue={inputValue}
        onChange={onChange}
        id={id}
        label={label}
        error={hasError && requiredProvider}
        markRequired={markRequired}
        placeholder={placeholder}
        options={options}
        disabled={disabled}
        {...inputProps}
      />
    </div>
  );
};

FieldAutocompleteSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
  placeholder: null,
  value: {value: '', label: ''},
};

const { string, object, node, bool } = PropTypes;

FieldAutocompleteSelectComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string,
  label: string,
  placeholder: string,
  input: object.isRequired,
  meta: object.isRequired,
  markRequired: bool,
  onChange: func,
  value: shape({ value: string, label: string }),
};

const FieldAutocompleteSelect = props => {
  return <Field component={FieldAutocompleteSelectComponent} {...props} />;
};

export default FieldAutocompleteSelect;

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import { formatMoney } from '../../../util/currency';
import { intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';

import css from '../OrderItemInfo.css';

const ServiceFeeItem = ({ serviceFeeLineItem, intl, idMessage }) => {

  return (
    <div key="confirm">
      <p className={classNames(css.text, css.priceContainer)} key="serviceFee">
        <span className={css.productName}>
          <FormattedMessage id="TransactionPanel.serviceFee" />
        </span>
        <span className={css.text}>
          {formatMoney(intl, serviceFeeLineItem[0].lineTotal)}
        </span>
      </p>
      <p className={classNames(css.subtitle, css.borderBottom, css.priceContainer)}>
        <FormattedMessage id={idMessage} /> {formatMoney(intl, serviceFeeLineItem[0].lineTotal)}
      </p>
    </div>
  );
}

ServiceFeeItem.defaultProps = {
  serviceFeeLineItem: [],
}

ServiceFeeItem.propTypes = {
  intl: intlShape.isRequired,
  idMessage: string.isRequired,
  serviceFeeLineItem: arrayOf(
    shape({
      code: propTypes.value('service-fee').isRequired,
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
    })
  )
};

export default ServiceFeeItem;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import {
  LINE_ITEM_DAY,
  LINE_ITEM_LONG_TERM_DISCOUNT,
  LINE_ITEM_PROMO_DISCOUNT,
} from '../../../util/types';
import { intlShape } from '../../../util/reactIntl';
import ListingsItem from './ListingsItem/ListingsItem';

import css from '../OrderItemInfo.css';

const PartiallyCanceledItems = ({ partialCancelLineItems, relLineItemIndex, listings, intl }) => {
  const partialCancelProductList = partialCancelLineItems.filter(
    item => item?.code === LINE_ITEM_DAY
  );

  const longTermDiscountList = partialCancelLineItems.filter(
    item => item?.code === LINE_ITEM_LONG_TERM_DISCOUNT
  );

  const promoDiscountList = partialCancelLineItems.filter(
    item => item?.code === LINE_ITEM_PROMO_DISCOUNT
  );

  const productsId = [];

  partialCancelProductList.forEach(
    ({ listingId }) => !productsId.includes(listingId) && productsId.push(listingId)
  );

  const partialCancelProducts = productsId.map(id => {
    const productData = partialCancelProductList.filter(({ listingId }) => id === listingId);
    const seatDifference =
      productData.length > 1
        ? productData[1].seats - productData[0].seats
        : -productData[0].seats;
    const amountDifference =
      productData.length > 1
        ? productData[1].lineTotal.amount + productData[0].lineTotal.amount
        : productData[0].lineTotal.amount;
    const lineTotalDifference = {
      amount: amountDifference,
      currency: productData[0].lineTotal.currency,
    };

    return {
      ...productData[0],
      seats: seatDifference,
      lineTotal: lineTotalDifference,
    };
  });

  const currency = partialCancelProducts[0].lineTotal.currency;
  const discountReturned = longTermDiscountList.length
    ? {
      ...longTermDiscountList[0],
      unitPrice: {
        amount:
          longTermDiscountList[1].unitPrice.amount + longTermDiscountList[0].unitPrice.amount,
        currency: longTermDiscountList[0].unitPrice.currency,
      },
      lineTotal: {
        amount:
          longTermDiscountList[1].lineTotal.amount + longTermDiscountList[0].lineTotal.amount,
        currency: longTermDiscountList[0].lineTotal.currency,
      },
    }
    : null;

  const promoDiscountReturned = promoDiscountList.length
    ? {
      ...promoDiscountList[0],
      unitPrice: {
        amount: promoDiscountList[1].unitPrice.amount + promoDiscountList[0].unitPrice.amount,
        currency: promoDiscountList[0].unitPrice.currency,
      },
      lineTotal: {
        amount: promoDiscountList[1].lineTotal.amount + promoDiscountList[0].lineTotal.amount,
        currency: promoDiscountList[0].lineTotal.currency,
      },
    }
    : null;

  const sum = [...partialCancelProducts, discountReturned, promoDiscountReturned].reduce(
    (accumulator, currentValue) =>
      currentValue ? accumulator + currentValue?.lineTotal?.amount : accumulator,
    0
  );

  const refund = { amount: sum, currency };

  return (
    <ListingsItem
      key={`partial_cancel${relLineItemIndex}`}
      products={partialCancelProducts}
      listings={listings}
      intl={intl}
      longTermDiscount={discountReturned}
      promoDiscount={promoDiscountReturned}
      money={refund}
      idMessage="OrderInfo.refund"
    >
      <p className={css.subtitle}>
        <FormattedMessage id="OrderInfo.canceled" />
      </p>
    </ListingsItem>
  );
}

PartiallyCanceledItems.propTypes = {
  intl: intlShape.isRequired,
  listings: arrayOf(shape({
    id: shape({ uuid: string.isRequired}),
    attributes: shape({
      title: string.isRequired,
    })
  })),
  relLineItemIndex: number,
  partialCancelLineItems: arrayOf(
    shape({
      code: oneOf(['day', 'service-fee', 'long-term-discount', 'promo-discount', 'provider-commission']),
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
    })
  )};

export default PartiallyCanceledItems;

import moment from 'moment/moment';

import { setOrderedProducts } from '../../components/TransactionPanel/setOrderedProducts';

/**
 * Payload formatting from service fee transaction query.
 *
 * @param {Object} payload - Payload the service fee transaction query.
 *
 * @return {{
 * formattedResponse: Object,
 * originTransaction: Object | undefined,
 * bookingDatesOfServiceFee: Object,
 * listingsOfServiceFee: Array,
 * transactionIsPaid: boolean }} - formatted data for checkout.
 */

export const getFormattedServiceFeeResponse = (payload) => {
  const data = payload && payload?.data?.data;
  const includedDate = payload && payload?.data?.included;

  if (!payload || !data || !includedDate) return {
    formattedResponse: undefined,
    originTransaction: undefined,
    bookingDatesOfServiceFee: undefined,
    listingsOfServiceFee: [],
    transactionIsPaid: false
  }

  const formattedResponse = {
    attributes: data.attributes,
    id: data.id,
    provider: includedDate.find(i => i.id.uuid === data.relationships.provider.data.id.uuid),
    deliveryToProvider: data.relationships.deliveryToProvider.data
      ? includedDate.find(i => i.id.uuid === data.relationships.deliveryToProvider.data.id.uuid)
      : null,
    relatedTransactions: [
      ...data.relationships.relatedTransactions.data.map(tr => ({
        ...tr,
        type: 'related-transaction',
        attributes: {
          ...includedDate.find(i => i.id.uuid === tr.id.uuid).attributes
        },
        listings: [
          ...includedDate.find(i => i.id.uuid === tr.id.uuid).relationships.listings.data.map(l => {
            const listing = includedDate.find(el => el.id.uuid === l.id.uuid);
            return {
              attributes: listing.attributes,
              id: listing.id,
              type: listing.type,
              images: [...listing.relationships.images.data.map(img => {
                const image = includedDate.find(el => el.id.uuid === img.id.uuid);
                return {
                  attributes: image.attributes,
                  type: image.type,
                  id: image.id,
                }
              })]
            }
          })
        ]
      }))
    ],
    type: data.type,
  };

  const originTransaction = formattedResponse.relatedTransactions.find(
    item => !item.attributes.serviceFee
      && item.attributes.state !== 'declined'
      && moment(item.attributes.start).isSame(moment(formattedResponse.attributes.start))
      && moment(item.attributes.end).isSame(moment(formattedResponse.attributes.end))
  );

  const bookingDatesOfServiceFee = originTransaction && {
    bookingStart: originTransaction.attributes.start,
    bookingEnd: originTransaction.attributes.end,
  };

  const { lineItems: relatedLineItems, transitions: relatedTransitions } = originTransaction?.attributes;

  const listings = relatedLineItems?.length && relatedTransitions.length
    && setOrderedProducts(relatedLineItems, relatedTransitions);

  const currentListings = originTransaction.listings.filter(item => {
    const actualListing = listings.find(el => el.listingId === item.id.uuid);
    return actualListing.seats > 0;
  })

  const listingsOfServiceFee = originTransaction ? currentListings.map(listing => ({
    attributes: { amount: originTransaction.attributes.lineItems.findLast(
        item => item?.listingId === listing.id.uuid
      )?.seats },
    id: { uuid: listing.id.uuid},
    listing
  })) : [];

  const transactionIsPaid = formattedResponse.attributes.state !== 'pending';

  return {
    formattedResponse,
    originTransaction,
    bookingDatesOfServiceFee,
    listingsOfServiceFee,
    transactionIsPaid
  }
}

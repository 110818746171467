import React from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { string } from 'prop-types';

import { intlShape } from '../../util/reactIntl';

import css from './RelatedTransactionsSection.css';

const ListingsTransaction = ({intl, start, end, statusClass, statusText, createdAt}) => {
  return (
    <>
      <span className={css.rentalDates}>
        {`${moment(start).format("DD MMM")} - ${moment(end).subtract(1,'days').format("DD MMM")}`}
      </span>
      <span className={classNames(statusClass, css.status)}>
        {`${intl.formatMessage({ id: statusText })} `}
      </span>
      <span className={css.createdDate}>
        {moment(createdAt).format("DD MMM YYYY HH:mm")}
      </span>
    </>
  )
}

ListingsTransaction.propTypes = {
  intl: intlShape.isRequired,
  start: string.isRequired,
  end: string.isRequired,
  statusClass: string.isRequired,
  statusText: string.isRequired,
  createdAt: string.isRequired,
}

export default ListingsTransaction;

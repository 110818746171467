import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { formatMoney } from '../../util/currency';
import { LINE_ITEM_PROVIDER_COMMISSION } from '../../util/types';

import css from './OrderItemInfo.css';

const ProviderCommission = ({ isAdminOrProvider, isProvider, intl, lineItems, isPaymentExpired, payinTotal }) => {
  const commission = lineItems.filter(item => item?.code === LINE_ITEM_PROVIDER_COMMISSION);

  const commissionSum = commission.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.lineTotal?.amount,
    0
  );

  const zeroLineTotal = {
    amount: 0,
    currency: payinTotal.currency,
  }

  const totalCost = isPaymentExpired ? zeroLineTotal : payinTotal;

  const commissionLineTotal = isPaymentExpired ? zeroLineTotal : {
    amount: commission.length ? commissionSum : 0,
    currency: totalCost.currency,
  };

  if (!commissionLineTotal && isAdminOrProvider && isProvider) return null;

  const profit = isPaymentExpired ? zeroLineTotal : {
    amount: commissionLineTotal.amount + totalCost.amount,
    currency: totalCost.currency,
  };

  return (isProvider ?
    <>
      <p className={classNames(css.text, css.priceContainer)}>
        <FormattedMessage id="OrderInfo.commission" />{` ${formatMoney(intl, commissionLineTotal)}`}
      </p>
      <p className={classNames(css.text, css.priceContainer)}>
        <FormattedMessage id="OrderInfo.profit" /> {formatMoney(intl, profit)}
      </p>
    </> : null
  )
}

export default ProviderCommission;

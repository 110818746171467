import React from "react";
import classNames from "classnames";
import { oneOf } from "prop-types";

import { txIsRequested } from "../../util/transaction";
import { FormattedMessage, intlShape } from "../../util/reactIntl";
import { propTypes } from "../../util/types";
import { LogoMedicoverSport, NamedLink, UserDisplayName } from '../../components';
import BookingInfoMaybe from "./BookingInfoMaybe";

import css from "./InboxPage.css";

const formatDate = (intl, date) => {
  return {
    short: intl.formatDate(date, {
      month: "short",
      day: "numeric",
    }),
    long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
  };
};

const InboxItem = props => {
  const { unitType, type, tx, intl, stateData, lineItems } = props;
  const { user, provider } = tx;
  const isOrder = type === "order";
  const otherUser = isOrder ? provider : user;
  const customerName = !isOrder ? `${tx.attributes.firstName} ${tx.attributes.lastName}` : undefined;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} nameToDisplay={customerName} />;
  const isOtherUserBanned = otherUser.attributes.banned;
  const isSaleNotification = !isOrder && txIsRequested(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const dateOfLastTransitionedAt = tx.attributes.lastTransitionedAt || tx.attributes.createdAt;
  const lastTransitionedAt = formatDate(intl, dateOfLastTransitionedAt);

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  return (
    <div className={classNames(css.item)}>
      <NamedLink
        className={linkClasses}
        name={isOrder ? "OrderDetailsPage" : "SaleDetailsPage"}
        params={{ id: tx.id.uuid }}
      >
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          <div className={classNames(css.itemUsername, stateData.nameClassName)}>
            {otherUserDisplayName} {!isOrder && tx.attributes.notRespondedMessage
            && <span className={css.waitingMessage}>{intl.formatMessage({ id: "InboxPage.notResponded" })}</span>}
            {tx.attributes.prolongationChain?.hasTransactionBefore
              && <span className={css.prolongedLineItem}>{intl.formatMessage({ id: "InboxPage.prolonged" })}</span>
            }
            {
              tx.attributes.partnership && <div className={css.medicoverContainer}>
                <LogoMedicoverSport rootClassName={css.medicover}/>
              </div>
            }
          </div>
          <BookingInfoMaybe
            bookingClassName={stateData.bookingClassName}
            intl={intl}
            isOrder={isOrder}
            tx={tx}
            unitType={unitType}
            lineItems={lineItems}
          />
          {
            isOrder && tx.attributes.processState === "pending-payment" &&
            <NamedLink name="PaymentPage" params={{ transactionId: tx.id.uuid }} className={css.paymentLink}>
              <FormattedMessage id="InboxPage.pay" />
            </NamedLink>
          }
        </div>
        <div className={css.itemState}>
          <div className={classNames(css.stateName, stateData.stateClassName)}>
            {stateData.state}
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.long}
          >
            {lastTransitionedAt.short}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(["order", "sale"]).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default InboxItem;

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, node, number, object, oneOf, shape, string } from 'prop-types';

import { formatMoney } from '../../../../util/currency';
import { intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import Products from './Products';
import LongTermDiscount from './LongTermDiscount';
import PromoDiscount from './PromoDiscount';

import css from '../../OrderItemInfo.css';

const ListingsItem = ({
    intl,
    idMessage,
    children,
    key,
    products,
    listings,
    longTermDiscount,
    promoDiscount,
    money
}) => {

  return (
    <div key={key}>
      {children}
      <Products
        products={products}
        listings={listings}
        intl={intl}
      />
      <LongTermDiscount discount={longTermDiscount} intl={intl} />
      <PromoDiscount discount={promoDiscount} intl={intl} />
      <p className={classNames(css.subtitle, css.priceContainer, css.borderBottom)}>
        <FormattedMessage id={idMessage} /> {formatMoney(intl, money)}
      </p>
    </div>
  );
}

ListingsItem.defaultProps = {
  children: null,
};

ListingsItem.propTypes = {
  intl: intlShape.isRequired,
  idMessage: string.isRequired,
  money: object,
  children: node,
  products: arrayOf(
    shape({
      code: oneOf(['day', 'service-fee', 'long-term-discount', 'promo-discount', 'provider-commission']),
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
    })
  ),
  listings: arrayOf(shape({
    id: shape({ uuid: string.isRequired}),
    attributes: shape({
      title: string.isRequired,
    })
  })),
  longTermDiscount: shape({
    code: propTypes.value("long-term-discount").isRequired,
    includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
    quantity: number,
    unitPrice: shape({
      amount: number,
      currency: string,
    }),
    lineTotal: shape({
      amount: number,
      currency: string,
    }),
    reversal: bool,
    seats: number,
  }),
  promoDiscount: shape({
    code: propTypes.value("promo-discount").isRequired,
    includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
    quantity: number,
    unitPrice: shape({
      amount: number,
      currency: string,
    }),
    lineTotal: shape({
      amount: number,
      currency: string,
    }),
    reversal: bool,
    seats: number,
  })
};

export default ListingsItem;

import React from 'react';
import classNames from 'classnames';
import { bool, string } from 'prop-types';

import { propTypes } from '../../util/types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { stringify } from '../../util/urlHelpers';
import { NamedLink } from '../index';

import css from './ServiceFeeSummary.css';

const ServiceFeeSummary = ({
  intl,
  rootClassName,
  className,
  transaction,
  isPayment,
  title,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const { payinTotal, code } = transaction.attributes;

  return (
    <div className={classes}>
      <div className={css.orderContent}>
        {title &&
          <p className={css.title}>
            <FormattedMessage id={title} />
            <span className={css.code}>{` ${code}`}</span>
          </p>
        }
        <p className={css.summaryTitle}>
          <FormattedMessage id="ServiceFeeSummary.summaryTitle" />
        </p>
        <div className={css.itemSection}>
          <div className={css.itemContainer}>
            <p className={css.itemText}>
              <FormattedMessage id="ServiceFeeSummary.content" />
            </p>
            <p className={css.itemText}>{formatMoney(intl, payinTotal)}</p>
          </div>
        </div>
        <div className={css.togetherSection}>
          <p className={css.togetherText}>
            <FormattedMessage id="ServiceFeeSummary.together" />
          </p>
          <p className={css.togetherText}>{formatMoney(intl, payinTotal)}</p>
        </div>
        { isPayment ?
          <NamedLink
            name="CheckoutPageServiceFee"
            params={{ feeId: transaction.id.uuid}}
            className={css.link}
          >
            {intl.formatMessage({ id: "ServiceFeeSummary.pay" })}
          </NamedLink>
        : null}
      </div>
    </div>
  );
};

ServiceFeeSummary.defaultProps = {
  rootClassName: null,
  className: null,
  isPayment: false,
  title: null,
};

ServiceFeeSummary.propTypes = {
  rootClassName: string,
  className: string,
  isPayment: bool,
  intl: intlShape.isRequired,
  transaction: propTypes.transaction.isRequired,
  title: string,
};

export default ServiceFeeSummary;

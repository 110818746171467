import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { string, node, bool } from "prop-types";
import isNull from "lodash/isNull";

import {
  Page,
  TabNav,
  LayoutSideNavigation,
  Footer,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
} from "..";
import { NotFoundPage, TopbarContainer } from "../../containers";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { FormattedMessage, injectIntl, intlShape } from "../../util/reactIntl";
import { ensureCurrentUser } from "../../util/data";
import { propTypes } from "../../util/types";

import css from "./InboxWrapper.css";

const InboxWrapperComponent = ({
   children,
   intl,
   scrollingDisabled,
   name,
   tab,
   currentUserProviders,
   providerNotificationBadge,
   currentUser
}) => {
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isOfferEnabled = ensuredCurrentUser.attributes.hasProvider
    || ensuredCurrentUser.attributes.isAdmin
    || ensuredCurrentUser.attributes.adminForCountries?.includes(process.env.REACT_APP_COUNTRY_ID);

  const tabData = [
    {
      tabName: "orders",
      pageName: "InboxPage",
      pageTitle: "InboxPage.ordersTabTitle",
      tabTitle: "InboxPage.ordersTabTitle",
      providerId: "orders",
      countryLimit: null,
    },
    {
      tabName: "sales",
      pageName: "InboxPage",
      pageTitle: "InboxPage.salesTabTitle",
      tabTitle: "InboxPage.salesTabTitle",
      providerId: (currentUserProviders && !!currentUserProviders.length && currentUserProviders[0].id.uuid) || "orders",
      countryLimit: null,
    },
    {
      tabName: "orders",
      pageName: "ServiceFeeInboxPage",
      pageTitle: "ServiceFeeInboxPage.ordersTabTitle",
      tabTitle: "ServiceFeeInboxPage.ordersTabTitle",
      providerId: "orders",
      countryLimit: ["pl"],
    },
    {
      tabName: "sales",
      pageName: "ServiceFeeInboxPage",
      pageTitle: "ServiceFeeInboxPage.salesTabTitle",
      tabTitle: "ServiceFeeInboxPage.salesTabTitle",
      providerId: (currentUserProviders && !!currentUserProviders.length && currentUserProviders[0].id.uuid) || "orders",
      countryLimit: ["pl"],
    },
  ];

  if (!tabData.some(({ tabName, pageName}) => tabName === tab && pageName === name)) return <NotFoundPage />;
  const rowData = tabData.find(({ tabName, pageName }) => tabName === tab && pageName === name);
  const { pageTitle } = rowData;
  const title = intl.formatMessage({ id: pageTitle });
  const selectedForCountriesTabs = tabData.filter(({countryLimit}) => isNull(countryLimit)
    || countryLimit.includes(process.env.REACT_APP_COUNTRY_ID));
  const enabledTabs = selectedForCountriesTabs.filter(({tabName}) => tabName === "orders"
    || (isOfferEnabled && tabName === "sales"))


  const tabs = enabledTabs
    .map(({ tabName, tabTitle, pageName, providerId }) => ({
      text: (
        <span>
          <FormattedMessage id={tabTitle} />
          {providerNotificationBadge}
        </span>
      ),
      selected: tabName === tab  && pageName === name,
      linkProps: {
        name: pageName,
        params: { tab: tabName, providerId },
      }
    }));

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage={name} />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          <h1 className={css.title}>
            <FormattedMessage id="Console.title" />
          </h1>
          <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {children}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
      <ScrollToTop />
    </Page>
  );
};

InboxWrapperComponent.defaultProps = {
  tab: null,
  name: null,
  children: null,
  intl: null,
  scrollingDisabled: false,
  providerNotificationBadge: null,
};

InboxWrapperComponent.propTypes = {
  tab: string.isRequired,
  name: string.isRequired,
  intl: intlShape.isRequired,
  children: node.isRequired,
  scrollingDisabled: bool.isRequired,
  providerNotificationBadge: node,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};

const InboxWrapper = compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
)(InboxWrapperComponent);

export default InboxWrapper;

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, object, shape, string } from 'prop-types';

import { formatMoney } from '../../util/currency';
import { txIsPaymentExpired, txIsPaymentPending } from '../../util/transaction';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { stringify } from '../../util/urlHelpers';
import PickupAddress from './PickupAddress';
import InvoiceDetails from './InvoiceDetails';
import AddressDeliveryHome from './AddressDeliveryHome';
import InvoiceLink from './InvoiceLink';
import ItemSummary from './ItemSummary/ItemSummary';
import ProviderCommission from './ProviderCommission';

import css from './OrderItemInfo.css';
import { NamedLink } from '../index';

const PAYMENT_EXPIRED = "payment-expired";

const OrderItemInfo = ({
  transaction,
  deliveryToProvider,
  provider,
  listings,
  intl,
  isAdminOrProvider,
  isProvider,
  isAdmin
}) => {

  const {
    receiverName,
    firstName,
    lastName,
    phone,
    email,
    deliveryType,
    deliveryToHome,
    payinTotal,
    lineItems,
    documents,
    transitions,
    meta: { invoice, customerType },
    processState,
    serviceFee,
    state
  } = transaction.attributes;
  const isFeeUnpaidAndCanceled = serviceFee && state === "declined";
  const isPaymentExpired = processState === PAYMENT_EXPIRED;
  const isInvoiceDetails = serviceFee
    ? (!txIsPaymentPending(transaction) && !txIsPaymentExpired(transaction)
      && !transaction.attributes.transitions.includes(item => item.transition === "confirm-payment"))
    : true;
  const isListingTransaction = !serviceFee;

  const zeroLineTotal = {
    amount: 0,
    currency: payinTotal.currency,
  }

  const totalCost = isPaymentExpired ? zeroLineTotal : payinTotal;

  const summaryMessage= isInvoiceDetails
      ? "OrderInfo.paymentSummary"
      : "OrderInfo.summary";

  return (
    <div className={css.container}>
      <div className={classNames(css.section, css.sectionContactDetails)}>
        <p className={css.title}>
          <FormattedMessage id="OrderInfo.contactDetails" />
        </p>
        { isAdmin
          ? <NamedLink
            className={css.text}
            name="ConsoleUserPage"
            params={{ tab: 'user' }}
            to={{ search: stringify({ phrase: email, page: 1 }) }}
          >
            {firstName} {lastName}
          </NamedLink>
          : <p className={css.subtitle}>
            {firstName} {lastName}
          </p>
        }
        <p className={css.text}>{email}</p>
        <p className={css.text}>{phone}</p>
        {isListingTransaction &&
          <>
              <p className={classNames(css.subtitle, css.subtitleContainer)}>
                <FormattedMessage id="OrderInfo.receiver" />
              </p>
              <p className={css.text}>{receiverName}</p>
          </>
        }
        <InvoiceDetails
          invoice={invoice}
          customerType={customerType}
          isInvoiceDetails={isInvoiceDetails}
        />
      </div>
      <AddressDeliveryHome
        deliveryToHome={deliveryToHome}
        isListingTransaction={isListingTransaction}
      />
      <PickupAddress
        deliveryType={deliveryType}
        deliveryToProvider={deliveryToProvider}
        provider={provider}
        isListingTransaction={isListingTransaction}
      />
      {!isFeeUnpaidAndCanceled &&
        <div className={classNames(css.section, css.sectionPayment)}>
          <div className={classNames(css.titleContainer, css.title)}>
            <FormattedMessage id={summaryMessage} />
          </div>
          <ItemSummary
            listings={listings}
            transitions={transitions}
            intl={intl}
            lineItems={lineItems}
          />
          <p className={classNames(css.subtitle, css.priceContainer)}>
            <FormattedMessage id="OrderInfo.totalCost" /> {formatMoney(intl, totalCost)}
          </p>
          <ProviderCommission
            isAdminOrProvider={isAdminOrProvider}
            isProvider={isProvider}
            intl={intl}
            lineItems={lineItems}
            isPaymentExpired={isPaymentExpired}
            payinTotal={payinTotal}
          />
          <InvoiceLink
            documents={documents}
            invoiceClassName={classNames(css.text, css.invoice)}
          />
        </div>
      }
    </div>
  );
};

OrderItemInfo.propTypes = {
  transaction: propTypes.transaction.isRequired,
  deliveryToProvider: object,
  provider: propTypes.providerDetails.isRequired,
  listings: arrayOf(shape({
    id: shape({ uuid: string.isRequired}),
    attributes: shape({
      title: string.isRequired,
    })
  })),
  intl: intlShape.isRequired,
  isAdminOrProvider: bool.isRequired,
  isProvider: bool.isRequired
}

export default OrderItemInfo;

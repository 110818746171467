import React, { Component } from 'react';
import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import { propTypes } from '../../util/types';
import { ensurePaymentMethodCard } from '../../util/data';

import {
  Form,
  PrimaryButton,
  IconSpinner
} from '../../components';

import css from './StripePaymentForm.css';


/**
 * Translate a Stripe API error object.
 *
 * To keep up with possible keys from the Stripe API, see:
 *
 * https://stripe.com/docs/api#errors
 *
 * Note that at least at moment, the above link doesn't list all the
 * error codes that the API returns.
 *
 * @param {Object} intl - react-intl object from injectIntl
 * @param {Object} stripeError - error object from Stripe API
 *
 * @return {String} translation message for the specific Stripe error,
 * or the given error message (not translated) if the specific error
 * type/code is not defined in the translations
 *
 */
const stripeErrorTranslation = (intl, stripeError) => {
  const { message, code, type } = stripeError;

  if (!code || !type) {
    // Not a proper Stripe error object
    return intl.formatMessage({ id: 'StripePaymentForm.genericError' });
  }

  const translationId =
    type === 'validation_error'
      ? `StripePaymentForm.stripe.validation_error.${code}`
      : `StripePaymentForm.stripe.${type}`;

  return intl.formatMessage({
    id: translationId,
    defaultMessage: message,
  });
};

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
      ? 'onetimeCardPayment'
      : selectedPaymentMethod;
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
  paymentMethodType: null
};

/**
 * Payment form that asks for credit card info using Stripe Elements.
 *
 * When the card is valid and the user submits the form, a request is
 * sent to the Stripe API to handle payment. `stripe.handleCardPayment`
 * may ask more details from cardholder if 3D security steps are needed.
 *
 * See: https://stripe.com/docs/payments/payment-intents
 *      https://stripe.com/docs/elements
 */
class StripePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleCardValueChange = this.handleCardValueChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.initializeStripeElement = this.initializeStripeElement.bind(this);
    this.handleStripeElementRef = this.handleStripeElementRef.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
    this.p24Container = null;
    this.deliveryOption = null;
    this.elements = null;
  }

  componentDidMount() {
    if (!window.Stripe) {
      throw new Error('Stripe must be loaded for StripePaymentForm');
    }

    if (config.stripe.publishableKey) {
      const {
        onStripeInitialized,
      } = this.props;
      this.stripe = window.Stripe(config.stripe.publishableKey);
      onStripeInitialized(this.stripe);

      this.initializeStripeElement();
    }
  }

  componentWillUnmount() {
    if (this.card) {
      this.card.removeEventListener('change', this.handleCardValueChange);
      this.card.unmount();
      this.card = null;
    }
  }

  initializeStripeElement(element) {
    const { currentTransaction } = this.props;
    const options = {
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: false
      },
      loader: 'always',
      defaultValues: {
        billingDetails: {
          name: currentTransaction.attributes.firstName + ' ' + currentTransaction.attributes.lastName,
          email: currentTransaction.attributes.email
        }
      }
    };
    const appearance = {};
    const clientSecret = this.props.currentTransaction.attributes.stripePaymentIntent.clientSecret;

    const elements = this.stripe.elements({ appearance, clientSecret, loader: 'always' });
    this.elements = elements;

    if (!this.card && (element || this.cardContainer)) {
      this.card = elements.create('payment', options);
      this.card.mount(element || this.cardContainer);
      this.card.addEventListener('change', this.handleCardValueChange);
    }
  }

  handleStripeElementRef(el) {
    this.cardContainer = el;
    if (this.stripe && el) {
      this.initializeStripeElement(el);
    }
  }

  handleCardValueChange(event) {
    const { intl } = this.props;
    const { error, complete } = event;

    this.setState(prevState => {
      return {
        error: error ? stripeErrorTranslation(intl, error) : null,
        cardValueValid: complete,
        paymentMethodType: event.value.type
      };
    });
  }

  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      hasHandledCardPayment,
      defaultPaymentMethod,
    } = this.props;
    const { initialMessage } = values;
    const { cardValueValid, paymentMethod } = this.state;
    const billingDetailsKnown = hasHandledCardPayment || defaultPaymentMethod;
    const onetimePaymentNeedsAttention = !billingDetailsKnown && !cardValueValid && this.state.paymentMethodType === 'card';

    if (inProgress || onetimePaymentNeedsAttention) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      message: initialMessage ? initialMessage.trim() : null,
      card: this.card,
      elements: this.elements,
      formId,
      formValues: { ...values, delivery: this.state.deliveryOption },
      paymentMethod: this.state.paymentMethodType === 'p24' ? 'p24' : getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      ),
      paymentMethodType: this.state.paymentMethodType
    };

    onSubmit(params);
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      loadingData,
      intl,
      initiateOrderError,
      handleCardPaymentError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      form,
      hasHandledCardPayment,
      messageOfSubmitBtn
    } = formRenderProps;

    this.finalFormAPI = form;

    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const hasPaymentErrors = handleCardPaymentError || confirmPaymentError;
    const classes = classNames(rootClassName || css.root, className);

    // TODO: handleCardPayment can create all kinds of errors.
    // Currently, we provide translation support for one:
    // https://stripe.com/docs/error-codes
    const piAuthenticationFailure = 'payment_intent_authentication_failure';
    const paymentErrorMessage =
      handleCardPaymentError && handleCardPaymentError.code === piAuthenticationFailure
        ? intl.formatMessage({ id: 'StripePaymentForm.handleCardPaymentError' })
        : handleCardPaymentError
          ? handleCardPaymentError.message
          : confirmPaymentError
            ? intl.formatMessage({ id: 'StripePaymentForm.confirmPaymentError' })
            : intl.formatMessage({ id: 'StripePaymentForm.genericError' });

    const hasStripeKey = config.stripe.publishableKey;

    const confirmButtonDisabled = invalid || submitInProgress;

    return hasStripeKey ? (
      <Form className={classes} onSubmit={handleSubmit} id="checkoutForm">
        <div className={css.paymentSection}>
          <h3>
            <FormattedMessage id="StripePaymentForm.paymentHeading" />
          </h3>
          <div ref={this.handleStripeElementRef} />
        </div>

        <div className={css.submitContainer}>
          {hasPaymentErrors ? (
            <span className={css.errorMessage}>{paymentErrorMessage}</span>
          ) : null}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={confirmButtonDisabled}
          >
            <FormattedMessage id={messageOfSubmitBtn} />
          </PrimaryButton>
          <p className={css.legalInfo}>
            <FormattedMessage id="StripePaymentForm.ppLink" />
            <a
              href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/privacy-policy-${config.locale}.pdf`}
              className={css.legalLink}
              target="_blank"
            >
              <FormattedMessage id="StripePaymentForm.privacyPolicy" />
            </a>
          </p>
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="StripePaymentForm.missingStripeKey" />
      </div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} />;
  }
}

StripePaymentForm.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  handleCardPaymentError: null,
  confirmPaymentError: null,
};

StripePaymentForm.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  handleCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
};

export default injectIntl(StripePaymentForm);

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';

// ================ Action types ================ //

export const FETCH_CONSOLE_SERVICE_FEE_REQUEST =
  'app/ConsoleServiceFeePage/FETCH_CONSOLE_SERVICE_FEE_REQUEST';
export const FETCH_CONSOLE_SERVICE_FEE_SUCCESS =
  'app/ConsoleServiceFeePage/FETCH_CONSOLE_SERVICE_FEE_SUCCESS';
export const FETCH_CONSOLE_SERVICE_FEE_ERROR =
  'app/ConsoleServiceFeePage/FETCH_CONSOLE_SERVICE_FEE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchServiceFeeError: null,
  pagination: null,
  serviceFee: [],
};

export default function consoleServiceFeePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_SERVICE_FEE_REQUEST:
      return { ...state, fetchInProgress: true, fetchServiceFeeError: null };
    case FETCH_CONSOLE_SERVICE_FEE_SUCCESS: {
      return {
        fetchInProgress: false,
        serviceFee: payload.data.data,
        pagination: payload.data.meta,
        fetchServiceFeeError: null
      };
    }
    case FETCH_CONSOLE_SERVICE_FEE_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        pagination: null,
        serviceFee: [],
        fetchInProgress: false,
        fetchServiceFeeError: payload
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchServiceFeeRequest = () => ({ type: FETCH_CONSOLE_SERVICE_FEE_REQUEST });

const fetchServiceFeeSuccess = response => ({
  type: FETCH_CONSOLE_SERVICE_FEE_SUCCESS,
  payload: response,
});

const fetchServiceFeeError = e => ({
  type: FETCH_CONSOLE_SERVICE_FEE_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 100;

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { page = 1, userId, processState, countryId = config.custom.countryId, phrase, sort, businessCategory } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const queryParams = {
    serviceFee: true,
    ...paginationParams,
    include: ['provider', 'user'],
    userId,
    processState,
    countryId,
    phrase,
    sort,
  };

  dispatch(fetchServiceFeeRequest());

  return await sdk.newSdk.transactions
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchServiceFeeSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchServiceFeeError(e));
      throw e;
    });
};

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import PropTypes, { func } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { typesOfServiceFee } from '../../../util/transaction';
import {
  Modal,
  Select,
  Button,
  InlineTextButton,
  Input,
  Textarea,
  IconWarning,
  IconTick,
} from '../../index';

import css from './ServiceFeeModal.css';

const FIELDS = {
  CODE: {
    name: 'code',
    initValue: 'all',
    label: "ServiceFeeModal.codeLabel",
    placeholder: '',
    error: "ServiceFeeModal.codeError" },
  REASON: {
    name: 'reason',
    initValue: '',
    label: "ServiceFeeModal.reasonLabel",
    placeholder: "ServiceFeeModal.reasonPlaceholder",
    error: "ServiceFeeModal.reasonError"
  },
  AMOUNT: {
    name: 'amount',
    initValue: 0,
    label: "ServiceFeeModal.amountLabel",
    placeholder: "ServiceFeeModal.amountPlaceholder",
    error: "ServiceFeeModal.amountError"
  }
}

const initValues = {
  [FIELDS.CODE.name]: FIELDS.CODE.initValue,
  [FIELDS.AMOUNT.name]: FIELDS.AMOUNT.initValue,
  [FIELDS.REASON.name]: FIELDS.REASON.initValue,
}

const ServiceFeeModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    transaction,
    createServiceFeeInProgress,
    createServiceFeeError,
    createServiceFeeSuccess,
    onCreateServiceFee,
    onClearServiceFee,
    transactionRole
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm({ defaultValues: initValues });

  const onSubmit = (data) => {
    const serviceFeeFor = transaction.id;
    const amount = data[FIELDS.AMOUNT.name] * 100;
    const reason = data[FIELDS.REASON.name] || '';
    onCreateServiceFee(serviceFeeFor, data[FIELDS.CODE.name], amount, reason, transactionRole);
  }

  const handleClose = () => {
    reset({ defaultValues: initValues });
    onClearServiceFee();
    onCloseModal();
  }

  useEffect(() => {
    createServiceFeeSuccess && reset({ defaultValues: initValues });
  }, [createServiceFeeSuccess]);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={handleClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={' '}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="TransactionPanel.serviceFee" />
      </p>

      {createServiceFeeSuccess ? (
        <p className={css.success}>
          <IconTick className={css.iconTick} />
          <FormattedMessage id="ServiceFeeModal.success" />
        </p>
      )
      : <form onSubmit={handleSubmit(onSubmit)} className={css.mainForm}>
        {createServiceFeeError ? (
          <p className={css.error}>
            <IconWarning variant="error" /> <FormattedMessage id="ServiceFeeModal.error" />
          </p>
        ): null}
        <Controller
          control={control}
          name={FIELDS.CODE.name}
          key={FIELDS.CODE.name}
          rules={{
            validate: value => value !== "all",
          }}
          render={({ field: { onChange, value, name } }) => {
            const upperCaseName = name.toUpperCase();
             return  (
               <Select
                value={value}
                onChange={onChange}
                label={intl.formatMessage({ id: FIELDS[upperCaseName].label })}
                error={errors[name] && intl.formatMessage({ id: FIELDS[upperCaseName].error })}
                id={name}
                markRequired={true}
              >
                {typesOfServiceFee.map(({ value, label }) => (
                  <option key={value} value={value}>{intl.formatMessage({ id: label })}</option>
                ))}
              </Select>
             )
          }}
        />
        <Controller
          control={control}
          name={FIELDS.AMOUNT.name}
          key={FIELDS.AMOUNT.name}
          rules={{
            validate: value => value !== 0 && value !== '',
          }}
          render={({ field: { onChange, value, name } }) => {
            const upperCaseName = name.toUpperCase();
            return (
              <Input
                label={intl.formatMessage({ id: FIELDS[upperCaseName].label })}
                placeholder={intl.formatMessage({ id: FIELDS[upperCaseName].placeholder })}
                id={name}
                value={value}
                onChange={onChange}
                onWheel={(e) => e.target.blur()}
                type="number"
                error={errors[name] && intl.formatMessage({ id: FIELDS[upperCaseName].error })}
                markRequired={true}
              />
            )
          }}
        />
        <Controller
          control={control}
          name={FIELDS.REASON.name}
          key={FIELDS.REASON.name}
          rules={{
            validate: value => {
              const valueWithoutWhitespace = value.trim();
              return valueWithoutWhitespace !== '' && valueWithoutWhitespace.length > 2
            },
          }}
          render={({ field: { onChange, value, name } }) => {
            const upperCaseName = name.toUpperCase();

            return (
              <Textarea
                label={intl.formatMessage({ id: FIELDS[upperCaseName].label })}
                placeholder={intl.formatMessage({ id: FIELDS[upperCaseName].placeholder })}
                id={name}
                value={value}
                onChange={onChange}
                onWheel={(e) => e.target.blur()}
                markRequired={true}
                error={errors[name] && intl.formatMessage({ id: FIELDS[upperCaseName].error })}
              />
            )
          }}
        />
        <InlineTextButton className={css.cancelButton} onClick={handleClose}>
          <FormattedMessage id="PartialCancelModal.cancel" />
        </InlineTextButton>
        <Button
          onClick={handleSubmit}
          inProgress={createServiceFeeInProgress}
          type="submit"
        >
          {intl.formatMessage({ id: "ServiceFeeModal.submit" })}
        </Button>
      </form>}
    </Modal>
  );
};

const {
  bool, string,
} = PropTypes;

ServiceFeeModal.defaultProps = {
  className: null,
  rootClassName: null,
};

ServiceFeeModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  isOpen: bool,
  onCloseModal: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  transaction: propTypes.transaction,
  createServiceFeeInProgress: bool,
  createServiceFeeError: propTypes.error,
  createServiceFeeSuccess: bool,
  onCreateServiceFee: func.isRequired,
  onClearServiceFee: func.isRequired,
};

export default withRouter(injectIntl(ServiceFeeModal));

import React from 'react';

import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';
import FieldAutocompleteSelect
  from '../../components/FieldAutocompleteSelect/FieldAutocompleteSelect';

const AutocompleteProductSelectField = props => {
  const { id, catalogProducts, intl, form, markRequired, disabled, selectedProduct, ...rest } = props;
  const catalogProductLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.catalogProductLabel',
  });
  const catalogProductPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.catalogProductPlaceholder',
  });
  const catalogProductRequired = intl.formatMessage({
      id: 'EditListingDescriptionForm.catalogProductRequired',
    })

  const productRequired = () => value => {
    if (value?.label === '' && value?.value === '') return catalogProductRequired;
    if (typeof value?.value === 'undefined' || value?.value === null) return catalogProductRequired;

    return undefined;
  };
  const validate = productRequired();

  const handelChange = (event) => {
    form.change('catalogProduct', event.target.value);
    form.change('catalogItem', '');
  }

  return catalogProducts ? (
    <FieldAutocompleteSelect
      className={css.category}
      id={id}
      label={catalogProductLabel}
      markRequired={markRequired}
      disabled={disabled}
      inputValue={selectedProduct}
      placeholder={catalogProductPlaceholder}
      options={catalogProducts}
      validate={validate}
      requiredProvider={catalogProductRequired}
      onChange={handelChange}
      {...rest}
    />
  ) : null;
};

export default AutocompleteProductSelectField;

import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';

import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { loadData } from './ConsoleServiceFeePage.duck';
import { PaginationLinks, ConsoleWrapper, IconSpinner, IconClose, Select, Input } from '../../components';
import ServiceFeeItem from './ServiceFeeItem/ServiceFeeItem';
import { parse, stringify } from '../../util/urlHelpers';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import config from '../../config';
import { countryId as defaultCountryId } from '../../marketplace-custom-config';

import css from './ConsoleServiceFeePage.css';

const transactionStatus = [
  { processStatus: 'all', textStatus: 'Console.all' },
  { processStatus: 'accepted', textStatus: 'ConsoleServiceFeePage.stateAccepted', class: css.accepted },
  { processStatus: 'pending-payment', textStatus: 'ConsoleServiceFeePage.statePending', class: css.pending },
  { processStatus: 'cancelled', textStatus: 'ConsoleServiceFeePage.stateCanceled', class: css.cancelled },
  { processStatus: 'payment-expired', textStatus: 'ConsoleServiceFeePage.stateExpired', class: css.expired },
];

const transactionCountry = [
  { countryId: 'all', country: 'Console.all' },
  ...config.countryNames
];

const ConsoleServiceFeePageComponent = props => {
  const {
    transactions,
    fetchInProgress,
    scrollingDisabled,
    fetchServiceFeeError,
    pagination,
    intl,
    location: { search, pathname },
    history,
    name,
    tab,
    currentUser,
    onManageDisableScrolling,
  } = props;
  const { countryId = config.custom.countryId, processState, nonDeliveredOnTime, page, phrase, sort = '-createdAt', businessCategory } = parse(search);
  const [searchPhrase, setSearchPhrase] = useState(phrase);
  const isAdmin = currentUser && currentUser.attributes.isAdmin;
  const adminCountries =  currentUser && currentUser.attributes.adminForCountries;
  const defaultAdminCountryId = (isAdmin || (adminCountries && adminCountries.includes(defaultCountryId)))
    ? defaultCountryId
    : adminCountries ? adminCountries[0] : null;

  const pagingLinks =
    !fetchInProgress && pagination && pagination?.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName={name}
        pagePathParams={{ tab }}
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const errorMessage = !fetchInProgress && fetchServiceFeeError ? (
    <p className={css.error}>{fetchServiceFeeError?.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress && !transactions?.length && !fetchServiceFeeError ? (
      <p>
        <FormattedMessage id="Console.noServiceFeeFound" />
      </p>
    ) : null;

  const handleCountryChange = event => {
    const searchCountryId = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), countryId: searchCountryId, page: 1 })}`,
    });
  };

  const handleStatusChange = event => {
    const searchProcessState = event.target.value === 'all' ? undefined : event.target.value;

    history.push({
      pathname,
      search: `${stringify({ ...parse(search), processState: searchProcessState, page: 1 })}`,
    });
  };

  const countries = currentUser?.attributes.isAdmin
    ? transactionCountry
    : transactionCountry.filter(country =>
        currentUser?.attributes.adminForCountries.includes(country.countryId)
      );

  const handleDelete = event => {
    event.preventDefault();
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), userId: null, page: 1 })}`,
    });
  };

  const handleChange = event => {
    setSearchPhrase(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push({
      pathname,
      search: `${stringify({ ...parse(search), phrase: searchPhrase, page: 1 })}`,
    });
  };

  const transactionSearch = (
    <form onSubmit={handleSubmit} className={css.searchInputWrapper}>
      <Input
        value={searchPhrase}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: "Console.searchTransaction" })}
        label={intl.formatMessage({id: "Console.search" })}
      />
    </form>
  );

  useEffect(() => {
    if (!page && currentUser) {
      setSearchPhrase('');
      history.push({ pathname, search: `${stringify({ ...parse(search), countryId: defaultAdminCountryId, page: 1 })}` });
    }
  }, [page, currentUser]);

  return (
    <ConsoleWrapper scrollingDisabled={scrollingDisabled} name={name} tab={tab}>
      {errorMessage}
      <div className={css.filterContainer}>
        <Select
          value={countryId}
          onChange={handleCountryChange}
          label={intl.formatMessage({id: "ConsoleTransactionPage.country" })}
          isValidation={false}
        >
          <option hidden value="">
            {intl.formatMessage({ id: 'Console.select' })}
          </option>
          {countries.map(({ countryId, country }) => (
            <option value={countryId}>{intl.formatMessage({ id: country })}</option>
          ))}
        </Select>
        <Select
          value={processState || 'all'}
          onChange={handleStatusChange}
          label={intl.formatMessage({id: "Console.transactionStatus" })}
          isValidation={false}
        >
          <option hidden value="">
            {intl.formatMessage({ id: "Console.select" })}
          </option>
          {transactionStatus.map(({ processStatus, textStatus }) => (
            <option value={processStatus}>{intl.formatMessage({ id: textStatus })}</option>
          ))}
        </Select>
      </div>
      {transactionSearch}
      {fetchInProgress ? (
        <div className={css.listItemsLoading}>
          <IconSpinner />
        </div>
      ) : (
        <>
          {parse(search)?.userId ? (
            <button onClick={handleDelete} className={css.closeButton}>
            <span className={css.textButton}>
              {!!transactions?.length ? (
                <>
                  {`${transactions[0]?.user?.attributes?.firstName} `}
                  {transactions[0]?.user?.attributes?.lastName}
                </>
              ) : (
                parse(search)?.userId
              )}
            </span>
              <IconClose />
            </button>
          ) : null}
          {!!transactions.length && <p className={css.totalItems}>
            {`${intl.formatMessage({ id: "Console.numberOfItems" })}: `}
            <span>{pagination.totalItems}</span>
          </p>}
          <ul className={css.list}>
            {transactions.map(transaction => {
              const {
                id: { uuid },
                attributes: { processState, createdAt, serviceFee },
                user: {
                  attributes: { firstName, lastName },
                },
                provider: {
                  attributes: { name: providerName, countryId },
                },
              } = transaction;

              const createdAtDate = intl.formatDate(createdAt, {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });

              const currentState = transactionStatus.find(
                state => state.processStatus === processState
              );

              return (
                <ServiceFeeItem
                  key={uuid}
                  id={uuid}
                  state={intl.formatMessage({ id: currentState.textStatus })}
                  classNameState={currentState.class}
                  createdAt={createdAtDate}
                  firstName={firstName}
                  lastName={lastName}
                  providerName={providerName}
                  providerCountryId={countryId}
                  serviceFee={serviceFee}
                />
              );
            })}
          </ul>
        </>
      )}
      {noResultsMessage}
      {pagingLinks}
    </ConsoleWrapper>
  );
};

ConsoleServiceFeePageComponent.defaultProps = {
  transactions: [],
  fetchInProgress: false,
  fetchServiceFeeError: null,
  pagination: null,
  scrollingDisabled: false,
  name: null,
  tab: null,
};

ConsoleServiceFeePageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchServiceFeeError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  transactions: arrayOf(propTypes.transactionDetails),
  intl: intlShape.isRequired,
  name: string.isRequired,
  tab: string.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchServiceFeeError,
    pagination,
    serviceFee,
  } = state.ConsoleServiceFeePage;
  const { currentUser } = state.user;
  return {
    fetchInProgress,
    fetchServiceFeeError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, serviceFee),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ConsoleServiceFeePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleServiceFeePageComponent);

ConsoleServiceFeePage.loadData = loadData;

export default ConsoleServiceFeePage;

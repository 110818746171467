import React from 'react';

import { formatMoney } from '../../../util/currency';
import { typesOfServiceFee } from '../../../util/transaction';
import { NamedLink } from '../../index';

import css from './ServiceFeeDetails.css';
import { propTypes } from '../../../util/types';
import { intlShape } from '../../../util/reactIntl';
import { bool } from 'prop-types';

const ServiceFeeDetails = ({intl, transaction, isProvider, isOrder}) => {
  const { serviceFee, lineItems, processState } = transaction.attributes;
  const serviceFeeItem = serviceFee && lineItems.find(
    item => item.code === 'service-fee' && !item.reversal
  );
  const isPaymentBtn = !isProvider && isOrder && processState === 'pending-payment' && serviceFee;

  const typeOfServiceFeeLabel = serviceFee
    && typesOfServiceFee.find(({value}) => value === serviceFee.code).label;

  return (
    <>
      <div className={css.serviceFeeContainer}>
        <p className={css.serviceFeeDetails}>
          {intl.formatMessage({ id:"OrderDetails.serviceFeeDetails" })}
        </p>
        <p className={css.text}>
          {intl.formatMessage({ id: typeOfServiceFeeLabel })}
          {serviceFee.reason ? `: ${serviceFee.reason}` : null}
        </p>
        <p className={css.text}>
          {`${intl.formatMessage({ id: "OrderDetails.amount" })}:
                ${serviceFeeItem ? formatMoney(intl, serviceFeeItem?.lineTotal) : null}`}
        </p>
      </div>
      {isPaymentBtn ?
        <NamedLink
          name="CheckoutPageServiceFee"
          params={{ feeId: transaction.id.uuid}}
          className={css.paymentLink}
        >
          {intl.formatMessage({ id:"OrderDetails.payServiceFee" })}
        </NamedLink>
      : null}
    </>
  )
}

ServiceFeeDetails.propsType = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  isProvider: bool.isRequired,
  isOrder: bool.isRequired,
}

export default ServiceFeeDetails;

import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT, propTypes } from '../../util/types';
import { Button, ListingLink, Modal } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import css from './EditListingDescriptionPanel.css';
import businessCategoryConfig from '../../business-category-config';

const PRODUCT_CREATION_METHOD = {
  CATALOG_PRODUCT: 'catalogProduct',
  NEW_PRODUCT: 'newProduct'
}

const EditListingDescriptionPanel = props => {
  const [initialValues, setInitialValues] = useState(null);
  const [productCreationMethod, setProductCreationMethod] = useState(null);

  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUserProviders,
    productCatalogList,
    onManageDisableScrolling,
    userPublicData
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);

  const {
    description, price, title, availabilityPlan, category, businessCategory, code, externalLink, customParts, parts, depositConditions, minBookingDays, serviceDaysAmount, customPrice
  } = currentListing.attributes;

  const businessCategoryItem = businessCategoryConfig.find(
    item => item.key === businessCategory
  );

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const hasItemAttached = !!currentListing.catalogItem?.id.uuid;

  const catalogItemId = currentListing?.catalogItem && currentListing.catalogItem.id.uuid || '';
  const productCatalog = currentListing?.catalogItem
  ? productCatalogList.find( product => product.catalogItems.find(item => item.id.uuid === catalogItemId))
  : null;

  const initialValuesToPass = initialValues || {
    title,
    description,
    price: productCatalog ? productCatalog.attributes.price : price,
    category: businessCategoryItem?.category,
    businessCategory,
    code,
    externalLink,
    parts: parts?.replace(customParts, ''),
    customParts,
    depositConditions,
    provider: currentListing.provider ? { value: currentListing.provider.id.uuid, label: currentListing.provider.attributes.name } : { value: '', label: '' },
    serviceDaysAmount,
    customPrice: hasItemAttached ? customPrice : null,
    isCustomPrice: !!(hasItemAttached ? customPrice : null)
  };

  const initialQuantity = availabilityPlan ? availabilityPlan.entries[0].seats : 1;
  const initialMinBookingDays = minBookingDays || 1;
  const isCatalogProduct = currentListing.id
    ? !!currentListing.catalogItem
    : productCreationMethod === PRODUCT_CREATION_METHOD.CATALOG_PRODUCT;
  // const catalogItemId = currentListing?.catalogItem && currentListing.catalogItem.id.uuid || '';
  // const productCatalog = currentListing?.catalogItem
  //   ? productCatalogList.find( product => product.catalogItems.find(item => item.id.uuid === catalogItemId))
  //   : null;

  const isNewListing = !currentListing?.id?.uuid;
  const isWarehouse = currentListing?.provider?.attributes?.isWarehouse;
  const isWarehouseFromPL = isWarehouse && currentListing?.provider?.attributes?.countryId === 'pl';

  return (
    <div className={classes}>
      <div className={css.headerWrapper}>
        <h1 className={css.title}>{panelTitle}</h1>
      </div>
      {
        !isNewListing && (
          <div className={css.productInfo}>
            {hasItemAttached ?  <FormattedMessage id="EditListingDescriptionPanel.catalogProduct" /> : <FormattedMessage id="EditListingDescriptionPanel.anotherProduct" />}
          </div>
        )
      }
      {isNewListing && !productCreationMethod ?
        <div className={css.btnSection}>
          <Button
            onClick={() => setProductCreationMethod(PRODUCT_CREATION_METHOD.CATALOG_PRODUCT)}
          >
            <FormattedMessage id="EditListingDescriptionPanel.catalogProductBtn" />
          </Button>
          <Button onClick={() => setProductCreationMethod(PRODUCT_CREATION_METHOD.NEW_PRODUCT)}>
            <FormattedMessage id="EditListingDescriptionPanel.newProductBtn" />
          </Button>
        </div>
        :
      <EditListingDescriptionForm
        className={css.form}
        initialValues={{
          ...initialValuesToPass,
          quantity: initialQuantity,
          minBookingDays: initialMinBookingDays,
          catalogProduct: productCatalog ? {value: productCatalog.id.uuid, label: `${productCatalog.attributes.modelCode} - ${productCatalog.attributes.title}`, price: productCatalog.attributes.price } : {value: '', label: ''} ,
          catalogItem: catalogItemId
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            title,
            description,
            code,
            price,
            category,
            quantity,
            externalLink,
            depositConditions,
            minBookingDays,
            provider,
            businessCategory,
            serviceDaysAmount,
            catalogProduct,
            catalogItem,
            customParts,
            customPrice,
            isCustomPrice
          } = values;
          const providerIdMaybe = currentListing.provider && currentListing.provider.id.uuid ? {} : { providerId: provider.value };
          const customPriceMaybe = (hasItemAttached && (!isWarehouse || !isCustomPrice)) ? { customPrice: null } : { customPrice: isWarehouse && hasItemAttached ? (customPrice?.amount || null) : price?.amount };
          const catalogItemIdMaybe = isCatalogProduct ? { catalogItemId: catalogItem } : {};
          const titleMaybe = isNewListing && isCatalogProduct ?  {} : {  title: title && title.trim() };
          const descriptionMaybe = isNewListing && isCatalogProduct ? {} : { description };
          const categoryMaybe = isNewListing && isCatalogProduct ? {} : { category };
          const businessCategoryMaybe = isNewListing && isCatalogProduct ? {} : { businessCategory };
          const codeMaybe = isNewListing && isCatalogProduct ? {} : { code };
          const externalLinkMaybe = isNewListing && isCatalogProduct ? {} : { externalLink };
          // const partsMaybe = isNewListing && isCatalogProduct ? {} : { parts };
          const minBookingDaysMaybe = isNewListing && isCatalogProduct ? {} : {  minBookingDays: parseInt(minBookingDays) };
          const isAssignCatalogProduct = !isCatalogProduct && !isNewListing && catalogItem;

          const assignValues = {
            id: currentListing?.id?.uuid ,
            catalogItemId: catalogItem,
            customPrice: (customPrice && isCustomPrice) ? customPrice.amount : null,
            customParts: null
          }

          const updateValues = {
              ...providerIdMaybe,
              ...titleMaybe,
              ...descriptionMaybe,
              ...customPriceMaybe,
              ...categoryMaybe,
              ...businessCategoryMaybe,
              ...codeMaybe,
              ...externalLinkMaybe,
              // ...partsMaybe,
              ...minBookingDaysMaybe,
              depositConditions,
              customParts,
              serviceDaysAmount: parseInt(serviceDaysAmount),
              ...catalogItemIdMaybe
            };

          const productValues = isAssignCatalogProduct ? assignValues : updateValues;

          onSubmit(productValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
        displayPartsField={true}
        currentUserProviders={currentUserProviders}
        productCatalogList={productCatalogList}
        hasItemAttached={hasItemAttached}
        isCatalogProduct={isCatalogProduct}
        isNewListing={isNewListing}
        onManageDisableScrolling={onManageDisableScrolling}
        userPublicData={userPublicData}
        isWarehouseFromPL={isWarehouseFromPL}
      />
      }
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
  userPublicData: {}
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  productCatalogList: propTypes.catalogProduct,
  userPublicData: object.isRequired,
};

export default EditListingDescriptionPanel;

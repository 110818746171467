/**
 * Note: This form is using card from Stripe Elements https://stripe.com/docs/stripe-js#elements
 * Card is not a Final Form field so it's not available trough Final Form.
 * It's also handled separately in handleSubmit function.
 */
import React, { Component } from 'react';
import classNames from 'classnames';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';

import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { ensurePaymentMethodCard } from '../../util/data';
import config from '../../config';
import {
  Form,
  PrimaryButton,
  FieldCheckbox,
  FieldSelect,
  CheckoutCustomFields
} from '../../components';

import css from './StripePaymentFormServiceFee.css';

const INVOICE_FIELDS = [
  {
    "label": "StripePaymentForm.country",
    "type": "text",
    "placeholder": "StripePaymentForm.country",
    "name": "invoice.country",
    "required": true
  },
  {
    "label": "StripePaymentForm.city",
    "type": "text",
    "placeholder": "StripePaymentForm.city",
    "name": "invoice.city",
    "required": true
  },
  {
    "label": "StripePaymentForm.zip",
    "type": "text",
    "placeholder": "StripePaymentForm.zip",
    "name": "invoice.zip",
    "required": true
  },
  {
    "label": "StripePaymentForm.address",
    "type": "text",
    "placeholder": "StripePaymentForm.address",
    "name": "invoice.address",
    "required": true
  },
];

const COMPANY_FIELDS = [
  {
    "label": "StripePaymentForm.companyName",
    "type": "text",
    "placeholder": "StripePaymentForm.companyName",
    "name": "invoice.companyName",
    "required": true
  },
  {
    "label": "StripePaymentForm.taxId",
    "type": "text",
    "placeholder": "StripePaymentForm.taxId",
    "name": "invoice.taxId",
    "required": true
  },
  ...INVOICE_FIELDS
];

const INDIVIDUAL_FIELDS = [
  {
    "label": "StripePaymentForm.customerName",
    "type": "text",
    "placeholder": "StripePaymentForm.customerName",
    "name": "invoice.name",
    "required": true
  },
  ...INVOICE_FIELDS
]

const getPaymentMethod = (selectedPaymentMethod, hasDefaultPaymentMethod) => {
  return selectedPaymentMethod == null && hasDefaultPaymentMethod
    ? 'defaultCard'
    : selectedPaymentMethod == null
      ? 'onetimeCardPayment'
      : selectedPaymentMethod;
};

const initialState = {
  error: null,
  cardValueValid: false,
  // The mode can be 'onetimePayment', 'defaultCard', or 'replaceCard'
  // Check SavedCardDetails component for more information
  paymentMethod: null,
  paymentMethodType: null
};

class StripePaymentFormServiceFee extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.paymentForm = this.paymentForm.bind(this);
    this.finalFormAPI = null;
    this.cardContainer = null;
    this.p24Container = null;
    this.deliveryOption = null;
    this.elements = null;
  }

  handleSubmit(values) {
    const {
      onSubmit,
      inProgress,
      formId,
      defaultPaymentMethod,
    } = this.props;

    const { paymentMethod } = this.state;

    if (inProgress) {
      // Already submitting or card value incomplete/invalid
      return;
    }

    const params = {
      card: this.card,
      elements: this.elements,
      formId,
      formValues: { ...values, delivery: this.state.deliveryOption },
      paymentMethod: this.state.paymentMethodType === 'p24' ? 'p24' : getPaymentMethod(
        paymentMethod,
        ensurePaymentMethodCard(defaultPaymentMethod).id
      )
    };

    onSubmit(params);
  }

  paymentForm(formRenderProps) {
    const {
      className,
      rootClassName,
      inProgress: submitInProgress,
      formId,
      authorDisplayName,
      intl,
      initiateOrderError,
      confirmPaymentError,
      invalid,
      handleSubmit,
      form,
      hasHandledCardPayment,
      superStore,
      values,
      warnings,
      updateTransactionInProgress,
      updateTransactionError
    } = formRenderProps;

    this.finalFormAPI = form;
    const billingDetailsNeeded = !(hasHandledCardPayment || confirmPaymentError);
    const classes = classNames(rootClassName || css.root, className);
    const hasStripeKey = config.stripe.publishableKey;
    const { providerRules } = this.props;

    const confirmButtonDisabled =  !values.terms ||
      invalid ||
      submitInProgress ||
      !!warnings?.length;

    return hasStripeKey ? (
      <Form className={classes} onSubmit={handleSubmit} id="checkoutForm">
        {initiateOrderError ? (
          <span className={css.errorMessage}>{initiateOrderError.message}</span>
        ) : null}
        {(superStore.attributes.countryId === "hu" || superStore.attributes.countryId === "cz" ||
          (superStore.attributes.countryId === "pl" && superStore.attributes.isDecathlon)) && (
          <div className={css.paymentSection}>
            <h3>
              {intl.formatMessage(
                { id: "StripePaymentForm.invoiceDetails" },
                { sectionNumber: "" }
              )}
            </h3>
            <FieldSelect
              id="type"
              name="customerType"
              className={css.selectCountry}
              label={intl.formatMessage({ id: "StripePaymentForm.customerType" })}
              validate={validators.required("Type required")}
            >
              <option value=''>
                {intl.formatMessage({ id: "StripePaymentForm.choose" })}
              </option>
              <option value="individual">
                {intl.formatMessage({ id: "StripePaymentForm.indivdual" })}
              </option>
              <option value="company">
                {intl.formatMessage({ id: "StripePaymentForm.company" })}
              </option>
            </FieldSelect>
            <CheckoutCustomFields
              additionalFields={{
                fields:
                  values.customerType === 'company'
                    ? COMPANY_FIELDS.map(f => ({
                        ...f,
                        required: values.customerType === 'company',
                      }))
                    : INDIVIDUAL_FIELDS.map(f => ({
                        ...f,
                        required: values.customerType === 'individual',
                      })),
              }}
              intl={intl}
              needsTranslation
            />
            <div className={classNames(css.fieldsRow, css.checkboxSaveInvoiceContainer)}>
              <FieldCheckbox
                id="saveInvoiceDetails"
                name="saveInvoiceDetails"
                label={intl.formatMessage({ id: "StripePaymentForm.saveInvoiceDetails" })}
                size="large"
              />
            </div>
          </div>
        )}
        <div className={classNames(css.fieldsRow, css.checkboxTermContainer)}>
          <FieldCheckbox
            id="terms"
            name="terms"
            label={ <span>
              {`${intl.formatMessage({ id: "StripePaymentForm.rentingTerms1" })} `}
              <a href={providerRules} className={css.termsLink} target="_blank">
                {intl.formatMessage({ id: "StripePaymentForm.rentingTerms2" })}
              </a>
            </span>}
            required
            size="large"
          />
        </div>
        <div className={css.submitContainer}>
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress || updateTransactionInProgress}
            disabled={confirmButtonDisabled}
          >
            {billingDetailsNeeded ? (
              <FormattedMessage id="StripePaymentForm.submitPaymentInfo" />
            ) : (
              <FormattedMessage id="StripePaymentForm.submitConfirmPaymentInfo" />
            )}
          </PrimaryButton>
          <p className={css.legalInfo}>
            <FormattedMessage id="StripePaymentForm.ppLink" />
            <a
              href={`https://prod-rent-backend-a9sd29me0v9sv.s3.eu-west-1.amazonaws.com/prod/doc/privacy-policy-${config.locale}.pdf`}
              className={css.legalLink}
              target="_blank"
            >
              <FormattedMessage id="Footer.privacyPolicy" />
            </a>
          </p>
        </div>
      </Form>
    ) : (
      <div className={css.missingStripeKey}>
        <FormattedMessage id="StripePaymentForm.missingStripeKey" />
      </div>
    );
  }

  render() {
    const { onSubmit, ...rest } = this.props;
    return <FinalForm onSubmit={this.handleSubmit} {...rest} render={this.paymentForm} keepDirtyOnReinitialize />;
  }
}

StripePaymentFormServiceFee.defaultProps = {
  className: null,
  rootClassName: null,
  inProgress: false,
  loadingData: false,
  showInitialMessageInput: true,
  hasHandledCardPayment: false,
  defaultPaymentMethod: null,
  initiateOrderError: null,
  handleCardPaymentError: null,
  confirmPaymentError: null,
};

StripePaymentFormServiceFee.propTypes = {
  className: string,
  rootClassName: string,
  inProgress: bool,
  loadingData: bool,
  initiateOrderError: object,
  handleCardPaymentError: object,
  confirmPaymentError: object,
  formId: string.isRequired,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  paymentInfo: string.isRequired,
  authorDisplayName: string.isRequired,
  showInitialMessageInput: bool,
  hasHandledCardPayment: bool,
  defaultPaymentMethod: propTypes.defaultPaymentMethod,
};

export default injectIntl(StripePaymentFormServiceFee);

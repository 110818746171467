import React from 'react';
import classNames from 'classnames';
import { arrayOf, oneOf, shape, string } from 'prop-types';

import { intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { formatToShortAndLongDate } from '../../../util/dates';
import { NamedLink, UserDisplayName } from '../../../components';

import css from './ServiceFeeItem.css';

const ServiceFeeItem = props => {
  const { type, transaction, intl, stateOptions } = props;
  const { user, provider } = transaction;
  const isOrder = type === "order";
  const otherUser = isOrder ? provider : user;
  const customerName = !isOrder ? `${transaction.attributes.firstName} ${transaction.attributes.lastName}` : undefined;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} nameToDisplay={customerName} />;
  const isOtherUserBanned = otherUser.attributes.banned;
  const dateOfLastTransitionedAt = transaction.attributes.lastTransitionedAt || transaction.attributes.createdAt;
  const lastTransitionedAt = formatToShortAndLongDate(intl, dateOfLastTransitionedAt);
  const stateValue = stateOptions.find(item => transaction.attributes.state === item.value);

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  return (
    <ul className={css.listItem}>
      <div className={classNames(css.item)}>
        <NamedLink
          className={linkClasses}
          name={ isOrder ? "ServiceFeeDetailsPage" : "SaleServiceFeeDetailsPage" }
          params={{ id: transaction.id.uuid }}
        >
          <div className={css.itemInfo}>
            <div className={classNames(css.itemUsername )}>
              {otherUserDisplayName}
            </div>
            <p className={css.serviceFee}>
              {intl.formatMessage({ id: "ServiceFeeInboxPage.serviceFee" })}
            </p>
          </div>
          <div className={css.itemState}>
            <div className={classNames(css.statusText, stateValue.className)}>
              {intl.formatMessage({ id: stateValue.label })}
            </div>
            <div
              className={classNames(css.lastTransitionedAt)}
              title={lastTransitionedAt.long}
            >
              {lastTransitionedAt.short}
            </div>
          </div>
        </NamedLink>
      </div>
    </ul>
  );
};

ServiceFeeItem.propTypes = {
  type: oneOf(['order', 'sale']).isRequired,
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  stateOptions: arrayOf(shape({
    value: string,
    label: string,
    className: string
  }))
};

export default ServiceFeeItem;

import React from 'react';
import classNames from 'classnames';
import moment from 'moment/moment';
import { string } from 'prop-types';

import { intlShape } from '../../util/reactIntl';
import ListingsTransaction from './ListingsTransaction';

import css from './RelatedTransactionsSection.css';

const ServiceFeeTransaction = ({intl, statusClass, statusText, createdAt}) => {
  return (
    <>
      <span className={css.rentalDates}>
        {intl.formatMessage({ id: "ServiceFeeTransaction.serviceFee" })}
      </span>
      <span className={classNames(statusClass, css.status)}>
        {`${intl.formatMessage({ id: statusText })} `}
      </span>
      <span className={css.createdDate}>
        {moment(createdAt).format("DD MMM YYYY HH:mm")}
      </span>
    </>
  )
}

ListingsTransaction.propTypes = {
  intl: intlShape.isRequired,
  statusClass: string.isRequired,
  statusText: string.isRequired,
  createdAt: string.isRequired,
}

export default ServiceFeeTransaction;

import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { queryUserListings, queryUserReviews, showUser } from '../ProfilePage/ProfilePage.duck';
import Promise from 'lodash/_Promise';

// ================ Action types ================ //

export const ADD_TO_CART_REQUEST = 'app/CartPage/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'app/CartPage/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'app/CartPage/ADD_TO_CART_ERROR';

export const FETCH_CART_REQUEST = 'app/CartPage/FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'app/CartPage/FETCH_CART_SUCCESS';
export const FETCH_CART_ERROR = 'app/CartPage/FETCH_CART_ERROR';

export const DELETE_FROM_CART_REQUEST = 'app/CartPage/DELETE_FROM_CART_REQUEST';
export const DELETE_FROM_CART_SUCCESS = 'app/CartPage/DELETE_FROM_CART_SUCCESS';
export const DELETE_FROM_CART_ERROR = 'app/CartPage/DELETE_FROM_CART_ERROR';

export const FETCH_PENDING_SERVICE_FEES_REQUEST = 'app/CartPage/FETCH_SERVICE_FEES_REQUEST';
export const FETCH_PENDING_SERVICE_FEES_SUCCESS = 'app/CartPage/FETCH_SERVICE_FEES_SUCCESS';
export const FETCH_PENDING_SERVICE_FEES_ERROR = 'app/CartPage/FETCH_SERVICE_FEES_ERROR';

// ================ Reducer ================ //

const initialState = {
  addToCartInProgress: false,
  addToCartError: null,
  addToCartSuccess: false,
  fetchInProgress: false,
  fetchCartError: null,
  deleteFromCartInProgress: false,
  deleteFromCartError: null,
  deleteFromCartSuccess: false,
  fetchServiceFeesInProgress: false,
  fetchServiceFeesError: null,
  fetchServiceFeesSuccess: false,
  pagination: null,
  cart: [],
  pendingServiceFeeTransaction: [],
};

export default function cartPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case ADD_TO_CART_REQUEST:
      return { ...state, addToCartInProgress: true, addToCartError: null, addToCartSuccess: false };
    case ADD_TO_CART_SUCCESS:
      return { ...state, addToCartInProgress: false,  addToCartError: null, addToCartSuccess: true };
    case ADD_TO_CART_ERROR:
      return { ...state, addToCartInProgress: false, addToCartError: payload, addToCartSuccess: false };
    case FETCH_CART_REQUEST:
      return { ...state, fetchInProgress: true, fetchCartError: null };
    case FETCH_CART_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        cart: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CART_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchCartError: payload };
    case DELETE_FROM_CART_REQUEST:
      return {
        ...state,
        deleteFromCartInProgress: true,
        deleteFromCartError: null,
        deleteFromCartSuccess: false,
      };
    case DELETE_FROM_CART_SUCCESS:
      return { ...state, deleteFromCartInProgress: false, deleteFromCartSuccess: true };
    case DELETE_FROM_CART_ERROR:
      return {
        ...state,
        deleteFromCartInProgress: false,
        deleteFromCartError: payload,
        deleteFromCartSuccess: false,
      };
    case FETCH_PENDING_SERVICE_FEES_REQUEST:
      return {
        ...state,
        fetchServiceFeesInProgress: true,
        fetchServiceFeesError: null,
        fetchServiceFeesSuccess: false,
        pagination: null,
      };
    case FETCH_PENDING_SERVICE_FEES_SUCCESS:
      return {
        ...state,
        fetchServiceFeesInProgress: false,
        pendingServiceFeeTransaction: [ ...payload.data.data.map(item => ({
              attributes: item.attributes,
              id: item.id,
              provider: payload.data.included.find(i => i.id.uuid === item.relationships.provider.data.id.uuid),
              relatedTransactions: [
                ...item.relationships.relatedTransactions.data.map(tr => ({
                  ...tr,
                  type: 'related-transaction',
                  attributes: {
                    ...payload.data.included.find(i => i.id.uuid === tr.id.uuid).attributes
                  },
                  listings:[
                    ...payload.data.included.find(i => i.id.uuid === tr.id.uuid).relationships.listings.data.map(l => {
                      const listing = payload.data.included.find(el => el.id.uuid === l.id.uuid);
                      return {
                        attributes: listing.attributes,
                        id: listing.id,
                        type: listing.type,
                        images: [...listing.relationships.images.data.map(img => {
                          const image = payload.data.included.find(el => el.id.uuid === img.id.uuid);
                          return {
                            attributes: image.attributes,
                            type: image.type,
                            id: image.id,
                          }
                        })]
                      }
                    })
                  ]
                }))
              ],
            type: item.type,
            }))
            ],
        fetchServiceFeesSuccess: true,
        fetchServiceFeesError: null,
      };
    case FETCH_PENDING_SERVICE_FEES_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchServiceFeesInProgress: false,
        fetchServiceFeesError: payload,
        fetchServiceFeesSuccess: true,
        pendingServiceFeeTransaction: [],
      };
    default:
      return state;
  }
}
// ================ Action creators ================ //

const initiateAddToCartRequest = () => ({ type: ADD_TO_CART_REQUEST });

const addToCartSuccess = response => ({
  type: ADD_TO_CART_SUCCESS,
  payload: response,
});

const addToCartError = (e) => ({
  type: ADD_TO_CART_ERROR,
  error: true,
  payload: e,
});

const fetchCartRequest = () => ({ type: FETCH_CART_REQUEST });

const fetchCartSuccess = response => ({
  type: FETCH_CART_SUCCESS,
  payload: response,
});

const fetchCartError = e => ({
  type: FETCH_CART_ERROR,
  error: true,
  payload: e,
});

const deleteCartRequest = () => ({ type: DELETE_FROM_CART_REQUEST });

const deleteFromCartError = e => ({
  type: DELETE_FROM_CART_ERROR,
  error: true,
  payload: e,
});

const deleteFromCartSuccess = response => ({
  type: DELETE_FROM_CART_SUCCESS,
  payload: response,
});

const fetchPendingServiceFeesRequest = () => ({ type: FETCH_PENDING_SERVICE_FEES_REQUEST });
const fetchPendingServiceFeesSuccess = response => ({
  type: FETCH_PENDING_SERVICE_FEES_SUCCESS,
  payload: response,
});

const fetchPendingServiceFeesError = e => ({
  type: FETCH_PENDING_SERVICE_FEES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const addToCart = cartParams => (dispatch, getState, sdk) => {
  dispatch(initiateAddToCartRequest());

  return sdk.newSdk.cartListings
    .create({
      listingId: cartParams.listingId,
      amount: cartParams.amount,
      start: cartParams.start,
      end: cartParams.end,
    })
    .then(response => {
      dispatch(addToCartSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(addToCartError(e));
      throw e;
    });
};

export const deleteFromCart = listingId => (dispatch, getState, sdk) => {
  dispatch(deleteCartRequest());

  return sdk.newSdk.cartListings
    .delete({ id: listingId })
    .then(response => {
      dispatch(deleteFromCartSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(deleteFromCartError(error));
      throw error;
    });
};

export const addCartToUser = (cartId, email) => (dispatch, getState, sdk) => {
  return sdk.newSdk.cartPreorders
    .update({ id: cartId, clientEmail: email })
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
}

const fetchPendingServiceFees =  () => (dispatch, getState, sdk) => {
  dispatch(fetchPendingServiceFeesRequest());

  return sdk.newSdk.transactions
    .query({
      serviceFee: true,
      state: 'pending',
      userId: 'me',
      include: ['relatedTransactions.listings.images','provider'],
    })
    .then(response => {
      dispatch(fetchPendingServiceFeesSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchPendingServiceFeesError(storableError(e)));
      throw e;
    });
}

export const fetchCartPreorders = (params, search) => (dispatch, getState, sdk) => {
  const queryParams = {
    include: [
      'cartListings',
      'cartListings.listing',
      'provider',
      'cartListings.listing.images',
      'provider.deliveryToProviders'
    ],
  };

  dispatch(fetchCartRequest());

  if (typeof window === 'undefined') {
    return Promise.resolve({
      data: {
        data: [],
        included: [],
        meta: {}
      }
    })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(fetchCartSuccess(response));
        return response;
      })
  }

  return sdk.newSdk.cartPreorders
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCartSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchCartError(e));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  return Promise.all([
    dispatch(fetchCartPreorders()),
    dispatch(fetchPendingServiceFees()),
  ]);
}

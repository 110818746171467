import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { arrayOf, bool, shape, string } from 'prop-types';

import { loadData } from './ServiceFeeInboxPage.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { IconSpinner, InboxWrapper, PaginationLinks } from '../../components';
import ServiceFeeItem from './ServiceFeeItem/ServiceFeeItem';
import ServiceFeeFilters from './ServiceFeeFilters/ServiceFeeFilters';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';

import css from './ServiceFeeInboxPage.css';

//A fee with the 'declined' status is an unpaid and canceled fee.
const stateOptions = [
  { value: "all", label: "ServiceFeeFilters.allFees", className: null },
  { value: "pending", label: "ServiceFeeFilters.pendingPayment", className: css.pending },
  { value: "declined", label: "ServiceFeeFilters.declined", className: css.expired },
  { value: "accepted", label: "ServiceFeeFilters.accepted", className: css.accepted },
  { value: "cancelled", label: "ServiceFeeFilters.cancelled", className: css.cancelled },
];

const ServiceFeeInboxPageComponent = (props) => {
  const {
    fetchInProgress,
    fetchServiceFeesError,
    fetchServiceFeesSuccess,
    intl,
    pagination,
    params,
    location,
    scrollingDisabled,
    transactions,
    currentUserProviders,
    history,
    name,
    currentUser
  } = props;
  const { tab, providerId } = params;
  const isOrders = tab === "orders";
  const type = isOrders ? "order" : "sale";

  const error = fetchServiceFeesError ? (
    <p className={css.error}>
      <FormattedMessage id="ServiceFeeInboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchServiceFeesError ? (
      <p key="noResults" className={css.noResults}>
        <FormattedMessage
          id={isOrders ? "ServiceFeeInboxPage.noOrdersFound" : "ServiceFeeInboxPage.noSalesFound"}
        />
      </p>
    ) : null;

  const pagingLinks =
    !fetchInProgress && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ServiceFeeInboxPage"
        pagePathParams={params}
        pageSearchParams={parse(location.search)}
        pagination={pagination}
      />
    ) : null;

  return (
    <InboxWrapper
      currentUserProviders={currentUserProviders}
      intl={intl}
      scrollingDisabled={scrollingDisabled}
      name={name}
      tab={tab}
      currentUser={currentUser}
    >
      <>
        {error}
        <ServiceFeeFilters
          location={location}
          isOrders={isOrders}
          history={history}
          currentUserProviders={currentUserProviders}
          providerId={providerId}
          intl={intl}
          stateOptions={stateOptions}
        />
        <ul className={css.itemList}>
          {!fetchInProgress ? (
            transactions.map(transaction => (
                <ServiceFeeItem intl={intl} transaction={transaction} type={type} stateOptions={stateOptions}/>
            ))
          ) : (
            <li className={css.listItemsLoading}>
              <IconSpinner />
            </li>
          )}
          {noResults}
        </ul>
        {pagingLinks}
      </>
    </InboxWrapper>
  )
}
const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchServiceFeesError,
    fetchServiceFeesSuccess,
    pagination,
    serviceFees,
  } = state.ServiceFeeInboxPage;

  const { currentUser, userProviders: currentUserProviders } = state.user;

  return {
    currentUser,
    currentUserProviders,
    fetchInProgress,
    fetchServiceFeesError,
    fetchServiceFeesSuccess,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, serviceFees),
  };
};

ServiceFeeInboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
    providerId: string,
  }).isRequired,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchServiceFeesError: propTypes.error,
  fetchServiceFeesSuccess: bool,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,
  intl: intlShape.isRequired,
};

const ServiceFeeInboxPage = compose(
  connect(mapStateToProps),
  injectIntl,
  withRouter
)(ServiceFeeInboxPageComponent);

ServiceFeeInboxPage.loadData = loadData;

export default ServiceFeeInboxPage;

import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { arrayOf, node, number, shape, string } from 'prop-types';

import { NamedLink, ResponsiveImage } from '../../components';
import { formatMoney } from '../../util/currency';
import { typesOfServiceFee } from '../../util/transaction';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';

import css from './ServiceFeeItem.css';

const ServiceFeeItem = ({
  intl,
  orders,
  totalNumberOfOrders,
  orderNumber,
  children,
  provider,
  rootClassName,
  className,
  serviceFee,
  payinTotal,
  serviceFeeFor,
}) => {
  const classes = classNames(rootClassName || css.root, className);
  const typeOfServiceFeeLabel = serviceFee
    && typesOfServiceFee.find(({value}) => value === serviceFee.code).label;

  return (
    <div className={classes}>
      <div className={css.orderContent}>
        <p className={css.title}>
          {!!orderNumber ?
            <span>
              {`${orderNumber}/${totalNumberOfOrders} `}
            </span>
          : null}
          <span>
            <FormattedMessage
              id="ServiceFeeItem.serviceFeeFor"
              values={{ providerName: provider.attributes.name }}
            />
          </span>
        </p>
        <div className={css.mainContent}>
          <div>
            <p className={css.serviceFeeText}>
              {intl.formatMessage({ id: "ServiceFeeItem.type" })}
              : {intl.formatMessage({ id: typeOfServiceFeeLabel })}
            </p>
            {serviceFee.reason ?
              <p className={css.serviceFeeText}>
                {intl.formatMessage({ id: "ServiceFeeItem.reason" })}
                : {serviceFee.reason}
              </p>
              : null}
            <p className={css.serviceFeeText}>
              {intl.formatMessage({ id: "ServiceFeeItem.amount" })}
              : <b>{formatMoney(intl, payinTotal)}</b>
            </p>
          </div>
          {!!serviceFeeFor.length ?
            <div className={css.orderContainer}>
              <p className={classNames(css.subtitle, css.text)}>
                {intl.formatMessage({ id: "ServiceFeeItem.feeAppliesTo" })}
                {serviceFeeFor.map((item, index) => (
                  <>
                    <NamedLink
                      name="OrderDetailsPage"
                      params={{ id: item.id.uuid }}
                    >
                      {` ${item.attributes.code}`}
                    </NamedLink>
                    {index < serviceFeeFor.length-1 && ', '}
                  </>
                ))}
              </p>
              {orders.map(order => {
                const image = order.listing?.images[0];
                const { title } = order.listing.attributes;

                return (
                  <div className={css.productContainer} key={order?.id.uuid}>
                    {image && (
                      <ResponsiveImage
                        rootClassName={css.rootForImage}
                        image={image}
                        alt="product"
                        variants={["square-small", "square-small2x"]}
                      />
                    )}
                    <div className={css.productContent}>
                        <p className={classNames(css.productTitle)}>{title.toLowerCase()}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          : null}
        </div>
      </div>
      {children}
    </div>
  );
};

ServiceFeeItem.defaultProps = {
  totalNumberOfOrders: 0,
  orderNumber: 0,
  children: <></>,
  serviceFeeFor: [],
};

ServiceFeeItem.propTypes = {
  intl: intlShape.isRequired,
  orders: arrayOf(propTypes.listing),
  totalNumberOfOrders: number,
  orderNumber: number,
  children: node,
  provider: propTypes.providerDetails,
  rootClassName: string,
  className: string,
  serviceFee: shape({
    reason: string,
    code: string,
  }).isRequired,
  payinTotal: propTypes.money.isRequired,
  serviceFeeFor: arrayOf(propTypes.transaction),
};

export default ServiceFeeItem;

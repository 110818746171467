import React, { useEffect } from 'react';
import PropTypes, { node } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import { ensureTransaction } from '../../util/data';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { fetchNumberOfPendingServiceFees, fetchNumberOfCartPreorders } from '../../ducks/user.duck';
import {
  cancelServiceFee,
  loadData,
  setInitialValues,
} from './ServiceFeePage.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ServiceFeePanel,
} from '../../components';
import { TopbarContainer } from '../../containers';

import css from './ServiceFeePage.css';

const PROVIDER = 'provider';
const CUSTOMER = 'user';

export const ServiceFeePageComponent = props => {
  const {
    fetchServiceFeeInProgress,
    fetchServiceFeeError,
    transactionRef,
    cancelInProgress,
    currentUser,
    scrollingDisabled,
    transaction,
    history,
    intl,
    params,
    onCancel,
    onManageDisableScrolling,
    callSetInitialValues,
    onInitializeCardPaymentData,
    transactionRole,
    pageName,
    transactionSuccessComponent
  } = props;
  const currentTransaction = ensureTransaction(transaction);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pageName === "ServiceFeeDetailsSuccessPage") {
      dispatch(fetchNumberOfPendingServiceFees());
      dispatch(fetchNumberOfCartPreorders())
    }
  }, []);

  // Redirect users with someone else's direct link to their own inbox/sales or inbox/orders page.
  const isDataAvailable =
    currentUser &&
    currentTransaction.id &&
    currentTransaction.id.uuid === params.id &&
    currentTransaction.attributes.lineItems &&
    currentTransaction.user &&
    currentTransaction.provider &&
    !fetchServiceFeeError;

  const loadingOrFailedFetching = fetchServiceFeeError ? (
    <p className={css.error}>
      <FormattedMessage id="ServiceFeePage.fetchFailed" />
    </p>
  ) : (
    <p className={css.loading}>
      <FormattedMessage id="ServiceFeePage.loadingData" />
    </p>
  );

  const panel = isDataAvailable ? (
    <ServiceFeePanel
      className={classNames(css.tabContent, css.tabContentVisible)}
      currentUser={currentUser}
      transaction={currentTransaction}
      onManageDisableScrolling={onManageDisableScrolling}
      transactionRole={transactionRole}
      onCancel={onCancel}
      cancelInProgress={cancelInProgress}
      history={history}
      pageName={pageName}
      callSetInitialValues={callSetInitialValues}
      onInitializeCardPaymentData={onInitializeCardPaymentData}
      transactionSuccessComponent={transactionSuccessComponent}
    />
  ) : (
    loadingOrFailedFetching
  );

  return (
    <Page
      title={intl.formatMessage({ id: "ServiceFeePage.title" })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
         <div>{panel}</div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter className={css.footer}>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

ServiceFeePageComponent.defaultProps = {
  currentUser: null,
  fetchTransactionError: null,
  acceptSaleError: null,
  declineSaleError: null,
  transaction: null,
  fetchMessagesError: null,
  initialMessageFailedToTransaction: null,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  transactionSuccessComponent: null
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

ServiceFeePageComponent.propTypes = {
  fetchServiceFeeInProgress: bool,
  fetchServiceFeeError: propTypes.error,
  transactionRef: propTypes.transaction,
  cancelInProgress: bool,
  cancelServiceFeeError: propTypes.error,
  onCancel: func,
  onManageDisableScrolling: func,
  pageName: string.isRequired,
  params: shape({ id: string }).isRequired,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  callSetInitialValues: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  transactionSuccessComponent: node,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchServiceFeeInProgress,
    fetchServiceFeeError,
    transactionRef,
    cancelInProgress,
    cancelServiceFeeError,
    defineSuspiciousInProgress,
    defineSuspiciousError,
  } = state.ServiceFeePage;
  const { currentUser,  currentUserHasListings, userProviders } = state.user;
  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;

  return {
    fetchServiceFeeInProgress,
    fetchServiceFeeError,
    transactionRef,
    cancelInProgress,
    cancelServiceFeeError,
    defineSuspiciousInProgress,
    defineSuspiciousError,
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    transaction,
    currentUserHasListings,
    currentUserProviderId: userProviders && !!userProviders.length && userProviders[0].id.uuid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCancel: transactionId => dispatch(cancelServiceFee(transactionId)),
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  };
};

const ServiceFeePage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ServiceFeePageComponent);

ServiceFeePage.loadData = loadData;
ServiceFeePage.setInitialValues = setInitialValues;

export default ServiceFeePage;

import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isEmpty from 'lodash/isEmpty';

import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { TRANSITION_CANCEL_BY_PROVIDER } from '../../util/transaction';
import * as log from '../../util/log';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchNumberOfPendingServiceFees } from '../../ducks/user.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/ServiceFeePage/SET_INITIAL_VALUES';

export const FETCH_SERVICE_FEE_REQUEST = 'app/ServiceFeePage/FETCH_SERVICE_FEE_REQUEST';
export const FETCH_SERVICE_FEE_SUCCESS = 'app/ServiceFeePage/FETCH_SERVICE_FEE_SUCCESS';
export const FETCH_SERVICE_FEE_ERROR = 'app/ServiceFeePage/FETCH_SERVICE_FEE_ERROR';

export const FETCH_TRANSITIONS_REQUEST = 'app/ServiceFeePage/FETCH_TRANSITIONS_REQUEST';
export const FETCH_TRANSITIONS_SUCCESS = 'app/ServiceFeePage/FETCH_TRANSITIONS_SUCCESS';
export const FETCH_TRANSITIONS_ERROR = 'app/ServiceFeePage/FETCH_TRANSITIONS_ERROR';

export const CANCEL_SERVICE_FEE_REQUEST = 'app/ServiceFeePage/CANCEL_SERVICE_FEE_REQUEST';
export const CANCEL_SERVICE_FEE_SUCCESS = 'app/ServiceFeePage/CANCEL_SERVICE_FEE_SUCCESS';
export const CANCEL_SERVICE_FEE_ERROR = 'app/ServiceFeePage/CANCEL_SERVICE_FEE_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchServiceFeeInProgress: false,
  fetchServiceFeeError: null,
  transactionRef: null,
  cancelInProgress: false,
  cancelServiceFeeError: null,
  defineSuspiciousInProgress: false,
  defineSuspiciousError: null,
};

export default function serviceFeePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_SERVICE_FEE_REQUEST:
      return { ...state, fetchServiceFeeInProgress: true, fetchServiceFeeError: null };
    case FETCH_SERVICE_FEE_SUCCESS: {
      const transactionRef = { id: payload.data.data.id, type: 'transaction' };
      return { ...state, fetchServiceFeeInProgress: false, transactionRef };
    }
    case FETCH_SERVICE_FEE_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchServiceFeeInProgress: false, fetchServiceFeeError: payload };

    case FETCH_TRANSITIONS_REQUEST:
      return { ...state, fetchTransitionsInProgress: true, fetchTransitionsError: null };
    case FETCH_TRANSITIONS_SUCCESS:
      return { ...state, fetchTransitionsInProgress: false, processTransitions: payload };
    case FETCH_TRANSITIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchTransitionsInProgress: false, fetchTransitionsError: payload };

    case CANCEL_SERVICE_FEE_REQUEST:
      return { ...state, cancelInProgress: true, cancelServiceFeeError: null, acceptSaleError: null };
    case CANCEL_SERVICE_FEE_SUCCESS:
      return { ...state, cancelInProgress: false };
    case CANCEL_SERVICE_FEE_ERROR:
      return { ...state, cancelInProgress: false, cancelServiceFeeError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //
export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const fetchServiceFeeRequest = () => ({ type: FETCH_SERVICE_FEE_REQUEST });

const fetchServiceFeeSuccess = response => ({
  type: FETCH_SERVICE_FEE_SUCCESS,
  payload: response,
});

const fetchServiceFeeError = e => ({ type: FETCH_SERVICE_FEE_ERROR, error: true, payload: e });

const cancelServiceFeeRequest = () => ({ type: CANCEL_SERVICE_FEE_REQUEST });

const cancelServiceFeeSuccess = () => ({ type: CANCEL_SERVICE_FEE_SUCCESS });

const cancelServiceFeeError = e => ({ type: CANCEL_SERVICE_FEE_ERROR, error: true, payload: e });

// ================ Thunks ================ //

export const fetchServiceFee = (id, txRole) => (dispatch, getState, sdk) => {
  dispatch(fetchServiceFeeRequest());

  return sdk.newSdk.transactions
    .show(
      {
        id: id.uuid,
        include: [
          'user',
          'user.image',
          'provider',
          'provider.image',
          'listings',
          'deliveryToProvider',
          'listings.images',
          'relatedTransactions.listings.images'
        ],
      },
      { expand: true }
    )
    .then(response => {
      dispatch(addMarketplaceEntities({
        data: {
          data: {
            ...response.data.data,
            relationships: {
              ...response.data.data.relationships,
              relatedTransactions: {
                data: response.data.data.relationships.relatedTransactions.data.map(p => ({...p, type: 'related-transaction'})).filter(i => i.id.uuid !== id.uuid)
              }
            }
          },
          included: response.data.included.map(i => {
            if (i.type === 'transaction') {
              return {
                ...i,
                type: 'related-transaction'
              }
            } else {
              return i
            }
          }).filter(i => i.id.uuid !== id.uuid)
        },
      }));
      dispatch(fetchServiceFeeSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchServiceFeeError(storableError(e)));
      throw e;
    });
};

export const cancelServiceFee = id => (dispatch, getState, sdk) => {
  dispatch(cancelServiceFeeRequest());

  return sdk.newSdk.transactions
    .cancel({ id: id.uuid })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(cancelServiceFeeSuccess());
      dispatch(fetchNumberOfPendingServiceFees());
      return response;
    })
    .catch(e => {
      dispatch(cancelServiceFeeError(storableError(e)));
      log.error(e, 'reject-sale-failed', {
        txId: id,
        transition: TRANSITION_CANCEL_BY_PROVIDER,
      });
      throw e;
    });
};

const isNonEmpty = value => {
  return typeof value === 'object' || Array.isArray(value) ? !isEmpty(value) : !!value;
};

// loadData is a collection of async calls that need to be made
// before page has all the info it needs to render itself
export const loadData = params => (dispatch, getState) => {
  const txId = new UUID(params.id);
  const state = getState().TransactionPage;
  const txRef = state.transactionRef;
  const txRole = params.transactionRole;

  // In case a transaction reference is found from a previous
  // data load -> clear the state. Otherwise keep the non-null
  // and non-empty values which may have been set from a previous page.
  const initialValues = txRef ? {} : pickBy(state, isNonEmpty);
  dispatch(setInitialValues(initialValues));

  // Sale / order (i.e. transaction entity in API)
  return dispatch(fetchServiceFee(txId, txRole))

};

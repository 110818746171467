import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { parse } from '../../util/urlHelpers';
import config from '../../config';
import moment from 'moment/moment';

// ================ Action types ================ //

export const FETCH_CONSOLE_REVIEWS_REQUEST = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_REQUEST';
export const FETCH_CONSOLE_REVIEWS_SUCCESS = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_SUCCESS';
export const FETCH_CONSOLE_REVIEWS_ERROR = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_ERROR';

export const EXPORT_REVIEWS_REQUEST =
  'app/ConsoleReviewPage/EXPORT_REVIEWS_REQUEST';
export const EXPORT_REVIEWS_SUCCESS =
  'app/ConsoleReviewPage/EXPORT_REVIEWS_SUCCESS';
export const EXPORT_REVIEWS_ERROR =
  'app/ConsoleReviewPage/EXPORT_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchReviewListError: null,
  pagination: null,
  reviewList: [],
  exportInProgress: false,
  exportReviewListError: null,
};

export default function consoleReviewPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_REVIEWS_REQUEST:
      return { ...state, fetchInProgress: true, fetchReviewListError: null };
    case FETCH_CONSOLE_REVIEWS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        reviewList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_REVIEWS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchProvidersError: payload };

    case EXPORT_REVIEWS_REQUEST:
      return { ...state, exportInProgress: true, exportReviewListError: null };
    case EXPORT_REVIEWS_SUCCESS: {
      return {
        ...state,
        exportInProgress: false,
        exportReviewListError: null,
      };
    }
    case EXPORT_REVIEWS_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        exportInProgress: false,
        exportReviewListError: payload
      };

    default:
      return state;
  }
}
// ================ Action creators ================ //

const fetchReviewListRequest = () => ({ type: FETCH_CONSOLE_REVIEWS_REQUEST });

const fetchReviewListSuccess = response => ({
  type: FETCH_CONSOLE_REVIEWS_SUCCESS,
  payload: response,
});

const fetchReviewListError = e => ({
  type: FETCH_CONSOLE_REVIEWS_ERROR,
  error: true,
  payload: e,
});

const exportReviewListRequest = () => ({ type: EXPORT_REVIEWS_REQUEST });

const exportReviewsListSuccess = () => ({
  type: EXPORT_REVIEWS_SUCCESS,
});

// ================ Thunks ================ //

export const exportReviewsToXlsx = (search) => async (dispatch, getState, sdk) => {
  const { countryId = config.custom.countryId, rating, start, end, providerId } = parse(search);

  const providerParam = providerId ? { providerId } : {};
  const ratingParam = rating ? { rating } : {};

  const currentEndDate = end ? new Date(end) : new Date();
  const endYear = currentEndDate.getFullYear();
  const endMonth = currentEndDate.getMonth();
  const endDay = currentEndDate.getDate();
  const endHours = currentEndDate.getHours();
  const endMinutes = currentEndDate.getMinutes();
  const endValue = new Date(endYear, endMonth, endDay + 1, endHours, endMinutes - 1)
    .toISOString()

  const createdAtFromParam = start ? { createdAtFrom: start } : {};
  const createdAtToParam = end ? {  createdAtTo: endValue } : {};

  const queryParams = {
    include: ['provider', 'transaction'],
    countryId,
    ...providerParam,
    ...ratingParam,
    ...createdAtFromParam,
    ...createdAtToParam
  };

  dispatch(exportReviewListRequest());

  const formatDate = item => ({
    user: `${item.rels.transaction.attributes.firstName} ${item.rels.transaction.attributes.lastName}`,
    provider: item.rels.provider.attributes.name,
    countryId: item.rels.provider.attributes.countryId,
    rating: item.attributes.rating,
    review: item.attributes.text,
    createdAt: moment(item.attributes.createdAt).format("DD-MM-YYYY HH:mm"),
  })

  const fileName = {
    filename: 'reviews'
  }

  return await sdk.newSdk.reviews
    .xlsx(queryParams, formatDate, fileName)
    .then(() => dispatch(exportReviewsListSuccess()))
    .catch(e => {
      throw e;
    });
};

const CONSOLE_PAGE_SIZE = 100;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, countryId = config.custom.countryId, rating, start, end, providerId } = parse(search);

  const currentEndDate = end ? new Date(end) : new Date();
  const currentEndDay = currentEndDate.getDate();
  const currentEndYear = currentEndDate.getFullYear();
  const currentEndMonth = currentEndDate.getMonth();
  const endDay = new Date(currentEndYear, currentEndMonth, currentEndDay + 2)
    .toISOString()
    .split('T')[0];

  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const queryParams = {
    ...paginationParams,
    include: ['provider', 'transaction'],
    countryId,
    providerId,
    rating,
    createdAtFrom: start,
    createdAtTo: endDay
  };

  dispatch(fetchReviewListRequest());

  return sdk.newSdk.reviews
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchReviewListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchReviewListError(e));
      throw e;
    });
};

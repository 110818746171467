import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { bool, object } from 'prop-types';

import css from './OrderItemInfo.css';

const AddressDeliveryHome = ({ deliveryToHome, isListingTransaction }) => {
  if (!deliveryToHome || !isListingTransaction) return null;

  return (
    <div className={classNames(css.section, css.sectionDeliverAddress)} key="delivery_to_home">
      <p className={css.title}>
        <FormattedMessage id="OrderInfo.deliveryAddress" />
      </p>
      <p className={css.text}>{deliveryToHome.address}</p>
      <p className={css.text}>{deliveryToHome.city}</p>
    </div>
  );
}

AddressDeliveryHome.defaultProps = {
  isListingTransaction: true,
}

AddressDeliveryHome.propTypes = {
  deliveryToHome: object.isRequired,
  isListingTransaction: bool,
}

export default AddressDeliveryHome;

import pick from 'lodash/pick';

import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getFormattedServiceFeeResponse } from './ServiceFeeHelpers';

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/CheckoutPageServiceFee/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPageServiceFee/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPageServiceFee/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPageServiceFee/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPageServiceFee/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPageServiceFee/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPageServiceFee/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPageServiceFee/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPageServiceFee/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPageServiceFee/STRIPE_CUSTOMER_ERROR';

export const FETCH_SERVICE_FEE_TRANSACTION_REQUEST = 'app/CheckoutPageServiceFee/FETCH_SERVICE_FEE_TRANSACTION_REQUEST';
export const FETCH_SERVICE_FEE_TRANSACTION_SUCCESS = 'app/CheckoutPageServiceFee/FETCH_SERVICE_FEE_TRANSACTION_SUCCESS';
export const FETCH_SERVICE_FEE_TRANSACTION_ERROR = 'app/CheckoutPageServiceFee/FETCH_SERVICE_FEE_TRANSACTION_ERROR';

export const UPDATE_TRANSACTION_REQUEST = 'app/CheckoutPageServiceFee/UPDATE_TRANSACTION_REQUEST';
export const UPDATE_TRANSACTION_SUCCESS = 'app/CheckoutPageServiceFee/UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_ERROR = 'app/CheckoutPageServiceFee/UPDATE_TRANSACTION_ERROR';


const EMULATED_TRANSACTION = {
  firstName: 'emulated',
  lastName: 'emulated',
  receiverName: 'emulated',
  phone: 'emulated',
  emulate: 'with-warnings',
};

// ================ Reducer ================ //

const initialState = {
  listing: null,
  listings: [],
  provider: null,
  bookingData: null,
  bookingDates: null,
  totalNumberOfOrders: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  currentTransaction: null,
  fetchServiceFeeTransactionInProgress: false,
  fetchServiceFeeTransactionSuccess: false,
  fetchServiceFeeTransactionError: null,
  updateTransactionInProgress: false,
  updateTransactionSuccess: false,
  updateTransactionError: null,
};

export default function CheckoutPageServiceFeeReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case FETCH_SERVICE_FEE_TRANSACTION_REQUEST:
      return {
        ...state,
        fetchServiceFeeTransactionInProgress: true,
        fetchServiceFeeTransactionSuccess: false,
        fetchServiceFeeTransactionError: null,
      }
    case FETCH_SERVICE_FEE_TRANSACTION_ERROR:
      return {
        ...state,
        fetchServiceFeeTransactionInProgress: false,
        fetchServiceFeeTransactionSuccess: false,
        fetchServiceFeeTransactionError: payload,
      }
    case FETCH_SERVICE_FEE_TRANSACTION_SUCCESS:
      const {
        formattedResponse,
        originTransaction,
        bookingDatesOfServiceFee,
        listingsOfServiceFee,
        transactionIsPaid
      } = getFormattedServiceFeeResponse(payload);

      return {
        ...state,
        fetchServiceFeeTransactionInProgress: false,
        fetchServiceFeeTransactionSuccess: true,
        fetchServiceFeeTransactionError: null,
        currentTransactionRef: formattedResponse,
        listings: !transactionIsPaid && listingsOfServiceFee,
        provider: !transactionIsPaid && originTransaction && formattedResponse.provider,
        totalNumberOfOrders: listingsOfServiceFee.length,
        bookingDates: !transactionIsPaid && bookingDatesOfServiceFee,
      }
    case UPDATE_TRANSACTION_REQUEST:
      return {
        ...state,
        updateTransactionInProgress: true,
        updateTransactionSuccess: false,
        updateTransactionError: null,
      }
    case UPDATE_TRANSACTION_ERROR:
      return {
        ...state,
        updateTransactionInProgress: false,
        updateTransactionSuccess: false,
        updateTransactionError: payload,
      }
    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        updateTransactionInProgress: false,
        updateTransactionSuccess: true,
        updateTransactionError: null,
        currentTransactionRef: payload.transaction
      }

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const fetchTransactionRequest = () => ({ type: FETCH_SERVICE_FEE_TRANSACTION_REQUEST });
export const fetchServiceFeeTransactionSuccess = transaction => ({
  type: FETCH_SERVICE_FEE_TRANSACTION_SUCCESS,
  payload: { ...transaction },
});

export const fetchServiceFeeTransactionError = e => ({
  type: FETCH_SERVICE_FEE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const updateTransactionRequest = () => ({ type: UPDATE_TRANSACTION_REQUEST });
export const updateTransactionSuccess = transaction => ({
  type: UPDATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const updateTransactionError = e => ({
  type: UPDATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId, prolongationObj = {}) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  // If we already have a transaction ID, we should transition, not
  // initiate.

  const handleSuccess = response => {
    const order = response.data.data;
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  const { invoice, customerType } = orderParams.protectedData;

  const invoiceInMetaMaybe = invoice ? { invoice } : {};
  const customerTypeMaybe = customerType ? { customerType } : {};

  const createTransactionParams =
    {
      firstName: orderParams.protectedData.customerFirstName,
      lastName: orderParams.protectedData.customerLastName,
      receiverName: orderParams.protectedData.receiver,
      phone: orderParams.protectedData.customerPhoneNumber,
      start: orderParams.bookingStart,
      end: orderParams.bookingEnd,
      providerId: orderParams.providerId,
      meta: {
        ...customerTypeMaybe,
        ...invoiceInMetaMaybe
      },
      listings: orderParams.listings.map(l => (
        {
          id: l.listing.id.uuid,
          seats: l.attributes.amount
        }
      )
      )
    }

  return sdk.newSdk.transactions
    .create(createTransactionParams)
    .then(handleSuccess)
    .catch(handleError);
};

export const confirmPayment = orderParams => async (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId.uuid,
  };

  try {
    const response = await sdk.newSdk.transactions.confirmPayment({ ...bodyParams, include: ['listings', 'provider'] });
    const order = response.data.data;
    dispatch(confirmPaymentSuccess(order.id));
    return order;
  } catch (e) {
    dispatch(confirmPaymentError(storableError(e)));
    const transactionIdMaybe = orderParams.transactionId
      ? { transactionId: orderParams.transactionId.uuid }
      : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  }
};

export const confirmP24Payment = orderParams => async (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId.uuid,
  };

  try {
    const response = await sdk.newSdk.transactions.confirmPayment({ ...bodyParams, include: ['listings', 'provider'] });
    const order = response.data.data;
    dispatch(addMarketplaceEntities(response))
    dispatch(confirmPaymentSuccess(order.id));
    return response;
  } catch (e) {
    dispatch(confirmPaymentError(storableError(e)));
    const transactionIdMaybe = orderParams.transactionId
      ? { transactionId: orderParams.transactionId.uuid }
      : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  }
};

/**
 * Initiate the speculative transaction with the given booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPageServiceFee_speculative_card_token',
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
    return response
  };

  const handleError = e => {
    const { listings, bookingStart, bookingEnd } = params;

    const cartListingId = listings.map(({ id }) => (id.uuid));

    console.error(e, 'speculate-transaction-failed', {
      cartListingId,
      bookingStart,
      bookingEnd,
    });

    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (orderParams.prolongFor && orderParams.end) {
    return sdk.newSdk.transactions
      .create({
        emulate: true,
        prolongFor: orderParams.prolongFor,
        end: orderParams.end,
        voucherCode: orderParams.voucherCode,
        include: ['listings', 'provider', 'listings.images']
      })
      .then(handleSuccess)
      .catch(handleError);
  }

  return sdk.newSdk.transactions
    .create({
      ...EMULATED_TRANSACTION,
      start: orderParams.bookingStart.toISOString().substring(0, 10),
      end: orderParams.bookingEnd.toISOString().substring(0, 10),
      providerId: orderParams.providerId,
      voucherCode: orderParams.voucherCode,
      listings: orderParams.listings.map(l => (
        {
          id: l.listing.id.uuid,
          seats: l.attributes.amount
        }
      )
      )
    })
    .then(handleSuccess)
    .catch(handleError);
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser())
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    return sdk.newSdk.currentUser
      .update({ ...actionPayload })
  };
};

export const fetchServiceFee = (id) => async (dispatch, getState, sdk) => {
  dispatch(fetchTransactionRequest());

  return sdk.newSdk.transactions.show({ id, include: [
      'provider',
      'listings',
      'deliveryToProvider',
      'listings.images',
      'relatedTransactions.listings.images',
    ]})
    .then(res => {
      dispatch(fetchServiceFeeTransactionSuccess(res))
      return res;
    })
    .catch(e => {
      dispatch(fetchServiceFeeTransactionError(storableError(e)));
    });
}

export const updateTransaction = (date) => async (dispatch, getState, sdk) => {
  dispatch(updateTransactionRequest());

  return sdk.newSdk.transactions.update(date)
    .then(res => {
      dispatch(addMarketplaceEntities(res));
      dispatch(updateTransactionSuccess(res.data.data))
      return res;
    })
    .catch(e => {
      dispatch(updateTransactionError(storableError(e)));
    });
}


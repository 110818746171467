import React from 'react';
import { oneOf, shape, string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { NamedLink } from '../../../components';
import { typesOfServiceFee } from '../../../util/transaction';

import css from './ServiceFeeItem.css';

const ServiceFeeItem = ({
  state,
  createdAt,
  firstName,
  lastName,
  providerName,
  providerCountryId,
  id,
  classNameState,
  serviceFee
}) => {

  const typeOfServiceFeeLabel = typesOfServiceFee.find(({value}) => value === serviceFee.code).label;

  return (
    <li className={css.listItem} key={id}>
      <div className={css.transactionDetails}>
        <div className={css.firstTransactionDetails}>
          <p>
            {firstName} {lastName}
          </p>
          <FormattedMessage id={typeOfServiceFeeLabel} />
          <p className={css.providerName}>
            {providerName} <em className={css.providerCountry}>({providerCountryId})</em>
          </p>
        </div>
        <div className={css.secondTransactionDetails}>
          <p className={classNameState}>{state}</p>
          <p className={css.date}>
            <FormattedMessage id="Console.createdAt" />
            <span> {createdAt}</span>
          </p>
        </div>
      </div>
      <NamedLink className={css.link} name="ServiceFeeDetailsPage" params={{ id }}>
        <FormattedMessage id="Console.serviceFeeDetails" />
      </NamedLink>
    </li>
  );
};

ServiceFeeItem.defaultProps = {
  id: null,
  state: null,
  createdAt: null,
  lastTransitionedAt: null,
  firstName: null,
  lastName: null,
  providerName: null,
  providerCountryId: null,
};

ServiceFeeItem.propTypes = {
  id: string.isRequired,
  state: string.isRequired,
  createdAt: string.isRequired,
  lastTransitionedAt: string,
  firstName: string.isRequired,
  lastName: string.isRequired,
  providerName: string.isRequired,
  providerCountryId: string.isRequired,
  serviceFee: shape({
    code: oneOf('cleaning', 'damage', 'partial-damage', 'late-return' ),
    reason: string,
  }).isRequired,
};

export default ServiceFeeItem;

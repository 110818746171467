import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';

import { intlShape } from '../../util/reactIntl';
import { txIsEnquired } from '../../util/transaction';
import { propTypes, DATE_TYPE_DATE } from '../../util/types';
import { BookingTimeInfo, } from '../../components';

import css from './InboxPage.css';
import moment from 'moment/moment';

const checkStatus = (tx) => {
  const { attributes: { processState, start, end } } = tx;
  const now = new Date();

  if (processState === 'accepted' || processState == 'expired') {
    return moment(start).isBefore(now, 'day');
  }
  if (processState === 'picked-up') {
    return moment(end).subtract(1, 'day').isBefore(now, 'day');
  }
  return false;
}

// Functional component as internal helper to print BookingTimeInfo if that is needed
const BookingInfoMaybe = props => {
  const { bookingClassName, isOrder, intl, tx, unitType, lineItems } = props;
  const isEnquiry = txIsEnquired(tx);

  if (isEnquiry) {
    return null;
  }
  // If you want to show the booking price after the booking time on InboxPage you can
  // add the price after the BookingTimeInfo component. You can get the price by uncommenting
  // sthe following lines:

  // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
  // const price = bookingPrice ? formatMoney(intl, bookingPrice) : null;

  // Remember to also add formatMoney function from 'util/currency.js' and add this after BookingTimeInfo:
  // <div className={css.itemPrice}>{price}</div>
  const displayDeliveryLocation = tx.attributes && tx.attributes.deliveryLocation;

  const productsWithActualSeats = lineItems.reduce((accumulator, item) => {
    if (item?.code === 'day') {
      const { listingId, reversal, seats } = item;
      accumulator[listingId] = accumulator[listingId] ? accumulator[listingId] : 0;
      accumulator[listingId] += (reversal ? -1 : 1) * seats;
    }
    return accumulator;
  }, {});

  const listingsWithSeats = tx.listings.map(listing => ({
    ...listing,
    seats: productsWithActualSeats[listing.id.uuid],
  }));
  const sortedListingsWithSeats = listingsWithSeats.sort((a, b) => b.seats - a.seats);

  return (
    <div className={classNames(css.bookingInfoWrapper, bookingClassName)}>
      <div className={css.productName}>
        {
          sortedListingsWithSeats.map((item) => {
            return (
              <div className={!item.seats && css.lineThrough}>
                {item.seats} {' x '} {item.attributes.title} {item.attributes && item.attributes.code ? `(${item.attributes.code})` : ''}
              </div>
            );
          })
        }
      </div>
      {displayDeliveryLocation && <div className={css.deliveryLocation}>{tx.attributes.deliveryLocation}</div>}
      {
        !isOrder && tx.attributes.deliveryType === 'store' &&
        <div className={css.centralStore}>
          {tx.deliveryToProvider
            && <div>
              {`${intl.formatMessage({ id: "InboxPage.productFromCentralStock" })}: `}{tx.deliveryToProvider.attributes.name}
            </div>
          }
          <div>
            {intl.formatMessage({ id:"InboxPage.transportStatus"})}{' '}
            <strong>{intl.formatMessage({ id: `DeliveryState.${tx.attributes.deliveryState}` })}</strong>
          </div>
        </div>
      }
      {
        !isEnquiry &&
        <BookingTimeInfo
          bookingClassName={bookingClassName}
          isOrder={isOrder}
          intl={intl}
          tx={tx}
          unitType={unitType}
          dateType={DATE_TYPE_DATE}
        />
      }
      {!isOrder && checkStatus(tx) && <div className={css.statusWarning}>{intl.formatMessage({
        id: 'InboxPage.toBeVerified',
      })}</div>}
    </div>
  );
};

BookingInfoMaybe.propTypes = {
  intl: intlShape.isRequired,
  isOrder: bool.isRequired,
  tx: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export default BookingInfoMaybe;

import React, { useState } from 'react';
import classNames from 'classnames';
import css from './BusinessTable.css';
import { FormattedMessage } from 'react-intl';

const displayDifference = (curr, prev, type = 'percentage') => {
  const delta = !prev && curr ? String.fromCharCode('0x221E') : (((curr / prev) - 1) * 100).toFixed();
  return (
    <span>
      <span className={classNames(css.arrow, { [css.positive]: delta > 0 || !prev, [css.negative]: delta < 0, [css.neutral]: delta == 0 })}/>
      {
        type === 'percentage' ?
          <span className={css.deltaValue}>{`${delta > 0 ? '+' : ''}${delta}%`}</span>
          :
          <span className={css.deltaValue}>{((curr - prev) / 100).toFixed()}</span>
      }
    </span>
  );
}

const BusinessTable = ({ rows, firstRowName, isComparisonOn, sortBy, dataHeaders, extendableRows, dates }) => {
  const [extendedRowLabel, setExtendedRowLabel] = useState(null);

  return (
    <div className={css.tableWrapper}>
      <table className={css.resultsTable}>
        <tr className={css.tableHeader}>
          <th className={css.textToLeft}>{firstRowName}</th>
          {
            dataHeaders.map(header => (
              <>
              <th>
                <div className={css.headerCellWrapper}>
                  <span className={css.columnName}>{header.primary}</span>
                  <span className={css.descName}>{header.secondary}</span>
                </div>
              </th>
              {
                isComparisonOn &&
                  <th>
                    &Delta;
                  </th>
              }
              </>
            ))
          }
        </tr>
          {
            rows
              .sort((a, b) => {
                if (typeof a.data[sortBy] === 'object' && a.data[sortBy] !== null) {
                  return b.data[sortBy].current - a.data[sortBy].current;
                }
                return b.data[sortBy] - a.data[sortBy];
              })
              .map(store => {
                const isExtended = store.label && store.label === extendedRowLabel;

                  return (
                    <>
                      <tr className={classNames(css.row, {[css.extended]: isExtended})}>
                        <td className={css.textToLeft}>{store.label}</td>
                        {
                          store.data.map(field => (
                            <>
                              <td className={css.stat}>{typeof field === 'object' && field !== null ? field.current : field}</td>
                              {isComparisonOn && <td>{displayDifference(field.current, field.prev)}</td>}
                              </>
                          ))
                        }
                        {extendableRows && store.label && <td onClick={()=>setExtendedRowLabel(store.label)} className={css.extend}>.</td>}
                      </tr>
                    </>
                  )
                })
          }
      </table>
    </div>
  )
};

BusinessTable.defaultProps = {
  firstRowName: <FormattedMessage id={'BusinessResults.providerField'} />,
  sortBy: 0,
  extendableRows: true
}

export default BusinessTable;
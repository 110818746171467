import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import {
  LINE_ITEM_DAY,
  LINE_ITEM_LONG_TERM_DISCOUNT,
  LINE_ITEM_PROMO_DISCOUNT, LINE_ITEM_SERVICE_FEE,
} from '../../../util/types';
import { intlShape } from '../../../util/reactIntl';
import ServiceFeeItem from './ServiceFeeItem';
import ListingsItem from './ListingsItem/ListingsItem';

const OriginalOrderItems = ({ confirmLineItems, listings, intl }) => {
    const serviceFeeLineItem = confirmLineItems.filter(item => item?.code === LINE_ITEM_SERVICE_FEE);

    if (serviceFeeLineItem.length) {
      return (
        <ServiceFeeItem
          serviceFeeLineItem={serviceFeeLineItem}
          intl={intl}
          idMessage="OrderInfo.subTotal"
        />
      );
    }

    const originProducts = confirmLineItems.filter(item => item?.code === LINE_ITEM_DAY);
    const originDiscount = confirmLineItems.find(
      item => item?.code === LINE_ITEM_LONG_TERM_DISCOUNT,
    );
    const originPromoDiscount = confirmLineItems.find(
      item => item?.code === LINE_ITEM_PROMO_DISCOUNT,
    );

    const sum = originProducts.reduce(
      (sum, currentValue) => sum + currentValue.lineTotal.amount,
      0
    );
    const currency = originProducts[0].lineTotal.currency;
    const subTotalWithDiscount = originDiscount ? sum + originDiscount.lineTotal.amount : sum;
    const amountSubTotal = originPromoDiscount
      ? subTotalWithDiscount + originPromoDiscount.lineTotal.amount
      : subTotalWithDiscount;

    const subTotal = { amount: amountSubTotal, currency };

    return (
      <ListingsItem
        key="confirm"
        products={originProducts}
        listings={listings}
        intl={intl}
        longTermDiscount={originDiscount}
        promoDiscount={originPromoDiscount}
        money={subTotal}
        idMessage="OrderInfo.subTotal"
      />
    );
}

OriginalOrderItems.propTypes = {
  intl: intlShape.isRequired,
  listings: arrayOf(shape({
    id: shape({ uuid: string.isRequired}),
    attributes: shape({
      title: string.isRequired,
    })
  })),
  confirmLineItems: arrayOf(
    shape({
      code: oneOf(['day', 'service-fee', 'long-term-discount', 'promo-discount', 'provider-commission']),
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
    })
  )};

export default OriginalOrderItems;

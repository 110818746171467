/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf } from 'prop-types';
import moment from 'moment';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { daysBetween } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { formatMoney } from '../../util/currency';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  Page,
  NamedLink,
  NamedRedirect,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  BookingPanel,
  IconChevronRight,
  CartModal
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

import { sendEnquiry, loadData, setInitialValues, getComplementaryProducts } from './ListingPage.duck';
import SectionImages from './SectionImages';
import SectionHeading from './SectionHeading';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionFeaturesMaybe from './SectionFeaturesMaybe';
import SectionReviews from './SectionReviews';
import SectionHostMaybe from './SectionHostMaybe';
import SectionRulesMaybe from './SectionRulesMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import css from './ListingPage.css';

import checkoutEvent from './checkoutEvent';
import viewProductEvent from './viewProductEvent';
import { addToCart } from '../CartPage/CartPage.duck';
import { viewItemEvent } from './viewItemEvent';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

const complementaryCategories = [
  {
    businessCategory: 'camping_tent',
    complementaryBusinessCategory: 'camping_accessories',
    keywords: undefined,
  },
  {
    businessCategory: 'camping_accessories',
    complementaryBusinessCategory: 'camping_accessories,camping_tent',
    keywords: undefined,
  },
  {
    businessCategory: 'watersports_sup',
    complementaryBusinessCategory: 'watersports_accessories,watersports_sup',
    keywords:  undefined,
  },
  {
    businessCategory: 'watersports_kayak',
    complementaryBusinessCategory: 'watersports_accessories,watersports_kayak',
    keywords: undefined,
  },
  {
    businessCategory: 'watersports_accessories',
    complementaryBusinessCategory: 'watersports_accessories',
    keywords: undefined,
  },
  {
    businessCategory: 'bikes_electric',
    complementaryBusinessCategory: 'bikes_electric,bikes_mountain',
    keywords: undefined,
  },
  {
    businessCategory: 'bikes_mountain',
    complementaryBusinessCategory: 'bikes_mountain,bikes_electric',
    keywords: undefined,
  },
  {
    businessCategory: 'bikes_city',
    complementaryBusinessCategory: 'bikes_city,bikes_mountain',
    keywords: undefined,
  },
  {
    businessCategory: 'wintersports_alpineskiing',
    complementaryBusinessCategory: 'wintersports_aplineskiing',
    keywords: undefined,
  },
  {
    businessCategory: 'tourism_crampons',
    complementaryBusinessCategory: 'tourism_crampons,tourism_axe',
    keywords: undefined,
  },
  {
    businessCategory: 'wintersports_snowboard',
    complementaryBusinessCategory: 'wintersports_snowboard',
    keywords: undefined
  },
  {
    businessCategory: 'wintersports_crosscountry',
    complementaryBusinessCategory: 'wintersports_crosscountry',
    keywords: undefined,
  },
  {
    businessCategory: 'wintersports_skitouring',
    complementaryBusinessCategory: 'wintersports_skitouring',
    keywords: undefined
  },
  {
    businessCategory: 'tourism_axe',
    complementaryBusinessCategory: 'tourism_crampons,tourism_axe',
    keywords: undefined
  },
  {
    businessCategory: 'bikes_city',
    complementaryBusinessCategory: 'bikes_city,bikes_mountain',
    keywords: undefined,
  },
];

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const categoryLabel = (categories, key) => {
  const cat = categories.find(c => c.key === key);
  return cat ? cat.label : key;
};

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      isCartModalOpen: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  componentDidMount() {
    // only works server side as GA tracking
    const {
      params, getListing, getOwnListing
    } = this.props;

    const listingId = new UUID(params.id);
    const isPendingApprovalVariant = params.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = params.variant === LISTING_PAGE_DRAFT_VARIANT;
    const listing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    listing.id && viewItemEvent({
      currency: listing.attributes.price.currency,
      value: listing.attributes.price.amount / 100,
      item: {
        price: listing.attributes.price.amount / 100,
        id: listing.id.uuid,
        name: listing.attributes.title,
        category: listing.attributes.businessCategory,
        provider: listing.provider?.attributes.name,
        // TO-DO Remember when adding quantity field
        quantity: 1
      }
    });

    listing.id && viewProductEvent({
      name: listing.attributes.title,
      productId: listing.id.uuid,
      price: listing.attributes.price.amount / 100,
      category: listing.attributes ? listing.attributes.category : '',
      brand: listing.provider?.attributes.name,
    })
  }

  handleSubmit(values) {
    const {
      history,
      getListing,
      params,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates, ...bookingData } = values;

    const initialValues = {
      listing,
      bookingData,
      bookingDates: {
        bookingStart: bookingDates.startDate,
        bookingEnd: bookingDates.endDate,
      },
      confirmPaymentError: null,
    };

    const routes = routeConfiguration();
    // Customize checkout page state with current listing and selected bookingDates
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetInitialValues(setInitialValues, initialValues);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  }

  handleAddToCart = values => {
    const { onAddToCart, getListing, params, getComplementaryProducts } = this.props;
    const listingId = new UUID(params.id);
    const listing = getListing(listingId);

    const { bookingDates } = values;
    const start = moment(bookingDates.startDate).format('YYYY-MM-DD');
    const end = moment(bookingDates.endDate).format('YYYY-MM-DD');

    const businessCategoryOfListing = listing.attributes.businessCategory;
    const providerId = listing.provider.id.uuid;

    const complementaryBusinessCategory = complementaryCategories.find(
      ({ businessCategory }) => businessCategory === businessCategoryOfListing
    );

    complementaryBusinessCategory &&
      getComplementaryProducts({
        start,
        end,
        providerId,
        businessCategory: complementaryBusinessCategory.complementaryBusinessCategory,
        keywords: complementaryBusinessCategory.keywords,
      });

    onAddToCart({
      listingId: listing.id.uuid,
      amount: values.quantity,
      start,
      end: moment(end).add(1, 'days').format('YYYY-MM-DD'),
    }).then(() =>
      this.setState({
        isCartModalOpen: true,
        bookingDatesStart: start,
        bookingDatesEnd: end,
        amount: values.quantity,
      })
    );
  };

  onContactUser() {
    const { currentUser, history, callSetInitialValues, params, location } = this.props;

    if (!currentUser) {
      const state = { from: `${location.pathname}${location.search}${location.hash}` };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, { enquiryModalOpenForListingId: params.id });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}), state);
    } else {
      this.setState({ enquiryModalOpen: true });
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });

        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  render() {
    const {
      unitType,
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      history,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      location,
      scrollingDisabled,
      showListingError,
      reviews,
      fetchReviewsError,
      sendEnquiryInProgress,
      sendEnquiryError,
      timeSlots,
      fetchTimeSlotsError,
      filterConfig,
      promo,
      complementaryProducts,
      onAddToCart,
      addToCartInProgress,
      cartListing,
      addToCartSuccess,
      fetchListingInProgress
,    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
    const params = { slug: listingSlug, ...rawParams };

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const isApproved =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

    const pendingIsApproved = isPendingApprovalVariant && isApproved;

    // If a /pending-approval URL is shared, the UI requires
    // authentication and attempts to fetch the listing from own
    // listings. This will fail with 403 Forbidden if the author is
    // another user. We use this information to try to fetch the
    // public listing.
    const pendingOtherUsersListing =
      (isPendingApprovalVariant || isDraftVariant) &&
      showListingError &&
      showListingError.status === 403;
    const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

    if (shouldShowPublicListingPage) {
      return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
    }

    const {
      description = '',
      price = null,
      title = '',
      externalLink,
      category
    } = currentListing.attributes;

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const bookingTitle = (
      <FormattedMessage id="ListingPage.bookingTitle" values={{ title }} />
    );
    const bookingSubTitle = intl.formatMessage({ id: 'ListingPage.bookingSubTitle' });

    const topbar = <TopbarContainer />;

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <Footer />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = e => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.provider;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing = currentListing.provider?.attributes.myRole ||
      (userAndListingAuthorAvailable && currentListing.provider.id.uuid === currentUser.id.uuid);

    const currentAuthor = authorAvailable ? currentListing.provider : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const showContactUser = authorAvailable && (!currentUser || (currentUser && !isOwnListing)) && ensuredAuthor.attributes && !!ensuredAuthor.attributes.isDecathlon;

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, ensuredAuthor.attributes.name);

    const { formattedPrice, priceTitle } = priceData(price, intl);

    const handleBookingSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (isOwnListing || isCurrentlyClosed) {
        window.scrollTo(0, 0);
      } else {
        checkoutEvent({
          name: currentListing.attributes.title,
          productId: currentListing.id.uuid,
          price: currentListing.attributes.price.amount / 100,
          category: currentListing.attributes.category,
          brand: currentListing.provider.attributes.name,
          days: daysBetween(values.bookingDates.startDate, values.bookingDates.endDate)
        });
        this.handleSubmit(values);
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'scaled-small');
    const twitterImages = listingImages(currentListing, 'scaled-small');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
      { id: 'ListingPage.schemaTitle' },
      { title, price: formattedPrice, siteTitle }
    );

    const handleBack = () => {
      if (history.length > 2) {
        history.goBack();
      } else {
        history.push(`/s?address=Polska&bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954&pub_category=${currentListing.attributes.category}`)
      }
    }

    const hostLink = (
      <NamedLink
        className={css.authorNameLink}
        name="ListingPage"
        params={params}
        to={{ hash: '#host' }}
      >
        {authorDisplayName}
      </NamedLink>
    );

    const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
    const categoryDisplay =
      category ? (
        <span>
          {categoryLabel(categoryOptions, category)}
          <span className={css.separator}>•</span>
        </span>
      ) : null;

      const complementaryBusinessCategory = complementaryCategories.find(
        ({ businessCategory }) => businessCategory === currentListing.attributes.businessCategory
      );

    const rating = reviews.map(x => x.attributes.rating).reduce((previous, current) => current += previous, 0) / reviews.length;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={`${intl.formatMessage({id:  "ListingPage.rentAt"})} ${authorDisplayName} ${description}`}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'Product',
          productId: listingId.uuid,
          description: `${intl.formatMessage({id:  "ListingPage.rentAt"})} ${authorDisplayName} ${description}`,
          name: schemaTitle,
          image: schemaImages,
          brand: authorDisplayName,
          google_product_category: '988',
          offers: [{
            '@type': 'Offer',
            price: price.amount / 100,
            priceCurrency: 'PLN',
            availability: "https://schema.org/InStock"
          }]
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div className={css.topButtonsContainer}>
                <button className={css.backButton} onClick={handleBack}>
                  <IconChevronRight className={css.chevronLeft} />
                  <FormattedMessage id="ListingPage.backButton" />
                </button>
              </div>
              <div className={css.content}>
                <div className={css.contentWraper}>
                  <div className={css.sectionWithBackground}>
                    <div className={css.contentContainer}>
                      <SectionHeading
                        priceTitle={priceTitle}
                        formattedPrice={formattedPrice}
                        richTitle={richTitle}
                        category={categoryDisplay}
                        hostLink={hostLink}
                        showContactUser={showContactUser}
                        onContactUser={this.onContactUser}
                        rating={rating}
                        reviewsCount={reviews.length}
                        listing={currentListing}
                        isOwnListing={isOwnListing}
                        editParams={{
                          id: listingId.uuid,
                          slug: listingSlug,
                          type: listingType,
                          tab: listingTab,
                        }}
                      />
                      <SectionImages
                        title={title}
                        listing={currentListing}
                        isOwnListing={isOwnListing}
                        editParams={{
                          id: listingId.uuid,
                          slug: listingSlug,
                          type: listingType,
                          tab: listingTab,
                        }}
                        imageCarouselOpen={this.state.imageCarouselOpen}
                        onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                        handleViewPhotosClick={handleViewPhotosClick}
                        onManageDisableScrolling={onManageDisableScrolling}
                        intl={intl}
                      />
                    </div>
                  </div>
                  <div className={css.contentContainer}>
                    <SectionFeaturesMaybe />
                    <SectionDescriptionMaybe
                      description={description}
                      externalLink={externalLink}
                      isDecathlon={ensuredAuthor.attributes.isDecathlon}
                    />
                    <SectionRulesMaybe
                      authorPublicData={ensuredAuthor.attributes}
                      listingPublicData={currentListing.attributes}
                      isDecathlon={ensuredAuthor.attributes.isDecathlon}
                      intl={intl}
                    />
                    <SectionMapMaybe
                      geolocation={currentListing.attributes.locations ? currentListing.attributes.locations[0] : null}
                      publicData={{}}
                      listingId={currentListing.id}
                      listingPublicData={{}}
                      listing={currentListing}
                    />
                    <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} rating={rating} />
                    <SectionHostMaybe
                      title={title}
                      listing={currentListing}
                      authorDisplayName={authorDisplayName}
                      showContactUser={showContactUser}
                      onContactUser={this.onContactUser}
                      isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                      onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                      sendEnquiryError={sendEnquiryError}
                      sendEnquiryInProgress={sendEnquiryInProgress}
                      onSubmitEnquiry={this.onSubmitEnquiry}
                      currentUser={currentUser}
                      onManageDisableScrolling={onManageDisableScrolling}
                    />
                  </div>
                </div>

                <BookingPanel
                  className={css.bookingPanel}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  unitType={unitType}
                  onSubmit={handleBookingSubmit}
                  onAddToCart={this.handleAddToCart}
                  title={bookingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  promo={promo}
                  addToCartSuccess={addToCartSuccess}
                />
              </div>
              <CartModal
                id="CartModal"
                isOpen={this.state.isCartModalOpen}
                onCloseModal={() => this.setState({ isCartModalOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                listing={currentListing}
                bookingDatesStart={this.state.bookingDatesStart}
                bookingDatesEnd={moment(this.state.bookingDatesEnd).add(1, 'days').format('YYYY-MM-DD')}
                amount={this.state.amount}
                complementaryProducts={complementaryProducts}
                onAddToCart={onAddToCart}
                rawParams={rawParams}
                history={history}
                addToCartInProgress={addToCartInProgress}
                cartListing={cartListing}
                complementaryBusinessCategory={complementaryBusinessCategory}
                fetchListingInProgress={fetchListingInProgress}
              />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter className={css.footer}>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
  filterConfig: config.custom.filters,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  filterConfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  // Topbar needs user info.
  const { isPromoOn, promoCode, discount, discountedCategories } = state.Promo;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
    complementaryListing,
    fetchListingInProgress
  } = state.ListingPage;
  const { currentUser } = state.user;

  const {addToCartInProgress, addToCartError, addToCartSuccess, cart} = state.CartPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    promo: { isPromoOn, promoCode, discount, discountedCategories },
    complementaryProducts: getMarketplaceEntities(state, complementaryListing),
    addToCartInProgress,
    addToCartSuccess,
    cartListing: getMarketplaceEntities(state, cart),
    fetchListingInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onAddToCart: params => dispatch(addToCart(params)),
  getComplementaryProducts: data => dispatch(getComplementaryProducts(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;

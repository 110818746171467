import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import {
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureStripeCustomer,
  ensurePaymentMethodCard,
} from '../../util/data';
import { minutesBetween } from '../../util/dates';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { txIsPaymentPending, txIsPaymentExpired } from '../../util/transaction';
import { clearData } from '../../util/checkoutSessionHelpers';
import {
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ServiceFeeSummary,
  ServiceFeeItem,
  WarningAcceptTerms
} from '../../components';
import { StripePaymentFormServiceFee } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  handleCardPayment,
  handleP24Payment,
  retrievePaymentIntent,
} from '../../ducks/stripe.duck';
import { savePaymentMethod } from '../../ducks/paymentMethods.duck';

import {
  initiateOrder,
  setInitialValues,
  stripeCustomer,
  confirmPayment,
  confirmP24Payment,
  updateProfile,
  fetchServiceFee,
  updateTransaction,
  fetchServiceFeeTransactionError,
  speculateTransaction,
} from './CheckoutPageServiceFee.duck';
import { getFormattedServiceFeeResponse } from './ServiceFeeHelpers';

import css from './CheckoutPageServcieFee.css';

const STORAGE_KEY = 'CheckoutPageServiceFee';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = ['processing', 'requires_capture', 'succeeded'];

// Payment charge options
const ONETIME_PAYMENT = 'ONETIME_PAYMENT';
const PAY_AND_SAVE_FOR_LATER_USE = 'PAY_AND_SAVE_FOR_LATER_USE';
const USE_SAVED_CARD = 'USE_SAVED_CARD';

const paymentFlow = (selectedPaymentMethod, saveAfterOnetimePayment) => {
  // Payment mode could be 'replaceCard', but without explicit saveAfterOnetimePayment flag,
  // we'll handle it as one-time payment
  return selectedPaymentMethod === 'defaultCard'
    ? USE_SAVED_CARD
    : saveAfterOnetimePayment
      ? PAY_AND_SAVE_FOR_LATER_USE
      : ONETIME_PAYMENT;
};

const checkIsPaymentExpired = existingTransaction => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
      ? minutesBetween(existingTransaction.attributes.lastTransitionedAt, new Date()) >= 15
      : false;
};

export class CheckoutPageServiceFeeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      listing,
      fetchServiceFeeTransaction
    } = this.props;

    return fetchServiceFeeTransaction( this.props.match.params.feeId ).then(res => {
        const {
          formattedResponse,
          originTransaction,
          bookingDatesOfServiceFee,
          listingsOfServiceFee,
          transactionIsPaid
        } = getFormattedServiceFeeResponse(res);

        this.setState({
          dataLoaded: true,
          pageData: {
            bookingData,
            bookingDates: !transactionIsPaid && bookingDatesOfServiceFee,
            listing,
            transaction: formattedResponse,
            listings: !transactionIsPaid && listingsOfServiceFee,
            provider:  !transactionIsPaid && originTransaction &&  formattedResponse.provider,
            totalNumberOfOrders: listingsOfServiceFee?.length
          }
        })
      })
  }

  handlePaymentIntent(handlePaymentParams) {
    const {
      currentUser,
      stripeCustomerFetched,
      onInitiateOrder,
      onUpdateProfile,
    } = this.props;

    const {
      bookingAdditionalData,
      pageData,
      speculatedTransaction,
      selectedPaymentMethod,
      saveAfterOnetimePayment,
    } = handlePaymentParams;

    const storedTx = ensureTransaction(pageData.transaction);
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const ensuredStripeCustomer = ensureStripeCustomer(ensuredCurrentUser.stripeCustomer);
    const ensuredDefaultPaymentMethod = ensurePaymentMethodCard(
      ensuredStripeCustomer.defaultPaymentMethod
    );

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensuredStripeCustomer.attributes.stripeCustomerId &&
      ensuredDefaultPaymentMethod.id
    );
    const stripePaymentMethodId = hasDefaultPaymentMethod
      ? ensuredDefaultPaymentMethod.attributes.stripePaymentMethodId
      : null;

    const selectedPaymentFlow = paymentFlow(selectedPaymentMethod, saveAfterOnetimePayment);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = fnParams => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents = storedTx.attributes && storedTx.attributes.stripePaymentIntent;
      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents ? Promise.resolve(storedTx) : onInitiateOrder(fnParams, storedTx.id);
    };

    const fnSaveInvoiceDetails = fnParams => {
      const { bookingAdditionalData } = handlePaymentParams;
      if (bookingAdditionalData.saveInvoiceDetails) {
        const updatedUserParams = {
          defaultTransactionMeta: {
            ...bookingAdditionalData.invoice,
            customerType: bookingAdditionalData.customerType,
          },
        };
        return onUpdateProfile(updatedUserParams).then(res => {
          return { ...fnParams };
        });
      } else {
        return Promise.resolve({ ...fnParams });
      }
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => x => funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnSaveInvoiceDetails
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    // Note: optionalPaymentParams contains Stripe paymentMethod,
    // but that can also be passed on Step 2
    // stripe.handleCardPayment(stripe, { payment_method: stripePaymentMethodId })
    const optionalPaymentParams =
      selectedPaymentFlow === USE_SAVED_CARD && hasDefaultPaymentMethod
        ? { paymentMethod: stripePaymentMethodId }
        : selectedPaymentFlow === PAY_AND_SAVE_FOR_LATER_USE
          ? { setupPaymentMethodForSaving: true }
          : {};

    const orderParams = {
      listings: pageData.listings,
      bookingStart: tx.attributes.start,
      bookingEnd: tx.attributes.end,
      voucherCode: bookingAdditionalData.voucherCode,
      ...optionalPaymentParams,
      protectedData: bookingAdditionalData,
      selectedPaymentMethod,
      providerId: pageData.provider.id.uuid,
    };

    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(values) {
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const { history, speculatedTransaction, currentUser, paymentIntent, onUpdateTransaction, transaction } = this.props;
    const { formValues } = values;

    const {
      name,
      customerFirstName,
      customerLastName,
      customerName,
      customerEmail,
      customerPhoneNumber,
      receiver,
      deliveryLocation,
      checkoutFields,
      customerType,
      delivery,
      saveInvoiceDetails,
    } = formValues;

    const invoiceObj = {
      customerType,
      invoice: checkoutFields.invoice,
    }
    const invoiceMeta = this.state.pageData.transaction.attributes.meta;
    const isEqualCustomerType = JSON.stringify(invoiceObj.customerType) === JSON.stringify(invoiceMeta.customerType);
    const isEqualInvoice = JSON.stringify(invoiceObj.invoice) === JSON.stringify(invoiceMeta.invoice);
    const isUpdateInvoice = !(isEqualCustomerType && isEqualInvoice);

    isUpdateInvoice && onUpdateTransaction({
      id: this.state.pageData.transaction.id.uuid,
      meta: invoiceObj,
    });

    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
    };

    const invoiceMaybe =
      checkoutFields && checkoutFields.invoice ? { invoice: checkoutFields.invoice } : {};

    const { invoice, ...rest } = checkoutFields || {};
    const deliveryOption =
      delivery === 'home'
        ? { deliveryToHomeAddress: rest }
        : delivery === 'store'
          ? { deliveryToProviderId: deliveryLocation }
          : {};

    const requestPaymentParams = {
      bookingAdditionalData: {
        customerFirstName,
        customerLastName,
        customerName,
        customerEmail,
        customerPhoneNumber,
        receiver,
        deliveryLocation,
        customerType,
        saveInvoiceDetails,
        ...invoiceMaybe,
        ...deliveryOption,
      },
      pageData: this.state.pageData,
      speculatedTransaction,
      billingDetails,
      paymentIntent
    };

    return this.handlePaymentIntent(requestPaymentParams)
      .then(res => {
        const { id } = res;
        this.setState({ submitting: false });
        const routes = routeConfiguration();
        const paymentPath = pathByRouteName('PaymentPage', routes, {
          transactionId: id.uuid,
        });

        clearData(STORAGE_KEY);

        history.push(paymentPath);
      })
      .catch(err => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const {
      history,
      paymentIntent,
      onRetrievePaymentIntent,
      onConfirmPayment,
      onConfirmP24Payment,
    } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret }).then(res => {
        if (res.paymentIntent && res.paymentIntent.status === 'succeeded') {
          const confirmFn =
            tx.attributes.lastTransition === 'transition/request-push-payment'
              ? onConfirmP24Payment
              : onConfirmPayment;
          confirmFn({
            paymentIntent: res.paymentIntent,
            transactionId: tx.id,
            transactionProtectedData: tx.attributes.protectedData,
          }).then(res => {
            const routes = routeConfiguration();
            const orderDetailsPath = pathByRouteName('OrderDetailsSuccessPage', routes, {
              id: tx.id.uuid,
            });
            history.push(orderDetailsPath);
          });
        }
      });
    }
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      currentUser,
      handleCardPaymentError,
      paymentIntent,
      stripeCustomerFetched,
      updateTransactionInProgress,
      updateTransactionError,
    } = this.props;

    if (!this.state.dataLoaded) {
      return <div>Loading...</div>;
    }

    const isLoading =
      !this.state.dataLoaded || speculateTransactionInProgress;
    const { transaction, listings, provider } = this.state.pageData;
    const redirectToCart = !listings?.length && !provider;

    if (redirectToCart) {
      return <NamedRedirect name="ServiceFeeDetailsPage" params={{ id: this.props.match.params.feeId }} />
    };

    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(speculatedTransactionMaybe);
    const currentAuthor = provider;
    const title = intl.formatMessage({ id: 'CheckoutPage.title' });
    const currentAuthorDisplayName = currentAuthor?.attributes.name;
    const warnings = speculatedTransaction && speculatedTransaction.attributes.warnings;
    const noTermsConfirmed = warnings
      ? warnings.find(warning => warning.error === 'No terms confirmed')
      : null;
    const pageProps = { title, scrollingDisabled, className: css.page };

    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: "CheckoutPage.goToLandingPage" })}
            format="mobile"
          />
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: "CheckoutPage.goToLandingPage" })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    if (isLoading) {
      return <Page {...pageProps}>{topbar}</Page>;
    }

    const tx =
      existingTransaction && existingTransaction.id
        ? existingTransaction
        : speculatedTransaction;

    const hasDefaultPaymentMethod = !!(
      stripeCustomerFetched &&
      ensureStripeCustomer(currentUser.stripeCustomer).attributes.stripeCustomerId &&
      ensurePaymentMethodCard(currentUser.stripeCustomer.defaultPaymentMethod).id
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(initiateOrderError);
    const isChargeDisabledError = isTransactionChargeDisabledError(initiateOrderError);

    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );

    const stripeErrors = transactionInitiateOrderStripeErrors(initiateOrderError);

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    }

    const speculateTransactionErrorMessage = (
      <p className={css.speculateError}>
        {speculateTransactionError?.message === 'No terms confirmed' ? (
          <FormattedMessage id="CheckoutPage.noTermsConfirmed" />
        ) : (
          <FormattedMessage id="CheckoutPage.speculateTransactionError" />
        )}
      </p>
    );

    let speculateErrorMessage = null;

    if (isTransactionInitiateMissingStripeAccountError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (isTransactionInitiateBookingTimeNotAvailableError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          {speculateTransactionError?.message === 'No terms confirmed' ? (
            <span>
              <FormattedMessage id="CheckoutPage.noTermsConfirmed" />
              <NamedLink
                className={css.linkToProfile}
                name="ProfileSettingsPage"
              >
                <FormattedMessage id="CheckoutPage.here" />
              </NamedLink>
            </span>
          ) : (
            <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
          )}
        </p>
      );
    }

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.firstName} ${currentUser.attributes.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent && STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}
    const { email, phoneNumber, defaultTransactionMeta } = ensureUser(currentUser).attributes;

    const isCreatedInvoice = currentAuthor.attributes.countryId === 'hu' ||
      (currentAuthor.attributes.countryId === 'pl' && currentAuthor.attributes.isDecathlon);

    const customerTypeMaybe = Object.keys(transaction.attributes.meta).length
      ? { customerType: transaction.attributes.meta.customerType }
      : { customerType: currentUser.attributes.defaultTransactionMeta.customerType };

    const metaDate = Object.keys(transaction.attributes.meta).length
      ? transaction.attributes.meta.invoice
      : currentUser.attributes.defaultTransactionMeta;

    const serviceFeeMetaMaybe = isCreatedInvoice
      ? {checkoutFields: {
          invoice: {
            ...metaDate,
          },
        }}
      : {};

    const initalValuesForStripePayment = {
      name: userName,
      receiver: userName,
      customerName: userName,
      customerFirstName: currentUser.attributes.firstName || null,
      customerLastName: currentUser.attributes.lastName || null,
      customerEmail: email,
      // customerPhoneNumber: phoneNumber,
      // for hungarian invoices purposes
      ...customerTypeMaybe,
      ...serviceFeeMetaMaybe,
    };

    const {  payinTotal } = tx.attributes;
    const serviceFee = transaction && transaction.attributes.serviceFee;

    const serviceFeeFor = transaction?.relatedTransactions.filter(el => !el.attributes.serviceFee
      && el.attributes.processState !== 'pending-payment'
      && el.attributes.processState !== 'payment-expired' );

    const showSummary = displayClass => (
      <div className={classNames(displayClass, css.summaryContainer)}>
        <ServiceFeeSummary
          intl={intl}
          transaction={transaction}
          title="ServiceFeeSummary.title"
        />
      </div>
  );

    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.container}>
          {speculateTransactionError ? (
            speculateTransactionErrorMessage
          ) : (
            <>
              {noTermsConfirmed && <WarningAcceptTerms />}
              <div className={css.wrapper}>
                <div className={css.paymentDetailsContainer}>
                  <ServiceFeeItem
                    orders={listings}
                    intl={intl}
                    provider={provider}
                    className={css.serviceFeeContainer}
                    serviceFee={serviceFee}
                    payinTotal={payinTotal}
                    serviceFeeFor={serviceFeeFor}
                  />
                  <section className={css.paymentContainer}>
                    {initiateOrderErrorMessage}
                    {listingNotFoundErrorMessage}
                    {speculateErrorMessage}
                    {showSummary(css.mobileContainer)}
                    <StripePaymentFormServiceFee
                      className={css.paymentForm}
                      onSubmit={this.handleSubmit}
                      inProgress={this.state.submitting}
                      formId="CheckoutPagePaymentForm"
                      paymentInfo={intl.formatMessage({ id: 'CheckoutPage.paymentInfo' })}
                      authorDisplayName={currentAuthorDisplayName}
                      isAuthorSuperStore={false}
                      superStore={currentAuthor}
                      initialValues={initalValuesForStripePayment}
                      initiateOrderError={initiateOrderError}
                      handleCardPaymentError={handleCardPaymentError}
                      confirmPaymentError={confirmPaymentError}
                      hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                      loadingData={!stripeCustomerFetched}
                      defaultPaymentMethod={
                        hasDefaultPaymentMethod
                          ? currentUser.stripeCustomer.defaultPaymentMethod
                          : null
                      }
                      paymentIntent={paymentIntent}
                      onStripeInitialized={this.onStripeInitialized}
                      providerRules={currentAuthor.attributes.rentTerms}
                      listings={listings}
                      warnings={warnings}
                      updateTransactionInProgress={updateTransactionInProgress}
                      updateTransactionError={updateTransactionError}
                    />
                  </section>
                </div>
                {showSummary(css.desktopContainer)}
              </div>
            </>
          )}
        </div>
      </Page>
    );
  }
}

CheckoutPageServiceFeeComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

const mapStateToProps = state => {
  const {
    listing,
    listings,
    provider,
    bookingData,
    bookingDates,
    stripeCustomerFetched,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
    validatePromoInProgress,
    validatePromoInfo,
    validatePromoError,
    totalNumberOfOrders,
    updateTransactionInProgress,
    updateTransactionError,
  } = state.CheckoutPageServiceFee;
  const { currentUser } = state.user;
  const { handleCardPaymentError, paymentIntent, retrievePaymentIntentError } = state.stripe;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    stripeCustomerFetched,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    listings,
    provider,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
    validatePromoInProgress,
    validatePromoInfo,
    validatePromoError,
    totalNumberOfOrders,
    updateTransactionInProgress,
    updateTransactionError,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchSpeculatedTransaction: (params, validatingPromoCode) =>
    dispatch(speculateTransaction(params, validatingPromoCode)),
  fetchStripeCustomer: () => dispatch(stripeCustomer()),
  onInitiateOrder: (params, transactionId, prolongationObj) => dispatch(initiateOrder(params, transactionId, prolongationObj)),
  onRetrievePaymentIntent: params => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: params => dispatch(handleCardPayment(params)),
  onHandleP24Payment: params => dispatch(handleP24Payment(params)),
  onConfirmPayment: params => dispatch(confirmPayment(params)),
  onConfirmP24Payment: params => dispatch(confirmP24Payment(params)),
  onSavePaymentMethod: (stripeCustomer, stripePaymentMethodId) =>
    dispatch(savePaymentMethod(stripeCustomer, stripePaymentMethodId)),
  onUpdateProfile: params => dispatch(updateProfile(params)),
  fetchServiceFeeTransaction: (params) => dispatch(fetchServiceFee(params)),
  onUpdateTransaction: (data) => dispatch(updateTransaction(data))
});

const CheckoutPageServiceFee = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageServiceFeeComponent);

CheckoutPageServiceFee.setInitialValues = initialValues => setInitialValues(initialValues);

CheckoutPageServiceFee.displayName = 'CheckoutPageServiceFee';

export default CheckoutPageServiceFee;

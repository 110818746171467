import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bool } from 'prop-types';
import moment from 'moment';

import routeConfiguration from '../../routeConfiguration';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { deleteFromCart, loadData, addCartToUser } from './CartPage.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  Order,
  ServiceFeeSummary
} from '../../components';
import Summary from './Summary/Summary';
import { TopbarContainer } from '..';
import { setOrderedProducts } from '../../components/TransactionPanel/setOrderedProducts';

import css from './CartPage.css';
import ServiceFeeItem from '../../components/ServiceFeeItem/ServiceFeeItem';

const CartPageComponent = ({
  intl,
  cart,
  fetchInProgress,
  fetchCartError,
  deleteFromCart,
  deleteFromCartSuccess,
  fetchCart,
  history,
  callSetInitialValues,
  onInitializeCardPaymentData,
  onAddCartToUser,
  fetchServiceFeesInProgress,
  pendingServiceFeeTransaction,
  fetchServiceFeesError
}) => {

  const serviceFeesWithListings = pendingServiceFeeTransaction.map(transaction => {
    const relatedTransaction = transaction.relatedTransactions.find(
      item => !item.attributes.serviceFee
        && item.attributes.processState !== 'pending-payment'
        && item.attributes.processState !== 'payment-expired'
        && moment(item.attributes.start).isSame(moment(transaction.attributes.start))
        && moment(item.attributes.end).isSame(moment(transaction.attributes.end))
    )

    const { lineItems: relatedLineItems, transitions: relatedTransitions } = relatedTransaction.attributes;

    const listings = relatedLineItems?.length && relatedTransitions.length
      && setOrderedProducts(relatedLineItems, relatedTransitions);

    const currentListings = relatedTransaction.listings.filter(item => {
      const actualListing = listings.find(el => el.listingId === item.id.uuid);
      return actualListing.seats > 0;
    })

    return {
      ...transaction,
      cartListings: currentListings.map(listing => ({
        attributes: { amount: relatedTransaction.attributes.lineItems.findLast(item => item?.listingId === listing.id.uuid)?.seats },
        id: { uuid: listing.id.uuid},
        listing
      })),
    }
  })

  const user = useSelector(state => state?.user?.currentUser);
  const isAddToUserAvailable = !!user?.attributes?.hasProvider;

  const errorMessage = fetchCartError ? (
    <p className={css.error}>{fetchCartError.message}</p>
  ) : null;

  const noResultsMessage =
    !fetchInProgress
    && !cart.length
    && !fetchCartError
    && !fetchServiceFeesInProgress
    && !pendingServiceFeeTransaction.length
    && !fetchServiceFeesError ? (
      <p className={css.noResultText}>
        <FormattedMessage id="Order.noOrders" />
      </p>
    ) : null;

    const handleSubmit = (values, isServiceFee = false) => {
      const { bookingDates, ...bookingData } = values;

      const initialValues = {
        bookingDates: {
          bookingStart: values.start,
          bookingEnd: values.end,
        },
        confirmPaymentError: null,
        listings: values.cartListings,
        provider: values.provider,
        totalNumberOfOrders: values.totalNumberOfOrders,
      };

      const routes = routeConfiguration();
      // Customize checkout page state with current listing and selected bookingDates
      const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
      callSetInitialValues(setInitialValues, initialValues);

      // Clear previous Stripe errors from store if there is any
      onInitializeCardPaymentData();
      const pathParams = isServiceFee ? { cartId: 'serviceFee' } : { cartId: values.cart };
      const searchParamsMaybe = isServiceFee ? { serviceFeeId: values.cart.uuid } : {};

      // Redirect to CheckoutPage
      history.push(
        createResourceLocatorString(
          'CheckoutPage',
          routes,
          pathParams,
          searchParamsMaybe
        )
      );
    };

  useEffect(() => {
    deleteFromCartSuccess && fetchCart();
  }, [deleteFromCartSuccess]);

  return (
    <Page
      className={css.root}
      title={intl.formatMessage({ id: 'CartPage.title' })}
      scrollingDisabled={false}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="CartPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.main}>
          <div className={css.cartContainer}>
            {errorMessage}
            {noResultsMessage}
            {fetchInProgress || fetchServiceFeesInProgress ? (
              <div className={css.listItemsLoading}>
                <IconSpinner />
              </div>
            ) : (
              <>
                {serviceFeesWithListings.map( (item, index) => {
                  const { cartListings, attributes, provider, id, relatedTransactions } = item;
                  const serviceFeeFor = relatedTransactions.filter(tx => !tx.attributes.serviceFee
                    && tx.attributes.processState !== 'pending-payment'
                    && tx.attributes.processState !== 'payment-expired' );

                  return (
                      <ServiceFeeItem
                        key={id.uuid}
                        orders={cartListings}
                        orderNumber={index + 1}
                        totalNumberOfOrders={serviceFeesWithListings.length}
                        intl={intl}
                        provider={provider}
                        className={css.orderContainer}
                        serviceFee={attributes.serviceFee}
                        payinTotal={attributes.payinTotal}
                        serviceFeeFor={serviceFeeFor}
                      >
                        <ServiceFeeSummary
                          intl={intl}
                          transaction={item}
                          isPayment={true}
                          rootClassName={css.summaryCard}
                          contextText={intl.formatMessage({ id: "CartPage.serviceFee" })}
                        />
                      </ServiceFeeItem>
                    )
                })}
                {cart.map(({ cartListings, attributes, provider, id }, index) => (
                  <Order
                    key={id.uuid}
                    orders={cartListings}
                    orderNumber={index + 1}
                    totalNumberOfOrders={cart.length}
                    intl={intl}
                    provider={provider}
                    deleteFromCart={deleteFromCart}
                    productCanBeRemoved={true}
                    className={css.orderContainer}
                  >
                    <Summary
                      start={attributes.start}
                      end={attributes.end}
                      payinTotal={attributes.payinTotal}
                      lineItems={attributes.lineItems}
                      cartListings={cartListings}
                      intl={intl}
                      totalNumberOfOrders={cart.length}
                      handleSubmit={handleSubmit}
                      provider={provider}
                      id={id.uuid}
                      onAddCartToUser={onAddCartToUser}
                      isAddToUserAvailable={isAddToUserAvailable}
                    />
                  </Order>
                ))}
              </>
            )}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CartPageComponent.defaultProps = {
  cart: [],
  fetchInProgress: false,
  fetchCartError: null,
  scrollingDisabled: false,
};

CartPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchCartError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  // cart: arrayOf(propTypes.cartListing),
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchCartError,
    cart,
    deleteFromCartInProgress,
    deleteFromCartSuccess,
    pendingServiceFeeTransaction,
    fetchServiceFeesInProgress,
    fetchServiceFeesError
  } = state.CartPage;

  return {
    fetchInProgress,
    fetchCartError,
    scrollingDisabled: isScrollingDisabled(state),
    cart: getMarketplaceEntities(state, cart),
    deleteFromCartInProgress,
    deleteFromCartSuccess,
    fetchServiceFeesInProgress,
    pendingServiceFeeTransaction,
    fetchServiceFeesError
  };
};

const mapDispatchToProps = dispatch => ({
  deleteFromCart: listingId => dispatch(deleteFromCart(listingId)),
  fetchCart: () => dispatch(loadData()),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onAddCartToUser: (cartId, email) => dispatch(addCartToUser(cartId, email)),
});

const CartPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(CartPageComponent);

CartPage.loadData = loadData;

export default CartPage;

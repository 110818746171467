import React from 'react';

import FieldAutocompleteSelect
  from '../../components/FieldAutocompleteSelect/FieldAutocompleteSelect';

import css from './EditListingDescriptionForm.css';

const AutocompleteProviderField = props => {
  const { id, providers, intl, markRequired, disabled, form, selectedProvider, ...rest } = props;
  const options = providers?.map(provider => ({value: provider.key, label: provider.label }));

  const providerLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.providerLabel',
  });
  const providerPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.providerPlaceholder',
  });

  const requiredProvider = intl.formatMessage({
    id: 'EditListingDescriptionForm.providerRequired',
  });
  const providerRequired = () => value => {
    if (value?.label === '' && value?.value === '') return requiredProvider;
    if (typeof value?.value === 'undefined' || value?.value === null) return requiredProvider;

    return undefined;
  };
  const validate = providerRequired();

  return providers ? (
    <FieldAutocompleteSelect
      className={css.category}
      id={id}
      label={providerLabel}
      markRequired={markRequired}
      disabled={disabled}
      inputValue={selectedProvider}
      placeholder={providerPlaceholder}
      options={options}
      validate={validate}
      requiredProvider={requiredProvider}
      {...rest}
    />
  ) : null;
};

export default AutocompleteProviderField;

import React from 'react';
import { object } from 'prop-types';

import {
  FieldCheckbox,
  FieldRadioButton,
  FieldSelect,
  FieldTextInput,
} from '../../components';
import * as validators from '../../util/validators';
import css from './CheckoutCustomFields.css';

const CheckoutCustomFields = props => {
  const { additionalFields, intl, needsTranslation } = props;

  if (!additionalFields || !additionalFields.fields || !additionalFields.fields.length) return null;

  const requiredMessage = intl.formatMessage({
    id: "StripePaymentForm.validateRequired",
  });
  const checkboxValue = intl.formatMessage({
    id: "StripePaymentForm.checkboxValue",
  });

  const selectPlaceholder = intl.formatMessage({ id: "StripePaymentForm.selectPlaceholder" });
  const requiredValidation = validators.required(requiredMessage);
  const { composeValidators,  nonEmptyArray } = validators;

  return (
    <>
      {additionalFields.fields.map((field, index) => {
        const { label, name, options, placeholder, required = true, type, value, disabled } = field;

        const translatedLabel = needsTranslation ? intl.formatMessage({ id: label }) : label;
        const translatedPlaceholder = needsTranslation ? intl.formatMessage({ id: placeholder }) : placeholder;

        const validation = required ? requiredValidation : null;
        const optionalLabel = `${translatedLabel}${required ? "*" : ""}`;

        if (type === "select") return (
          <FieldSelect
            className={css.message}
            id={name}
            key={name}
            label={optionalLabel}
            name={`checkoutFields.${name}`}
            validate={validation}
          >
            <option disabled={required} value="">
              {selectPlaceholder}
            </option>
            {options.map((option, i) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </FieldSelect>
        );

        if (type === "checkbox") return (
          <div key={index}>
            <p>{optionalLabel}</p>
            <FieldCheckbox
              id={name}
              name={`checkoutFields.${name}`}
              label={translatedPlaceholder}
              value={checkboxValue}
              validate={required ? composeValidators(nonEmptyArray(requiredMessage)) : null}
            />
          </div>
        );

        if (type === "radio") return (
          <div key={index}>
            <p>{optionalLabel}</p>
            <div>
              {
                options.map((option, i) => (
                  <FieldRadioButton
                    id={option}
                    key={i}
                    label={option}
                    name={`checkoutFields.${name}`}
                    validate={validation}
                    value={option}
                  />
                ))
              }
            </div>
          </div>
        );

        return (
          <FieldTextInput
            className={css.message}
            id={name}
            key={name}
            label={optionalLabel}
            name={`checkoutFields.${name}`}
            placeholder={translatedPlaceholder}
            type="text"
            validate={validation}
            disabled={disabled}
          />
        )
      })}
    </>
  );
};

CheckoutCustomFields.defaultProps = {
  additionalFields: null,
  needsTranslation: false
};

CheckoutCustomFields.propTypes = {
  additionalFields: object,
  intl: object.isRequired
};

export default CheckoutCustomFields;

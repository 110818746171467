import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';

import { formatMoney } from '../../../../util/currency';
import { intlShape } from '../../../../util/reactIntl';

import css from '../../OrderItemInfo.css';

const Products = ({ products, listings, intl }) => {
  if (!products?.length) return null;

  const sortedProducts = products[0].reversal
    ? products.sort((a, b) => a.unitPrice.amount - b.unitPrice.amount)
    : products.sort((a, b) => b.unitPrice.amount - a.unitPrice.amount);

  return (
    <div>
      {sortedProducts.map(({ listingId, unitPrice, quantity, seats }) => {
        const listingItem = listings.find(({ id: { uuid } }) => listingId === uuid);
        const title = listingItem.attributes.title;
        return (
          <p className={classNames(css.text, css.priceContainer)} key={listingId}>
            <span className={css.productName}>{title}</span>
            <span className={css.text}>
                {`${Math.abs(seats)} x ${formatMoney(intl, unitPrice)} x ${quantity} `}
              <FormattedMessage id={quantity === 1 ? 'OrderInfo.day' : 'OrderInfo.days'} />
              </span>
          </p>
        );
      })}
    </div>
  );
}

Products.propTypes = {
  intl: intlShape.isRequired,
  listings: arrayOf(shape({
    id: shape({ uuid: string.isRequired}),
    attributes: shape({
      title: string.isRequired,
    })
  })),
  products: arrayOf(
    shape({
      code: oneOf(['day', 'service-fee', 'long-term-discount', 'promo-discount', 'provider-commission']),
      includeFor: arrayOf(oneOf(['customer', 'provider'])).isRequired,
      quantity: number,
      unitPrice: shape({
        amount: number,
        currency: string,
      }),
      lineTotal: shape({
        amount: number,
        currency: string,
      }),
      reversal: bool,
      seats: number,
    })
  )};

export default Products;

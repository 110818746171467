import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { bool, oneOf } from 'prop-types';

import { AddressLinkMaybe } from '../index';
import { propTypes } from '../../util/types';

import css from './OrderItemInfo.css';

const PickupAddress = ({ deliveryType, deliveryToProvider, provider, isListingTransaction }) => {
  const { name, openingHours, businessLocation } = deliveryToProvider
    ? deliveryToProvider?.attributes
    : provider.attributes;
  const DELIVERED_TYPE_HOME = 'home';

  if (deliveryType === DELIVERED_TYPE_HOME || !isListingTransaction) return null;

  return (
    <div className={css.sectionDeliverAddress} key="pickup_address">
      <p className={css.title}>
        <FormattedMessage id="OrderInfo.pickupAddress" />
      </p>
      <p className={css.subtitle}>{name}</p>
      {businessLocation && (
        <AddressLinkMaybe
          className={css.text}
          location={businessLocation.selectedPlace}
          geolocation={businessLocation.selectedPlace.origin}
          showAddress
        />
      )}
      {openingHours ? (
        <>
          <p className={classNames(css.subtitle, css.subtitleContainer)}>
            <FormattedMessage id="OrderInfo.equipment" />
          </p>
          <p className={classNames(css.text, css.openingHours)}>{openingHours}</p>
        </>
      ) : null}
    </div>
  );
}

PickupAddress.defaultProps = {
  isListingTransaction: true,
}

PickupAddress.propTypes = {
  deliveryType: oneOf(['home', 'store']).isRequired,
  provider: propTypes.providerDetails.isRequired,
  deliveryToProvider: propTypes.providerDetails.isRequired,
  isListingTransaction: bool,
}

export default PickupAddress;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  DatePicker,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperBusinessSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  Page,
  UserNav,
  BusinessSummary,
  BusinessTable,
  Select,
} from '../../components';
import { TopbarContainer } from '../../containers';
import config from '../../config';

import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './BusinessAnalysisPage.css';

const date = new Date();
const currentYear = date.getFullYear();
const currentMonth = date.getMonth();
const firstDay = new Date(currentYear, currentMonth, 2).toISOString().split('T')[0];
const lastDay = new Date().toISOString().split('T')[0];

const countryData = [
  { value: 'Polska (PLN)', countryId: 'pl', currency: 'PLN' },
  { value: 'Magyarország (HUF)', countryId: 'hu', currency: 'HUF' },
  { value: 'Česká republika (CZK)', countryId: 'cz', currency: 'CZK' },
  { value: 'Lietuva (EUR)', countryId: 'lt', currency: 'EUR' },
  { value: 'Latvija (EUR)', countryId: 'lv', currency: 'EUR' },
  { value: 'Eesti (EUR)', countryId: 'ee', currency: 'EUR' },
];

const FetchData = ({ onLoadData, intl, start, end, prev_start, prev_end, currency, countryId, groupingDate, isDecathlon }) => {
  const [data, setData] = useState(null);
  const [prevData, setPrevData] = useState(null);
  const [loading, setLoading] = useState(false);

  const isComparisonOn = prev_start && prev_end;

  useEffect(() => {
    setLoading(true)
    Promise.all([
      onLoadData({ [`${groupingDate}GreaterThan`]: start, [`${groupingDate}LessThan`]: end, currency, countryId, isDecathlon, businessCategory: '*' }),
      prev_start && prev_end ? onLoadData({ [`${groupingDate}GreaterThan`]: prev_start, [`${groupingDate}LessThan`]: prev_end, currency, countryId, isDecathlon, businessCategory: '*' }) : Promise.resolve({ data: null })
    ]).then((res) => {
      setData(res[0].data?.data);
      setPrevData(res[1].data?.data);
      setLoading(false)
    })
  }, [start, end, prev_start, prev_end, currency, countryId, groupingDate, isDecathlon]);

  const concatenatedAdditionalData = data ? data.slice(1).map(provider => {
    const providerPrevData = prevData ? prevData.find(i => i.id.uuid !== 'all' && i.attributes.businessCategory === provider.attributes.businessCategory) : null;

    return {
      label: provider.attributes.businessCategory || 'other',
      providerName: provider.attributes.businessCategory,
      data: [
        { current: provider ? (provider.attributes.incomeTotal / 100).toFixed(2) : 0, prev: providerPrevData ? (providerPrevData.attributes.incomeTotal / 100).toFixed(2) : 0 },
        { current: provider ? provider.attributes.itemsCount : 0, prev: providerPrevData ? providerPrevData.attributes.itemsCount : 0 },
        { current: provider ? ((provider.attributes.incomeTotal / provider.attributes.itemsCount) / 100).toFixed(2) : 0, prev: providerPrevData ? ((providerPrevData.attributes.incomeTotal / providerPrevData.attributes.itemsCount) / 100).toFixed(2) : 0 },
        { current: provider ? (provider.attributes.avgDuration * 1).toFixed(1) : 0, prev: providerPrevData ? (providerPrevData.attributes.avgDuration * 1).toFixed(1) : 0 }
      ],
    }
  }) : [];

  if (!data || loading) return null;

  return (
    <BusinessTable
      rows={concatenatedAdditionalData}
      isComparisonOn={isComparisonOn}
      sortBy={0}
      firstRowName={intl.formatMessage({ id: 'General.category' })}
      dataHeaders={[
        { primary: intl.formatMessage({ id: 'BusinessResults.turnover' }), secondary: `(${currency})` },
        { primary: intl.formatMessage({ id: 'BusinessResults.quantity' }), secondary: `(${intl.formatMessage({ id: 'BusinessResults.days' })})` },
        { primary: intl.formatMessage({ id: 'BusinessResults.avgRentingValue' }), secondary: `(${currency})` },
        { primary: intl.formatMessage({ id: 'BusinessResults.avgRentingLength' }), secondary: `(${intl.formatMessage({ id: 'BusinessResults.days' })})` }
      ]}
      dates={{ firstDay, lastDay }}
      extendableRows={false}
    />
  )
}

export const BusinessResultsPageComponent = props => {
  const {
    scrollingDisabled,
    intl,
    location,
    history,
    onLoadData,
    analysis,
    currentUser
  } = props;

  const countryUser = countryData.find(({ countryId }) => countryId === currentUser?.attributes?.language);
  const defaultCurrency = countryUser ? countryUser.currency : 'PLN';
  const defaultCountryId = config.custom.countryId;

  const { start = firstDay, end = lastDay, prev_start, prev_end, currency = defaultCurrency, countryId = defaultCountryId, groupingDate = 'deliveredAt', isDecathlon } = parse(location.search);
  const [data, setData] = useState(null);
  const [prevData, setPrevData] = useState(null);
  const [additionalData, setAdditionalData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    Promise.all([
      onLoadData({ [`${groupingDate}GreaterThan`]: start, [`${groupingDate}LessThan`]: end, currency, countryId, isDecathlon, providerId: '*', include: ['provider'] }),
      prev_start && prev_end ? onLoadData({ [`${groupingDate}GreaterThan`]: prev_start, [`${groupingDate}LessThan`]: prev_end, currency, countryId, isDecathlon, providerId: '*', include: ['provider'] }) : Promise.resolve({ data: null })
    ]).then((res) => {
      setData(res[0].data);
      setPrevData(res[1].data);
    })
    !analysis && onLoadData({ [`${groupingDate}GreaterThan`]: start, [`${groupingDate}LessThan`]: end, currency, isDecathlon, countryId, businessCategory: '*' }).then(res => setAdditionalData(res.data))
  }, [start, end, prev_start, prev_end, currency, countryId, groupingDate, isDecathlon])

  const title = intl.formatMessage({ id: "BusinessAnalysisPage.title" });

  const handleDateRangeSubmit = ({ start, end, prevStart, prevEnd }) => {
    const prevDates = prevStart && prevEnd ? { prev_start: prevStart, prev_end: prevEnd } : {};
    history.push(
      createResourceLocatorString(
        analysis ? 'BusinessAnalysisPage' : 'BusinessDktPage',
        routeConfiguration(),
        {},
        { start, end, currency, countryId, groupingDate, isDecathlon, ...prevDates }
      )
    );
  }

  const handleCurrencyChange = (e) => {
    const prevDates = prev_start && prev_end ? { prev_start, prev_end } : {};

    const country = countryData.find(country => country.countryId === e.target.value);
    history.push(
      createResourceLocatorString(
        analysis ? 'BusinessAnalysisPage' : 'BusinessDktPage',
        routeConfiguration(),
        {},
        { currency: country.currency.toUpperCase(), countryId: country.countryId, groupingDate, start, end, isDecathlon, ...prevDates }
      )
    );
  }

  const handleGroupingDateChange = (e) => {
    const prevDates = prev_start && prev_end ? { prev_start, prev_end } : {};
    history.push(
      createResourceLocatorString(
        analysis ? 'BusinessAnalysisPage' : 'BusinessDktPage',
        routeConfiguration(),
        {},
        { groupingDate: e.target.value, currency, countryId, start, end, isDecathlon, ...prevDates }
      )
    );
  }

  const handleProviderTypesChange = (e) => {
    const prevDates = prev_start && prev_end ? { prev_start, prev_end } : {};
    history.push(
      createResourceLocatorString(
        analysis ? 'BusinessAnalysisPage' : 'BusinessDktPage',
        routeConfiguration(),
        {},
        { isDecathlon: e.target.value === 'true' || (e.target.value === 'false' ? false : undefined), currency, countryId, start, end, groupingDate, ...prevDates }
      )
    );
  }

  const isComparisonOn = prev_start && prev_end;
  const basicData = data ? data.data.slice(1) : [];
  const previousData = prevData ? prevData.data.slice(1) : [];
  const basicIncluded = data ? data.included : [];
  const previousIncluded = prevData ? prevData.included : [];

  const allProvidersData = [... new Map([...basicData, ...previousData].map(item => [item.relationships.provider.data.id.uuid, item])).values()];
  const allProvidersIncluded = [...basicIncluded, ...previousIncluded];

  const concatenatedData = data ? allProvidersData.map(provider => {
    const providerId = provider.relationships.provider.data.id.uuid;
    const providerData = allProvidersIncluded.find(i => i.id.uuid === provider.relationships.provider.data.id.uuid);
    const providerNumbers = data ? data.data.find(i => i.relationships.provider.data?.id.uuid === providerId) : null;
    const prevProviderData = prevData ? prevData.data.find(i => i.relationships.provider.data?.id.uuid === providerId) : null;

    const currentProviderAvg = providerNumbers && providerNumbers.attributes.transactionsCount > 0 ? (providerNumbers.attributes.incomeTotal / providerNumbers.attributes.transactionsCount) : 0;
    const prevProviderAvg = prevProviderData && prevProviderData.attributes.transactionsCount ? (prevProviderData.attributes.incomeTotal / prevProviderData.attributes.transactionsCount) : 0;

    return {
      label: providerData.attributes.name,
      providerId: providerData.id.uuid,
      providerName: providerData.attributes.name,
      data: [
        { current: providerNumbers ? (providerNumbers.attributes.incomeTotal / 100).toFixed(2) : 0, prev: prevProviderData ? (prevProviderData.attributes.incomeTotal / 100).toFixed(2) : 0 },
        { current: providerNumbers ? providerNumbers.attributes.transactionsCount : 0, prev: prevProviderData ? prevProviderData.attributes.transactionsCount : 0 },
        { current: providerNumbers ? (currentProviderAvg / 100).toFixed(2) : 0, prev: prevProviderData ? (prevProviderAvg / 100).toFixed(2) : 0 },
        // { current: providerNumbers ? (providerNumbers.attributes.incomeMax / 100).toFixed(2) : 0, prev: prevProviderData ? (prevProviderData.attributes.incomeMax / 100).toFixed(2) : 0 },
        { current: providerNumbers ? (providerNumbers.attributes.avgDuration * 1).toFixed(1) : 0, prev: prevProviderData ? (prevProviderData.attributes.avgDuration * 1).toFixed(1) : 0 }
      ],
    }
  }) : [];

  const concatenated2Data = (data && analysis) ? data.data.slice(1).map(provider => {
    const providerData = data.included.find(i => i.id.uuid === provider.relationships.provider.data.id.uuid);
    return {
      label: providerData.attributes.name,
      providerId: providerData.id.uuid,
      providerName: providerData.attributes.name,
      data: [
        { current: provider ? (provider.attributes.incomeTotal / 100).toFixed(2) : 0 },
        { current: provider ? (provider.attributes.payinTotal / 100).toFixed(2) : 0 },
        { current: provider ? (provider.attributes.incomeTotal / 100).toFixed(2) - (provider.attributes.payinTotal / 100).toFixed(2) : 0 },
      ],
    }
  }) : [];

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="BusinessDktPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="BusinessDktPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperBusinessSideNav currentTab="BusinessDktPage" />
        <LayoutWrapperMain className={css.colorBackground}>
          <div className={css.content}>
            <div className={css.header}>
              <h1 className={css.title}>
                {intl.formatMessage({ id: 'BusinessDktPage.yourResults' })}
              </h1>
              <div>
                <DatePicker onSubmit={handleDateRangeSubmit} value={{ startDate: start, endDate: end, prevStartDate: prev_start, prevEndDate: prev_end }} showComparison={!analysis} />
              </div>
            </div>
            <div className={css.filters}>
              <Select
                label={intl.formatMessage({ id: 'BusinessDktPage.country' })}
                value={countryId}
                onChange={handleCurrencyChange}
              >
                {countryData.map(({ value, countryId }) => (<option value={countryId}>{value}</option>))}
              </Select>
              <Select
                label={intl.formatMessage({ id: 'BusinessDktPage.filteringProviderType' })}
                value={isDecathlon}
                onChange={handleProviderTypesChange}
              >
                <option value="all">{intl.formatMessage({ id: 'BusinessDktPage.filterProviderTypeAll' })}</option>
                <option value="true">{intl.formatMessage({ id: 'BusinessDktPage.filterProviderTypeDecathlon' })}</option>
                <option value="false">{intl.formatMessage({ id: 'BusinessDktPage.filterProviderTypeExternal' })}</option>
              </Select>
              <Select
                label={intl.formatMessage({ id: 'BusinessDktPage.filtering' })}
                value={groupingDate}
                onChange={handleGroupingDateChange}
              >
                <option value="createdAt">{intl.formatMessage({ id: 'BusinessDktPage.createdAt' })}</option>
                <option value="deliveredAt">{intl.formatMessage({ id: 'BusinessDktPage.deliveredAt' })}</option>
              </Select>
            </div>
            {
              data &&
              <BusinessSummary
                currency={currency}
                currentData={{
                  payinTotal: data.data[0].attributes.incomeTotal,
                  transactionsCount: data.data[0].attributes.transactionsCount,
                  payoutTotal: data.data[0].attributes.payoutTotal,
                  payinAvg: data.data[0].attributes.incomeAvg,
                  payinMax: data.data[0].attributes.incomeMax,
                  avgDuration: data.data[0].attributes.avgDuration,
                  itemsCount: data.data[0].attributes.itemsCount,
                }}
                previousData={prevData && {
                  payinTotal: prevData.data[0].attributes.incomeTotal,
                  transactionsCount: prevData.data[0].attributes.transactionsCount,
                  payoutTotal: prevData.data[0].attributes.payoutTotal,
                  payinAvg: prevData.data[0].attributes.incomeAvg,
                  payinMax: prevData.data[0].attributes.incomeMax,
                  avgDuration: prevData.data[0].attributes.avgDuration,
                  itemsCount: prevData.data[0].attributes.itemsCount,
                }}
              />
            }
            {
              analysis && data &&
              <BusinessTable
                rows={concatenated2Data}
                isComparisonOn={false}
                sortBy={0}
                dataHeaders={[
                  { primary: intl.formatMessage({ id: 'BusinessResults.turnover' }), secondary: `(${currency})` },
                  { primary: intl.formatMessage({ id: 'BusinessResults.payment' }), secondary: `(${currency})` },
                  { primary: intl.formatMessage({ id: 'BusinessResults.paymentWithVoucher' }), secondary: `(${currency})` },
                ]}
                dates={{ firstDay, lastDay }}
                extendableRows={false}
              />
            }
            {
              data &&
              <BusinessTable
                rows={concatenatedData}
                isComparisonOn={isComparisonOn}
                sortBy={0}
                dataHeaders={[
                  { primary: intl.formatMessage({ id: 'BusinessResults.turnover' }), secondary: `(${currency})` },
                  { primary: intl.formatMessage({ id: 'BusinessResults.rentings' }), secondary: `(${intl.formatMessage({ id: 'BusinessResults.count' })})` },
                  { primary: intl.formatMessage({ id: 'BusinessResults.avgRentingValue' }), secondary: `(${currency})` },
                  // { primary: 'Maks. wartość wyp.', secondary: `(${currency})` },
                  { primary: intl.formatMessage({ id: 'BusinessResults.avgRentingLength' }), secondary: `(${intl.formatMessage({ id: 'BusinessResults.days' })})` }
                ]}
                dates={{ firstDay, lastDay }}
                extendableRows={false}
              />
            }
            <FetchData
              onLoadData={onLoadData}
              intl={intl}
              start={start}
              end={end}
              prev_start={prev_start}
              prev_end={prev_end}
              currency={currency}
              countryId={countryId}
              groupingDate={groupingDate}
              isDecathlon={isDecathlon}
            />
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

BusinessResultsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

const { bool } = PropTypes;

BusinessResultsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  // Topbar needs user info.
  const { currentUser } = state.user;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const loadData = params => (dispatch, getState, sdk) => {
  return sdk.newSdk.analyticsBookings.query({ ...params, })
    .then((res) => {
      return res;
    })
    .catch(e => {
      console.error(e, 'initial-message-send-failed', { tx: 'PLN' });
    });
};

const mapDispatchToProps = dispatch => ({
  onLoadData: (params) => dispatch(loadData(params))
});

const BusinessResultsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl,
  withRouter
)(BusinessResultsPageComponent);

export default BusinessResultsPage;
